import * as React from 'react';
import { TApiProcessEntity } from '../../types/Process';
import { GridList, GridListTile, GridListTileBar, useMediaQuery, useTheme } from '@material-ui/core';
import { useProcessListStyles } from './ProcessList.styles';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '../../Routes';
import Image from '../cms/Image';
import { simpleDateFormat } from '../../Helpers';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

interface IProcessListProps {
  processes?: Array<TApiProcessEntity>;
}

const ProcessList: React.FC<IProcessListProps> = ({
  processes
}): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useProcessListStyles();

  return (
    <GridList cellHeight={180} cols={isXs ? 2 : 4} className={classes.gridList}>
      {processes?.map((process) => {
        return (
          <GridListTile key={process.id}>
            <Link
              to={reverse(routes.processItem, {
                id: process.id
              })}
            >
              {process.image && (
                <Image image={{
                  url: process.image as string,
                  name: process.name as string
                }}/>
              )}

              <GridListTileBar
                title={process.name}
                subtitle={simpleDateFormat(process.updateDate)}
                actionIcon={
                  <IconButton
                    aria-label={`info about ${process.name}`}
                    className={classes.icon}
                  >
                    <InfoIcon/>
                  </IconButton>
                }
              />
            </Link>
          </GridListTile>
        );
      })}
    </GridList>
  );
};

export default ProcessList;

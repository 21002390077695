import * as React from "react";
import { useEffect } from "react";
import {
  ContentPage,
  IPageContentArticle,
  IPageContentImage,
  TContentState
} from "../types/Content";
import { useCMS } from "../hooks/useCMS";
import Paragraph from "./cms/Paragraph";
import Expansion from "./cms/Expansion";
import Image from "./cms/Image";
import CmsCard from "./cms/CmsCard";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPageTitle } from "../actions/ContentActions";
import { IAppState } from "../store/Store";

interface IPageContentProps {
  pageType: ContentPage | null;
  pageId?: number;
  showAsParagraph?: boolean;
  showAsCard?: boolean;
  customTitle?: string;
  hideContent?: boolean;
}

const PageContent: React.FC<IPageContentProps> = props => {
  const { cms } = useCMS(props.pageType, props.pageId);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState<string | false>("panel0");
  const contentState: TContentState = useSelector<IAppState, TContentState>(
    state => state.contentState
  );

  const customTitle = props.customTitle;

  const handleChange = (panel: string) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    const title =
      customTitle ?? (contentState.data?.name ? cms.getPageTitle() : null);

    if (title) {
      dispatch(setCurrentPageTitle(title));
    }

    //eslint-disable-next-line
  }, [contentState.data?.name, customTitle]);

  //TODO: Rework this to use react-query
  if (props.hideContent) {
    return null;
  }

  return (
    <>
      {cms.getPageImages()?.map((image: IPageContentImage, index: number) => {
        if (image.url) {
          return <Image image={image} key={index} />;
        }

        return false;
      })}
      {cms
        .getPageContent()
        ?.map((article: IPageContentArticle, index: number) => {
          if (props.showAsParagraph) {
            return <Paragraph key={index} body={article.bodyText} />;
          }
          if (props.showAsCard) {
            return (
              <CmsCard
                key={index}
                headline={article.headline}
                bodyText={article.bodyText}
              />
            );
          }
          return (
            <Expansion
              headline={article.headline}
              bodyText={article.bodyText}
              index={index}
              expanded={expanded}
              handleChange={handleChange}
            />
          );
        })}
    </>
  );
};

export default PageContent;

import * as React from 'react';
import { useEffect, useState } from 'react';
import { useChemicalListStyles } from '../../../components/chemicals/ChemicalList.styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store/Store';
import PageContent from '../../../components/PageContent';
import { ContentPage } from '../../../types/Content';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import {
  filterMeetingsList,
  getMeetingsList,
} from '../../../actions/MeetingActions';
import { TMeetingState } from '../../../types/Meeting';
import MeetingList from '../../../components/meetings/MeetingsList';
import { reverse } from 'named-urls';
import { routes } from '../../../Routes';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

const MeetingsListPage: React.FC<RouteComponentProps<any>> = (
  props
): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useChemicalListStyles();
  const { t } = useTranslation();
  const meetingState: TMeetingState = useSelector<IAppState, TMeetingState>(
    (state) => state.meetingState
  );

  const [allOrganisations, setAllOrganisations] = useState<Array<string>>();
  const [chosenOrganisation, setChosenOrganisation] = useState<string>('-');

  useEffect(() => {
    dispatch(getMeetingsList());
  }, [dispatch]);

  useEffect(() => {
    const list: Array<string> = [];
    meetingState.list?.map((meeting) =>
      meeting.organisations.forEach((organisation) => {
        if (!list.includes(organisation)) {
          list.push(organisation);
        }
      })
    );
    list.sort();

    setAllOrganisations(list);
  }, [meetingState]);

  useEffect(() => {
    dispatch(
      filterMeetingsList(chosenOrganisation === '-' ? '' : chosenOrganisation)
    );
  }, [dispatch, chosenOrganisation]);

  const onSelect = (id: number) => {
    props.history.push(reverse(routes.meetingsPage, { id }));
  };

  return (
    <>
      <PageContent pageType={ContentPage.MEETINGS} showAsParagraph={true} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl variant='outlined' className={classes.formControl}>
            <InputLabel>{t('meetings.filter.organisations')}</InputLabel>
            <Select
              value={chosenOrganisation}
              onChange={(e) =>
                setChosenOrganisation(() => e.target.value as string)
              }
              label={t('meetings.filter.organisations')}
            >
              <MenuItem value={'-'} key={'-'}>
                {t('meetings.filter.organisations.all')}
              </MenuItem>

              {allOrganisations &&
                allOrganisations.map((title) => (
                  <MenuItem value={title} key={title}>
                    {title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className={classes.root}>
        {meetingState.filteredList && (
          <MeetingList
            meetings={meetingState.filteredList}
            onSelect={onSelect}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(MeetingsListPage);

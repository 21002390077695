import { useSelector } from 'react-redux';
import { IAppState, IRequestReducer } from '../store/Store';

export const useApp = () => {
  const appState: any = useSelector<IAppState, IRequestReducer>(
    (state: IAppState) => state.appState
  );

  const getActionName = (action: any) => {
    const matches = /(.*)_(REQUEST|SUCCESS|FAILED)/.exec(action.REQUEST);
    if (!matches) return;
    const [, request] = matches;

    return request;
  };

  const isLoading = (action?: any) => {
    if (typeof action !== 'undefined') {
      const actionName = getActionName(action);
      if (actionName) {
        return appState[actionName] && appState[actionName].loading;
      }
    }

    return appState.loading;
  };

  const hasError = (action?: any) => {
    if (typeof action !== 'undefined') {
      const actionName = getActionName(action);
      if (actionName) {
        return !!(appState[actionName] && appState[actionName].error);
      }
    }

    return !!appState.error;
  };

  const getError = (action?: any): any => {
    if (typeof action !== 'undefined') {
      const actionName = getActionName(action);
      if (actionName) {
        return appState[actionName] && appState[actionName].error;
      }
    }

    return appState.error;
  };

  const isLoaded = (action?: any) => {
    const actionName = getActionName(action);
    if (actionName) {
      return appState[actionName] && appState[actionName].loaded;
    }
    return appState.loaded;
  };

  return { app: { isLoading, isLoaded, hasError, getError } };
};

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface IFirstAidMeasurements {
  firstAidMeasures?: string;
  symptomsOrEffects?: string;
  emergencyInformation?: string;
  recommendations?: string;
  expanded: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
  })
);

const FirstAidMeasurements: React.FC<IFirstAidMeasurements> = ({
  firstAidMeasures,
  symptomsOrEffects,
  emergencyInformation,
  recommendations,
  expanded,
  handleChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange(`panel2`)}
      key={'panel2'}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel2bh-content`}
        id={`panel2bh-header`}
      >
        <Typography className={classes.heading}>{t('chemicals.first.aid.measures')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.list}>
        {firstAidMeasures && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.first.aid.measures')}</b> ${firstAidMeasures}` }} />
        )}
        {emergencyInformation && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.emergency_information')}</b> ${emergencyInformation}` }} />
        )}
        {symptomsOrEffects && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.symptoms_or_effects')}</b> ${symptomsOrEffects}` }} />
        )}
        {recommendations && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.recommendations')}</b> ${recommendations}` }} />
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default FirstAidMeasurements;

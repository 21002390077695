import { Reducer } from 'redux';
import { endReducer } from '../store/Store';
import {
  FilterChemicalsList,
  GetChemicalList,
  GetChemicalProduct,
  initialChemicalState,
  TChemicalActions,
  TChemicalState,
} from '../types/Chemical';

export const chemicalReducer: Reducer<TChemicalState, TChemicalActions> = (
  state = initialChemicalState,
  action
) => {
  switch (action.type) {
    case GetChemicalList.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetChemicalList.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.products,
        filteredList: action.payload.data.products,
      };

    case GetChemicalList.FAILED:
      return {
        ...state,
        loading: false,
      };

    case GetChemicalProduct.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetChemicalProduct.SUCCESS:
      return {
        ...state,
        loading: false,
        activeChemical: {
          data: action.payload.data.product,
          hazards: action.payload.data.hazards,
          ppe: action.payload.data.ppe,
        },
      };

    case GetChemicalProduct.FAILED:
      return {
        ...state,
        loading: false,
      };

    case FilterChemicalsList.SUBMIT:
      const searchPhrase = action.payload.search.toLocaleLowerCase();
      const searchLocation = action.payload.location.toLocaleLowerCase();

      return {
        ...state,
        filteredList: state.data?.filter((val) => {
          const searchCondition = val.name
            .toLocaleLowerCase()
            .includes(searchPhrase);

          if (!searchLocation) {
            return searchCondition;
          }

          return (
            searchCondition &&
            val.locations
              .map((location) => location.title.toLocaleLowerCase())
              .includes(searchLocation)
          );
        }),
        searchPhrase,
        searchLocation,
      };

    default:
      return endReducer(state, action);
  }
};

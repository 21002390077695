import * as React from 'react';
import { TApiProcessFile } from '../../../types/Process';
import { ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemText from '@material-ui/core/ListItemText';

interface IExecutionProps {
  execution: string;
  processFiles?: Array<TApiProcessFile>;
  expanded: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
  handleFileClick: (filePath: string) => () => void | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
  })
);

const Execution: React.FC<IExecutionProps> = ({
  execution,
  processFiles,
  expanded,
  handleFileClick,
  handleChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange('expandedExecution')}
      key={'expandedExecution'}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`expandedExecutionnbh-content`}
        id={`expandedExecutionbh-header`}
      >
        <Typography className={classes.heading}>{t("process.execution")}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.list}>
        {execution && (
          <Typography dangerouslySetInnerHTML={{ __html: execution }} />
        )}
        {processFiles && processFiles.map((file) => (
          <ListItem
            key={file.id}
            role={undefined}
            dense
            button
            onClick={handleFileClick(file.media)}
          >
            <ListItemIcon>
              <DescriptionIcon/>
            </ListItemIcon>
            <ListItemText
              primary={file.title}
              secondary={file.notes ? file.notes : null}
            />
          </ListItem>
        ))}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default Execution;
import { useQuery } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useApiClient } from "./useApiClient";
import { ContentPage, IPageContent } from "../../types/Content";

export const usePageContentQuery = (type: ContentPage) => {
  const apiClient = useApiClient();

  const fetchPageContent = async () => {
    const res = await apiClient.get<
      void,
      AxiosResponse<{ menuItem: IPageContent }>
    >(`/content/byType?type=${type}`);

    return res.data.menuItem;
  };

  return useQuery(["page_content", type], fetchPageContent, {
    enabled: !!type,
    staleTime: 60 * 1000
  });
};

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

interface ISpills {
  personalPrecautions?: string;
  environmentalPrecautions?: string;
  containmentAndCleanUp?: string;
  expanded: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
  })
);

const Spills: React.FC<ISpills> = ({
  personalPrecautions,
  environmentalPrecautions,
  containmentAndCleanUp,
  expanded,
  handleChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange(`panel9`)}
      key={'panel9'}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel9bh-content`}
        id={`panel9bh-header`}
      >
        <Typography className={classes.heading}>{t('chemicals.spills')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.list}>
        {personalPrecautions && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.personal_precautions')}</b> ${personalPrecautions}` }} />
        )}
        {environmentalPrecautions && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.environmental_precautions')}</b> ${environmentalPrecautions}` }} />
        )}
        {containmentAndCleanUp && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.containment_and_clean_up')}</b> ${containmentAndCleanUp}` }} />
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Spills;

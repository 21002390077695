import * as React from 'react';
import { TApiSurveyEntity } from '../../types/Survey';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { simpleDateFormat } from '../../Helpers';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import BallotIcon from '@material-ui/icons/Ballot';
import { SurveyInspectionType } from '../../types/Content';
import { useCurrentActiveSurvey } from '../../hooks/useCurrentActiveSurvey';
import Typography from '@material-ui/core/Typography';

interface ISurveyListProps {
  surveys: Array<TApiSurveyEntity>;
  onSelect: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    icon: {
      backgroundColor: 'white',
      color: 'rgb(141, 198, 63)',
    },
  })
);

const SurveyList: React.FC<ISurveyListProps> = (props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { loadRespondentsStorage, isSurveyCompleted } =
    useCurrentActiveSurvey();
  const incompletedSurveys = loadRespondentsStorage();

  return (
    <List className={classes.root}>
      {props.surveys.map((survey: TApiSurveyEntity) => {
        const isSurvey = survey.surveyType === SurveyInspectionType.SURVEY;
        if (isSurveyCompleted(survey)) return undefined;

        return (
          <ListItem
            button
            key={survey.id}
            onClick={() => props.onSelect(survey.id)}
          >
            <ListItemAvatar>
              <Avatar className={classes.icon}>
                {isSurvey ? (
                  <QuestionAnswerIcon fontSize={'large'} />
                ) : (
                  <BallotIcon fontSize={'large'} />
                )}
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={survey.title.trim() !== '' ? survey.title : survey.name}
              secondary={
                <React.Fragment>
                  {isSurvey
                    ? `${t('actionplan.endDate')} ${simpleDateFormat(
                        survey.endTime
                      )} `
                    : ''}
                  {incompletedSurveys[survey.id] && (
                    <Typography
                      display='inline'
                      component='span'
                      variant='body2'
                      color='error'
                    >
                      {t('survey.incomplete')}
                    </Typography>
                  )}
                </React.Fragment>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default SurveyList;

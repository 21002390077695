import * as React from 'react';
import Typography from '@material-ui/core/Typography';

interface IParagraphProps {
  body: string;
}

const Paragraph: React.FC<IParagraphProps> = ({ body }): JSX.Element => {
  return (
    <Typography component={'p'} dangerouslySetInnerHTML={{ __html: body }} />
  );
};

export default Paragraph;

import { apiCall } from './BaseAction';
import {
  FilterMeetingsList,
  GetMeeting,
  GetMeetingsList,
  TFilterMeetingsListAction,
  TGetMeetingAction,
  TGetMeetingsListAction,
} from '../types/Meeting';
import { Dispatch } from 'redux';

export const getMeetingsList = () => {
  return apiCall<TGetMeetingsListAction, null>(
    GetMeetingsList,
    'GET',
    '/meetings',
    true
  );
};

export const filterMeetingsList = (organisation: string) => {
  return (dispatch: Dispatch<TFilterMeetingsListAction>) => {
    dispatch({
      type: FilterMeetingsList.SUBMIT,
      payload: {
        organisation,
      },
    });
  };
};

export const getMeeting = (id: number) => {
  return apiCall<TGetMeetingAction, null>(
    GetMeeting,
    'GET',
    `/meetings/${id}`,
    true
  );
};

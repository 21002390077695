import * as React from 'react';
import { IAppState } from '../../store/Store';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import {
  getSurvey,
  getSurveyStructure,
  getSurveyResponses,
  getSurveyIdentificationInfo,
} from '../../actions/SurveyActions';
import { TSurveyRespondentId, TSurveysState } from '../../types/Survey';
import { isSurveyExpired, isSurveyNotPublished } from '../../helpers/Survey';
import { routes } from '../../Routes';
import { reverse } from 'named-urls';
import { TAuthState } from '../../types/Auth';
import { loadStoredRespondentState } from '../../hooks/useCurrentActiveSurvey';

interface IStateProps {
  surveysState: TSurveysState;
  authState: TAuthState;
}

interface IDispatchProps {
  getSurvey: (id: number, lang?: string) => any;
  getSurveyStructure: (id: number, lang?: string) => any;
  getSurveyResponses: (respondentId: TSurveyRespondentId) => any;
  getSurveyIdentificationInfo: (surveyId: number) => any;
}

type IProps = IStateProps &
  IDispatchProps &
  RouteComponentProps<{ id: string }>;

export const withSurveyData = (Component: React.FC) => {
  class requiresSurveyData extends React.Component<IProps> {
    public componentDidMount(): void {
      const surveyId = parseInt(this.props.match.params.id);
      const respondentIdentifier = loadStoredRespondentState();

      if (this.props.surveysState.currentSurvey) {
        if (
          (isSurveyExpired(this.props.surveysState.currentSurvey) ||
            isSurveyNotPublished(this.props.surveysState.currentSurvey)) &&
          !window.location.pathname.includes('/start')
        ) {
          window.location.replace(
            reverse(`/app${routes.surveyStart}`, { id: surveyId })
          );
        }
      }
      if (respondentIdentifier) {
        const currentSurveyId = respondentIdentifier.surveyId;
        const respondentId = respondentIdentifier.id;
        if (
          respondentId &&
          currentSurveyId &&
          !this.props.surveysState.currentSurvey
        ) {
          this.props.getSurveyResponses(respondentIdentifier.id);
        }
      }
      if (!this.props.surveysState.currentSurvey) {
        this.props.getSurveyIdentificationInfo(surveyId);
      }
    }

    componentDidUpdate(
      prevProps: Readonly<IProps>,
      prevState: Readonly<{}>,
      snapshot?: any
    ) {
      const surveyId = parseInt(this.props.match.params.id);
      const respondentIdentifier = loadStoredRespondentState();

      if (
        (this.props.surveysState?.currentSurvey?.survey.id !== surveyId ||
          !this.props.surveysState.currentSurvey) &&
        !this.props.surveysState.loading
      ) {
        if (
          respondentIdentifier &&
          this.props.surveysState?.currentSurvey?.language !==
            respondentIdentifier.lang
        ) {
          if (respondentIdentifier && respondentIdentifier.lang) {
            this.props.getSurvey(surveyId, respondentIdentifier.lang);
            this.props.getSurveyStructure(surveyId, respondentIdentifier.lang);
          }
        }
      }
    }

    public render() {
      return <Component />;
    }
  }

  const mapStateToProps = (state: IAppState): IStateProps => {
    return {
      surveysState: state.surveysState,
      authState: state.authState,
    };
  };

  const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return bindActionCreators(
      {
        getSurvey,
        getSurveyStructure,
        getSurveyResponses,
        getSurveyIdentificationInfo,
      },
      dispatch
    );
  };

  return withRouter(
    connect(mapStateToProps, mapDispatchToProps)(requiresSurveyData)
  );
};

import React, { useEffect, useRef } from 'react';
import { Divider, makeStyles, Paper } from "@material-ui/core";
import { createStyles, Theme } from "@material-ui/core/styles";
import { MessageLeft, MessageRight } from "./Message";
import { IWhistleblowerApiEntity } from "../types/Whistleblower";
import { simpleDateFormat } from "../../../../Helpers";
import { WhistleblowerSubmitForm } from "../forms/WhistleblowerSubmitForm";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PhotoIcon from "@material-ui/icons/Photo";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import { useTranslation } from "react-i18next";
import { Alert } from "@material-ui/lab";
import { usePageContentQuery } from "../../../../shared/hooks/usePageContentQuery";
import { ContentPage } from "../../../../types/Content";
import Loader from "../../../../components/Loader";
import Paragraph from '../../../../components/cms/Paragraph';
import { fetchAndReplaceAnchors } from '../../../../helpers/AnchorLoader';

interface Props {
  data: IWhistleblowerApiEntity;
  code: string;
  onUpdate?: (data: IWhistleblowerApiEntity) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      margin: theme.spacing(1, 0, 0)
    },
    messagesBody: {
      margin: theme.spacing(1, 1, 0),
      overflowY: "scroll",
      boxShadow: "none",
      width: "100%"
    },
    filename: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "block",
      overflow: "hidden",
      color: "black"
    }
  })
);

export const CommunicationChat: React.FC<Props> = ({
  data,
  code,
  onUpdate
}) => {
  const { data: pageContent, isLoading } = usePageContentQuery(
    ContentPage.WHISTLEBLOWER
  );
  const { t } = useTranslation();
  const classes = useStyles();
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      fetchAndReplaceAnchors(container);
    }
  }, [data]);

  if (isLoading) return <Loader visible={true} />;

  return (
    <div ref={containerRef as React.RefObject<HTMLDivElement>}>
      <Alert severity="info">
        {pageContent ? <Paragraph body={pageContent?.articles[4].bodyText} /> : null}
      </Alert>
      <Paper className={classes.paper}>
        <Paper className={classes.messagesBody}>
          {data.updates.map((update, index) => {
            return (
              <React.Fragment key={index}>
                {update.sender ? (
                  <MessageLeft
                    message={update.message}
                    timestamp={simpleDateFormat(update.sentAt)}
                    displayName={update.sender}
                  />
                ) : (
                  <MessageRight
                    message={update.message}
                    timestamp={simpleDateFormat(update.sentAt)}
                    displayName={t("whistleblower.anonymous")}
                  />
                )}
              </React.Fragment>
            );
          })}
        </Paper>
        {data.images ? (
          <>
            <Divider />
            <List>
              {data.images.map(image => {
                return (
                  <ListItem
                    key={image.id}
                    component="a"
                    href={image.url}
                    target="_blank"
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <PhotoIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={image.filename}
                      className={classes.filename}
                    />
                  </ListItem>
                );
              })}
            </List>
          </>
        ) : null}
      </Paper>
      <WhistleblowerSubmitForm code={code} onUpdate={onUpdate} />
    </div>
  );
};

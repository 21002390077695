import { apiCall } from './BaseAction';
import {
  AnonymousUserLogin,
  ChangeForgotPassword,
  ChangePassword,
  CheckPasswordToken,
  GetAppSettings,
  OAuthServiceInit,
  ProfileEdit,
  ResetPassword,
  TAnonymousUserLoginAction,
  TApiAuthEntity,
  TChangeForgotPasswordAction,
  TChangePasswordAction,
  TCheckPasswordToken,
  TGetAppSettingsAction,
  TOAuthServiceInitAction,
  TProfileEditAction,
  TResetPasswordAction,
  TUserLoginAction,
  TUserLogoutAction,
  UserCheckToken,
  UserLogin,
  UserLogout,
} from '../types/Auth';
import { Dispatch } from 'redux';
import { ClearStoreState, TClearStoreStateAction } from '../types/App';

type TUserLoginRequestData = {
  username: string;
  password: string;
  'g-recaptcha-response'?: string;
};

export const loginUser = (
  username: string,
  password: string,
  recaptcha?: string
) => {
  return apiCall<TUserLoginAction, TUserLoginRequestData, TApiAuthEntity>(
    UserLogin,
    'POST',
    '/auth/login/password',
    false,
    { username, password, 'g-recaptcha-response': recaptcha }
  );
};

type TUnifiedUserLoginRequestData = {
  key: string;
};

export const unifiedUserLogin = (key: string) => {
  return apiCall<TUserLoginAction, TUnifiedUserLoginRequestData>(
    UserLogin,
    'POST',
    '/auth/login/unified-login',
    false,
    { key }
  );
};

export const oAuthServiceInit = (service: 'microsoft' | 'google', params?: Record<string, string>) => {
  const generateParams = () => {
    if (params) {
      return Object.keys(params).reduce((acc, key) => {
        return `${acc}&payload[${key}]=${params[key]}`;
      }, '');
    }
    return '';
  }

  return apiCall<TOAuthServiceInitAction>(
    OAuthServiceInit,
    'GET',
    `/oauth/init?${generateParams()}`,
    false,
    null,
    { service }
  );
};

export const loginAnonymousUser = () => {
  return apiCall<TAnonymousUserLoginAction>(
    AnonymousUserLogin,
    'GET',
    '/auth/login/anonymous'
  );
};

export const checkUserToken = () => {
  return apiCall<TUserLoginAction, null, TApiAuthEntity>(
    UserCheckToken,
    'GET',
    '/auth/checkToken',
    true
  );
};

export const logoutUser = () => {
  return apiCall<TUserLogoutAction, null>(
    UserLogout,
    'POST',
    '/auth/logout',
    true
  );
};

type TEditProfileRequestData = {
  name: string;
};

export const editProfile = (name: string) => {
  return apiCall<TProfileEditAction, TEditProfileRequestData>(
    ProfileEdit,
    'POST',
    '/profile/change',
    true,
    { name }
  );
};

type TPasswordChangeRequestData = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export const changePassword = (
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string
) => {
  return apiCall<TChangePasswordAction, TPasswordChangeRequestData>(
    ChangePassword,
    'POST',
    '/password/change',
    true,
    { currentPassword, newPassword, confirmNewPassword }
  );
};

export const resetPassword = (emailAddress: string) => {
  return apiCall<TResetPasswordAction>(
    ResetPassword,
    'POST',
    '/password/forgot',
    false,
    { emailAddress }
  );
};

export const checkPasswordToken = (token: string) => {
  return apiCall<TCheckPasswordToken>(
    CheckPasswordToken,
    'GET',
    '/password/reset',
    false,
    null,
    { token }
  );
};

export const changeForgotPassword = (
  token: string,
  password: string,
  passwordRepeat: string
) => {
  return apiCall<TChangeForgotPasswordAction>(
    ChangeForgotPassword,
    'POST',
    '/password/reset',
    false,
    { token, password, passwordRepeat }
  );
};

export const getAppSettings = () => {
  return apiCall<TGetAppSettingsAction>(GetAppSettings, 'GET', '/app/settings');
};

export const clearStoreState = () => {
  return (dispatch: Dispatch<TClearStoreStateAction>) => {
    dispatch({
      type: ClearStoreState.SUBMIT,
    });
  };
};

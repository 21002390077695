import { ContentPage, TContentState } from '../types/Content';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  getPageContentById,
  getPageContentByType,
} from '../actions/ContentActions';
import { IAppState } from '../store/Store';
import { TAuthState } from '../types/Auth';

export const useCMS = (pageType: ContentPage | null, pageId?: number) => {
  const dispatch = useDispatch();
  const contentState: TContentState = useSelector<IAppState, TContentState>(
    (state) => state.contentState
  );
  const authState: TAuthState = useSelector<IAppState, TAuthState>(
    (state) => state.authState
  );

  useEffect(() => {
    if (pageId) {
      if (contentState.data?.id !== pageId) {
        dispatch(getPageContentById(pageId));
      }
    } else {
      if (pageType) {
        dispatch(getPageContentByType(pageType as ContentPage));
      }
    }
    //eslint-disable-next-line
  }, [dispatch, pageType, pageId, authState.data?.user]);

  const getPageTitle = (): string => {
    return (contentState.data && contentState.data.name) as string;
  };

  const getPageContent = () => {
    return contentState.data && contentState.data.articles;
  };

  const getPageImages = () => {
    return contentState.data && contentState.data.images;
  };

  const getPageType = () => {
    return contentState.data && (contentState.data.type as number);
  };

  return { cms: { getPageTitle, getPageContent, getPageType, getPageImages } };
};

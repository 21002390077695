import { Reducer } from 'redux';
import {
  FilterProcessList,
  initialProcessState,
  Process,
  ProcessCategories,
  ProcessList,
  TProcessActions,
  TProcessState,
} from '../types/Process';
import { endReducer } from '../store/Store';


export const processReducer: Reducer<
  TProcessState,
  TProcessActions
> = (state = initialProcessState, action) => {
  switch (action.type) {
    case ProcessCategories.SUCCESS: {
      return {
        ...state,
        categories: action.payload.data.categories,
      };
    }

    case ProcessCategories.FAILED:
      return state;

    case ProcessList.REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }

    case ProcessList.SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload.data.processes,
        filteredList: action.payload.data.processes,
      };
    }

    case ProcessList.FAILED:
      return {
        ...state,
        loading: false,
      };

    case Process.REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }

    case Process.SUCCESS: {
      return {
        ...state,
        loading: false,
        activeProcess: action.payload.data.process,
      };
    }

    case Process.FAILED:
      return {
        ...state,
        loading: false,
      };

    case FilterProcessList.SUBMIT: {
      const searchPhrase = action.payload.search.toLocaleLowerCase();

      return {
        ...state,
        filteredList: state.data?.filter((val) => {
          return val.name
            .toLocaleLowerCase()
            .includes(searchPhrase);
        }),
        searchPhrase
      };
    }

    default:
      return endReducer(state, action);
  }
}
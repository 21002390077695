import * as React from 'react';
import { Route } from 'react-router-dom';
import Navbar from '../navbar/Navbar';
import { useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import { ComponentClass, FC, ReactElement } from 'react';
import { RouteProps } from 'react-router';
import { ContentPage, TContentState } from '../../types/Content';
import { IAppState } from '../../store/Store';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, useMediaQuery, useTheme } from '@material-ui/core';

interface IDynamicRouteParams extends RouteProps {
  component: ComponentClass | FC;
  navbarText?: string | ReactElement<any>;
  showBackButton?: boolean;
  pageType?: ContentPage;
  hideNavbar?: boolean;
  hideAccountMenu?: boolean;
  customIcon?: ComponentClass | FC;
}

const useStyles = makeStyles((theme: Theme) => ({
  mainContainer: {
    margin: '75px auto 65px',
  },
}));

export const DynamicRoute = ({
  component,
  navbarText,
  showBackButton,
  pageType,
  hideNavbar,
  hideAccountMenu,
  customIcon,

  ...rest
}: IDynamicRouteParams) => {
  const classes = useStyles();
  const contentState: TContentState = useSelector<IAppState, TContentState>(
    (state) => state.contentState
  );
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  let pageTitle = navbarText;
  if (pageType) {
    pageTitle = contentState?.data?.name;
  }
  if (
    contentState.currentPageTitle?.title &&
    contentState.currentPageTitle.url === window.location.pathname
  ) {
    pageTitle = contentState.currentPageTitle.title;
  }

  const routeComponent = (props: any) => (
    <>
      {pageTitle && !hideNavbar && (
        <Navbar
          text={pageTitle ? pageTitle : ''}
          visible={true}
          showBackButton={showBackButton}
          hideAccountMenu={hideAccountMenu}
          customIcon={customIcon}
        />
      )}
      <Container
        component='main'
        maxWidth={isXs ? 'xs' : 'lg'}
        className={classes.mainContainer}
      >
        {React.createElement(component, props)}
      </Container>
    </>
  );
  return <Route {...rest} render={routeComponent} />;
};

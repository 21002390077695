import * as React from 'react';
import ChangePasswordForm from '../../../components/forms/profile/ChangePasswordForm';
import { FormEvent, useState } from 'react';
import { useApp } from '../../../hooks/useApp';
import { ChangePassword } from '../../../types/Auth';
import { useDispatch } from 'react-redux';
import { TDispatch } from '../../../types/Thunk';
import { changePassword } from '../../../actions/AuthActions';
import AlertBlock from '../../../components/AlertBlock';
import { useTranslation } from 'react-i18next';

export const ChangePasswordPage: React.FC = (): JSX.Element => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const { app } = useApp();
  const dispatch = useDispatch<TDispatch>();
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();

  const submitPasswordChange = (e: FormEvent) => {
    e.preventDefault();
    dispatch(
      changePassword(currentPassword, newPassword, confirmNewPassword)
    ).then(() => {
      setSuccess(true);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    });
  };

  return (
    <>
      {success && (
        <AlertBlock type={'success'} onClose={() => setSuccess(false)}>
          {t('profile.password.change.success.message')}
        </AlertBlock>
      )}
      <ChangePasswordForm
        errors={app.getError(ChangePassword)}
        currentPassword={currentPassword}
        setCurrentPassword={setCurrentPassword}
        newPassword={newPassword}
        setNewPassword={setNewPassword}
        confirmNewPassword={confirmNewPassword}
        setConfirmNewPassword={setConfirmNewPassword}
        submitForm={submitPasswordChange}
      />
    </>
  );
};

export default ChangePasswordPage;

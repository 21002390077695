import * as React from 'react';
import { useEffect } from 'react';
import CategoryDropdown from '../../../components/actionplan/CategoryDropdown';
import {
  ActionPlanRiskLevel,
  ActionPlanStatus,
  TActionPlanState,
} from '../../../types/Actionplan';
import ActionPlanList from '../../../components/actionplan/ActionPlanList';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '../../../Routes';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActionPlanList,
  getCategories,
} from '../../../actions/ActionPlanActions';
import { IAppState } from '../../../store/Store';
import CachedIcon from '@material-ui/icons/Cached';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ContentPage } from '../../../types/Content';
import PageContent from '../../../components/PageContent';

export const getActionRiskStyling = (actionPlanRisk: ActionPlanRiskLevel) => {
  switch (actionPlanRisk) {
    case ActionPlanRiskLevel.HIGH:
      return '#CC3E44';

    case ActionPlanRiskLevel.MEDIUM:
      return '#FABA03';

    default:
      return '#8DC63F';
  }
};

export const getActionPlanStyling = (
  actionPlanStatus: ActionPlanStatus
): { color: string; icon: JSX.Element } => {
  switch (actionPlanStatus) {
    case ActionPlanStatus.INPROGRESS:
    case ActionPlanStatus.ONHOLD: {
      return {
        color: '#2962C6',
        icon: <CachedIcon fontSize={'large'} />,
      };
    }

    case ActionPlanStatus.RESOLVED: {
      return {
        color: '#8DC63F',
        icon: <CheckCircleIcon fontSize={'large'} />,
      };
    }

    default:
      return {
        color: '#2962C6',
        icon: <CachedIcon fontSize={'large'} />,
      };
  }
};

const ActionPlanListPage: React.FC<RouteComponentProps<any>> = (
  props
): JSX.Element => {
  const [category, setCategory] = React.useState<string>('');
  const dispatch = useDispatch();
  const actionPlanState: TActionPlanState = useSelector<
    IAppState,
    TActionPlanState
  >((state) => state.actionPlanState);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getActionPlanList());
  }, [dispatch]);

  const handleCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setCategory(event.target.value as string);
    dispatch(getActionPlanList(event.target.value as number));
  };

  const onActionPlanSelect = (id: number) => {
    props.history.push(reverse(routes.actionPlanItem, { id }));
  };

  return (
    <>
      <PageContent pageType={ContentPage.ACTIONPLANS} showAsParagraph={true} />
      {actionPlanState.categories && (
        <CategoryDropdown
          category={category}
          handleCategoryChange={handleCategoryChange}
          categories={actionPlanState.categories}
        />
      )}
      {actionPlanState.data && (
        <ActionPlanList
          actionPlans={actionPlanState.data}
          onSelect={onActionPlanSelect}
        />
      )}
    </>
  );
};

export default withRouter(ActionPlanListPage);

import { apiCall } from './BaseAction';
import {
  ActionPlan,
  ActionPlanCategories,
  ActionPlanList,
  TApiActionPlanCategory,
  TApiActionPlanEntity,
  TGetActionPlan,
  TGetActionPlanCategories,
  TGetActionPlanList,
} from '../types/Actionplan';

export const getCategories = () => {
  return apiCall<TGetActionPlanCategories, null, Array<TApiActionPlanCategory>>(
    ActionPlanCategories,
    'GET',
    '/actionplans/categories',
    true
  );
};

export const getActionPlanList = (categoryId?: number) => {
  return apiCall<TGetActionPlanList, null, Array<TApiActionPlanEntity>>(
    ActionPlanList,
    'GET',
    '/actionplans',
    true,
    null,
    { category: categoryId }
  );
};

export const getActionPlan = (actionPlanId: number) => {
  return apiCall<TGetActionPlan, null, TApiActionPlanEntity>(
    ActionPlan,
    'GET',
    `/actionplan/${actionPlanId}`,
    true
  );
};

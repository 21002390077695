import * as React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Alert, Color } from "@material-ui/lab";
import { useState } from "react";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

interface IAlertBlockProps {
  type: Color;
  onClose?: () => any;
  hideCloseButton?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    width: "100%",
    margin: theme.spacing(0, 0, 1)
  }
}));

const AlertBlock: React.FC<IAlertBlockProps> = (props): JSX.Element => {
  const classes = useStyles();
  const [open, setOpen] = useState(!!props.children);

  return (
    <Collapse in={open} className={classes.alert}>
      <Alert
        style={{ wordBreak: "break-word" }}
        action={
          props.hideCloseButton ? null : (
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
                if (typeof props.onClose !== "undefined") {
                  props.onClose();
                }
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          )
        }
        severity={props.type}
      >
        {props.children}
      </Alert>
    </Collapse>
  );
};

export default AlertBlock;

import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { FormEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AlertBlock from '../../AlertBlock';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { routes } from '../../../Routes';

interface ILoginFormProps {
  password: string;
  setPassword: (value: React.SetStateAction<string>) => void;
  passwordRepeat: string;
  setPasswordRepeat: (value: React.SetStateAction<string>) => void;
  submitForm: (e: FormEvent) => void;
  errors: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: '20px',
  },
}));

const PasswordResetForm: React.FC<ILoginFormProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      {props.errors && (
        <AlertBlock type={'error'} hideCloseButton={true}>
          {props.errors.message}
        </AlertBlock>
      )}
      <form className={classes.form} noValidate onSubmit={props.submitForm}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='password'
          label={t('auth.remind.newPassword')}
          name='password'
          autoFocus
          value={props.password}
          error={!!props.errors?.fields?.password}
          helperText={
            !!props.errors?.fields?.password
              ? props.errors?.fields?.password
              : t('password.reset.helperText')
          }
          type={'password'}
          onChange={(e) => props.setPassword(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='passwordRepeat'
          label={t('auth.remind.newPasswordRepeat')}
          name='passwordRepeat'
          value={props.passwordRepeat}
          error={!!props.errors?.fields?.passwordRepeat}
          helperText={props.errors?.fields?.passwordRepeat}
          type={'password'}
          onChange={(e) => props.setPasswordRepeat(e.target.value)}
        />
        <Grid container>
          <Grid item xs className={classes.forgotLink}>
            <Link to={routes.login}>{t('auth.login.link')}</Link>
          </Grid>
          <Grid item xs>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              {t('auth.remind.submit')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PasswordResetForm;

import { Reducer } from 'redux';
import {
  CaseType,
  DeleteCasePhoto,
  GetCaseTypes,
  initialCasesState,
  ReportCase,
  TApiCasePhoto,
  TApiCaseType,
  TCasesActions,
  TCasesState,
  UploadCasePhoto,
  ClearCasePhotos,
} from '../types/Cases';
import { endReducer } from '../store/Store';
import { filter } from 'lodash';

export const caseReducer: Reducer<TCasesState, TCasesActions> = (
  state = initialCasesState,
  action
) => {
  switch (action.type) {
    case GetCaseTypes.REQUEST:
    case DeleteCasePhoto.REQUEST:
    case UploadCasePhoto.REQUEST:
    case ReportCase.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetCaseTypes.FAILED:
    case UploadCasePhoto.FAILED:
    case ReportCase.FAILED:
    case DeleteCasePhoto.FAILED:
      return {
        ...state,
        loading: false,
      };

    case GetCaseTypes.SUCCESS:
      let types: Array<TApiCaseType> = filter(
        action.payload.data.types,
        (type) => type.canReport && type.type !== CaseType.OTHER
      );

      return {
        loading: false,
        data: types,
        departments: action.payload.data.departments,
        locations: action.payload.data.locations,
        enabled: action.payload.data.enabled,
      };

    case UploadCasePhoto.SUCCESS:
      return {
        ...state,
        loading: false,
        photos: state.photos
          ? [...state.photos, action.payload.data]
          : [action.payload.data],
      };

    case DeleteCasePhoto.SUCCESS:
      return {
        ...state,
        loading: false,
        photos: filter(
          state.photos as Array<TApiCasePhoto>,
          (p) => p.id !== action.payload.data.photoId
        ),
      };

    case ReportCase.SUCCESS:
      return {
        ...state,
        loading: false,
        photos: [],
      };
    case ClearCasePhotos.SUBMIT:
      return {
        ...state,
        photos: [],
      };

    default:
      return endReducer(state, action);
  }
};

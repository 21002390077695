import * as React from 'react';
import { ChangeEvent } from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import {
  TApiChemicalProductProducer,
  TApiChemicalProductVendor,
} from '../../../types/Chemical';
import { useChemicalProductStyles } from '../ChemicalProduct.styles';
import { Grid } from '@material-ui/core';

interface ICompanyProps {
  title: string;
  producer?: TApiChemicalProductProducer;
  vendor?: TApiChemicalProductVendor;
  expanded: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
  panelId: number;
}

const Company: React.FC<ICompanyProps> = ({
  title,
  producer,
  vendor,
  expanded,
  handleChange,
  panelId,
}): JSX.Element => {
  const classes = useChemicalProductStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange(`panel${panelId}`)}
      key={`panel${panelId}`}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${panelId}bh-content`}
        id={`panel${panelId}bh-header`}
      >
        <Typography className={classes.heading}>{title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={2}>
          {vendor && (
            <Grid item xs={6}>
              <div className={classes.companyDetails}>
                <h3>{vendor.name}</h3>
                <p>
                  {vendor.country}, {vendor.city}, {vendor.streetAddress},{' '}
                  {vendor.postalCode}
                  <br />
                  {vendor.email}
                  <br />
                  {vendor.phone}
                </p>
              </div>
            </Grid>
          )}
          {producer && (
            <Grid item xs={6}>
              <div className={classes.companyDetails}>
                <h3>{producer.name}</h3>
                <p>
                  {producer.country}, {producer.city}, {producer.streetAddress},{' '}
                  {producer.postalCode}
                  <br />
                  {producer.email}
                  <br />
                  {producer.phone}
                </p>
              </div>
            </Grid>
          )}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Company;

import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { TApiActionPlanCategory } from '../../types/Actionplan';
import { useTranslation } from 'react-i18next';

interface ICategoryDropdownProps {
  category: string;
  handleCategoryChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  categories: Array<TApiActionPlanCategory>;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3, 0, 1),
      width: '100%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      backgroundColor: 'white',
      padding: '0 .2rem',
    },
  })
);

const CategoryDropdown: React.FC<ICategoryDropdownProps> = (
  props
): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  return (
    <FormControl variant='outlined' className={classes.formControl}>
      <InputLabel
        ref={inputLabel}
        id='actionplan-category-label'
        shrink
        className={classes.label}
      >
        {t('actionplan.category.select.label')}
      </InputLabel>
      <Select
        labelId='actionplan-category'
        id='actionplan-category'
        displayEmpty
        value={props.category}
        onChange={props.handleCategoryChange}
        labelWidth={labelWidth}
        defaultValue={''}
      >
        <MenuItem value={''} selected>
          <em>{t('actionplan.category.select.all')}</em>
        </MenuItem>
        {props.categories.map((category) => {
          return (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CategoryDropdown;

import { AxiosResponse } from 'axios';

export interface TApiCaseType {
  type: CaseType;
  title: string;
  canReport: boolean;
  reportUrl?: string;
}

export interface departmentLocationTreeNode {
  0: {
    entity: {
      id: number;
      title: string;
    };
  };
  1: number;
}

export enum CaseType {
  SAFETY_ISSUE = 1,
  NEAR_MISS_ACCIDENT = 2,
  OTHER = 3,
  GENERAL_WORKPLACE_ENVIRONMENT_ISSUE = 4,
  WHISTLEBLOWER = 5,
  WORKPLACE_ACCIDENT = 6,
}

export interface TApiCasePhoto {
  id: number;
  filename: string;
  baseUrl: string;
  mimeType: string;
}

export interface TCasesState {
  readonly data?: Array<TApiCaseType>;
  readonly loading?: boolean;
  readonly photos?: any;
  readonly enabled?: boolean;
  readonly departments?: Array<departmentLocationTreeNode>;
  readonly locations?: Array<departmentLocationTreeNode>;
}

export const initialCasesState: TCasesState = {
  data: undefined,
};

export enum GetCaseTypes {
  REQUEST = 'GET_CASE_TYPES_REQUEST',
  SUCCESS = 'GET_CASE_TYPES_SUCCESS',
  FAILED = 'GET_CASE_TYPES_FAILED',
}

export type TGetCaseTypesAction =
  | {
      type: GetCaseTypes.REQUEST;
    }
  | {
      type: GetCaseTypes.SUCCESS;
      payload: AxiosResponse<{
        departments: Array<departmentLocationTreeNode>;
        locations: Array<departmentLocationTreeNode>;
        types: Array<TApiCaseType>;
        enabled: boolean;
      }>;
    }
  | {
      type: GetCaseTypes.FAILED;
      payload: AxiosResponse<any>;
    };

export enum UploadCasePhoto {
  REQUEST = 'UPLOAD_CASE_PHOTO_REQUEST',
  SUCCESS = 'UPLOAD_CASE_PHOTO_SUCCESS',
  FAILED = 'UPLOAD_CASE_PHOTO_FAILED',
}

export type TUploadCasePhotoAction =
  | {
      type: UploadCasePhoto.REQUEST;
    }
  | {
      type: UploadCasePhoto.SUCCESS;
      payload: AxiosResponse<TApiCasePhoto>;
    }
  | {
      type: UploadCasePhoto.FAILED;
      payload: AxiosResponse<any>;
    };

export enum DeleteCasePhoto {
  REQUEST = 'DELETE_CASE_PHOTO_REQUEST',
  SUCCESS = 'DELETE_CASE_PHOTO_SUCCESS',
  FAILED = 'DELETE_CASE_PHOTO_FAILED',
}

export type TDeleteCasePhotoAction =
  | {
      type: DeleteCasePhoto.REQUEST;
    }
  | {
      type: DeleteCasePhoto.SUCCESS;
      payload: AxiosResponse<{ photoId: number }>;
    }
  | {
      type: DeleteCasePhoto.FAILED;
      payload: AxiosResponse<any>;
    };

export enum ReportCase {
  REQUEST = 'REPORT_CASE_REQUEST',
  SUCCESS = 'REPORT_CASE_SUCCESS',
  FAILED = 'REPORT_CASE_FAILED',
}

export type TReportCaseAction =
  | {
      type: ReportCase.REQUEST;
    }
  | {
      type: ReportCase.SUCCESS;
      payload: AxiosResponse<{ caseId: number }>;
    }
  | {
      type: ReportCase.FAILED;
      payload: AxiosResponse<any>;
    };

export enum ClearCasePhotos {
  SUBMIT = 'CLEAR_CASE_PHOTOS',
}

export type TClearCasePhotosAction = {
  type: ClearCasePhotos.SUBMIT;
};

export type TCasesActions =
  | TGetCaseTypesAction
  | TUploadCasePhotoAction
  | TDeleteCasePhotoAction
  | TReportCaseAction
  | TClearCasePhotosAction;

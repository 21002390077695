import * as React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { TApiQuestionEntity } from '../../types/Survey';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import SurveyTranslation from '../survey/SurveyTranslation';

interface IQuestionHelpTextModal {
  modalOpen: boolean;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
  question: TApiQuestionEntity;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const QuestionHelpTextModal: React.FC<IQuestionHelpTextModal> = ({
  modalOpen,
  setModalOpen,
  question,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle disableTypography>
        <IconButton
          aria-label='close'
          className={classes.closeButton}
          onClick={() => setModalOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          <p dangerouslySetInnerHTML={{ __html: question.helpText }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setModalOpen(false)} color='primary' autoFocus>
          <SurveyTranslation translate={'survey.question.help.modal.close'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionHelpTextModal;

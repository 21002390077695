import * as React from "react";
import { FormEvent } from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AlertBlock from "../../AlertBlock";
import { TApiUserDepartment, TApiUserLocation } from "../../../types/User";
import FormHelperText from "@material-ui/core/FormHelperText";
import { TSurveysState } from "../../../types/Survey";
import { isEmpty } from "lodash";
import SurveyTranslation from "../../survey/SurveyTranslation";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: "100%",
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(8),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

interface IIdentificationFormProps {
  name?: string;
  setName?: (value: React.SetStateAction<string>) => void;
  location: TApiUserLocation;
  setLocation: (value: React.SetStateAction<TApiUserLocation>) => void;
  organization?: TApiUserDepartment;
  setOrganization?: (value: React.SetStateAction<TApiUserDepartment>) => void;
  segmentationId: string | null;
  selectSegmentationOrganisation: string | null;
  selectSegmentationLocation: string | null;
  segmentationContinueButton: string | null;
  submitForm: (e: FormEvent) => void;
  errors: any | undefined;
  survey: TSurveysState;
}

const printLevelMark = (level: number) => {
  let levelMark = "";
  if (level > 0) {
    for (let i = 0; i < level; i++) {
      levelMark = levelMark + "-";
    }
  }

  return <>{levelMark} </>;
};

const printDepartmentTree = (
  department: Array<TApiUserDepartment>,
  level: number = 0
): any => {
  return department.map((department) => {
    return [
      <MenuItem value={department.id} key={department.id}>
        {printLevelMark(level)}
        {department.title}
      </MenuItem>,
      department.children.length > 0 &&
        printDepartmentTree(department.children, level + 1),
    ];
  });
};
const printLocationTree = (
  location: Array<TApiUserLocation>,
  level: number = 0
): any => {
  return location.map((location) => {
    return [
      <MenuItem value={location.id} key={location.id}>
        {printLevelMark(level)}
        {location.title}
      </MenuItem>,
      location.children.length > 0 &&
        printLocationTree(location.children, level + 1),
    ];
  });
};

const IdentificationForm: React.FC<IIdentificationFormProps> = (
  props
): JSX.Element => {
  const classes = useStyles();

  return (
    <>
      {props.errors && (
        <AlertBlock type={"error"}>{props.errors.message}</AlertBlock>
      )}
      <form onSubmit={props.submitForm}>
        {props.survey.currentSurveyIdentification?.employeeIdentification && (
          <TextField
            variant="outlined"
            error={!!props.errors?.fields?.respondentName}
            helperText={props.errors?.fields?.respondentName}
            margin="normal"
            fullWidth
            id="name"
            label={
              props.segmentationId !== null ? (
                <>{props.segmentationId}</>
              ) : (
                <SurveyTranslation translate={"survey.identification.name"} />
              )
            }
            name="name"
            autoFocus
            value={props.name}
            onChange={(e) => {
              if (typeof props.setName !== "undefined") {
                props.setName(e.target.value);
              }
            }}
            type={"text"}
          />
        )}
        {!isEmpty(props.survey.currentSurveyIdentification?.departments) && (
          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel id="organization-label">
              {props.selectSegmentationOrganisation !== null ? (
                <>{props.selectSegmentationOrganisation}</>
              ) : (
                <SurveyTranslation
                  translate={"survey.identification.organization"}
                />
              )}
            </InputLabel>
            <Select
              labelId="organization-label"
              id="organization-select"
              onChange={(e) => {
                if (
                  typeof props.setOrganization !== "undefined" &&
                  typeof props.organization !== "undefined"
                ) {
                  props.setOrganization({
                    ...props.organization,
                    id: e.target.value as number,
                  });
                }
              }}
              label={
                props.selectSegmentationOrganisation !== null ? (
                  <>{props.selectSegmentationOrganisation}</>
                ) : (
                  <SurveyTranslation
                    translate={"survey.identification.organisation"}
                  />
                )
              }
            >
              <MenuItem value="" selected={true} disabled={true}>
                <SurveyTranslation
                  translate={"survey.identification.please_select"}
                />
              </MenuItem>

              {props.survey.currentSurveyIdentification?.departments &&
                printDepartmentTree(
                  props.survey.currentSurveyIdentification
                    ?.departments as Array<TApiUserDepartment>
                )}
            </Select>
            <FormHelperText>
              {props.errors?.fields?.departmentId}
            </FormHelperText>
          </FormControl>
        )}
        {!isEmpty(props.survey.currentSurveyIdentification?.locations) && (
          <FormControl variant="outlined" className={classes.formControl} required>
            <InputLabel id="location-label">
              {props.selectSegmentationLocation !== null ? (
                <>{props.selectSegmentationLocation}</>
              ) : (
                <SurveyTranslation
                  translate={"survey.identification.location"}
                />
              )}
            </InputLabel>
            <Select
              labelId="location-label"
              id="location-select"
              onChange={(e) =>
                props.setLocation({
                  ...props.location,
                  id: e.target.value as number,
                })
              }
              label={
                props.selectSegmentationLocation !== null ? (
                  <>{props.selectSegmentationLocation}</>
                ) : (
                  <SurveyTranslation
                    translate={"survey.identification.location"}
                  />
                )
              }
            >
              <MenuItem value="" selected={true} disabled={true}>
                <SurveyTranslation
                  translate={"survey.identification.please_select"}
                />
              </MenuItem>
              {props.survey.currentSurveyIdentification?.locations &&
                printLocationTree(
                  props.survey.currentSurveyIdentification
                    ?.locations as Array<TApiUserLocation>
                )}
            </Select>
            <FormHelperText>{props.errors?.fields?.locationId}</FormHelperText>
          </FormControl>
        )}
        <Grid container>
          <Grid item xs={12}>
            <Button type="submit" fullWidth variant="contained" color="primary">
              {props.segmentationContinueButton !== null ? (
                <>{props.segmentationContinueButton}</>
              ) : (
                <SurveyTranslation
                  translate={"survey.identification.submit.button"}
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default IdentificationForm;

import AlertBlock from '../../../../components/AlertBlock';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CasePhotoSelect from '../../../../components/cases/CasePhotoSelect';
import CasePhotoList from '../../../../components/cases/CasePhotoList';
import TextField from '@material-ui/core/TextField';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ReCAPTCHA from 'react-google-recaptcha';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormEvent, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  deletePhoto,
  uploadPhoto,
  clearPhotos,
} from '../../../../actions/CaseActions';
import { TApiCasePhoto, TCasesState } from '../../../../types/Cases';
import { useDispatch, useSelector } from 'react-redux';
import { TDispatch } from '../../../../types/Thunk';
import { IAppState } from '../../../../store/Store';
import {
  IWhistleblowerCreateRequest,
  IWhistleblowerCreateResponse,
  useWhistleblowerCreate,
} from '../hooks/useWhistleblowerCreate';
import {
  IWhistleblowerUpdateRequest,
  useWhistleblowerUpdate,
} from '../hooks/useWhistleblowerUpdate';
import axios from 'axios';
import { IWhistleblowerApiEntity } from '../types/Whistleblower';
import { Link } from 'react-router-dom';
import { routes } from '../../../../Routes';
import { usePageContentQuery } from '../../../../shared/hooks/usePageContentQuery';
import { ContentPage } from '../../../../types/Content';
import IconButton from '@material-ui/core/IconButton';
import Paragraph from '../../../../components/cms/Paragraph';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert, Color } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(3, 0, 3),
    },
    personalContainer: {
      border: '2px solid #6FADE7',
      padding: theme.spacing(2),
    },
    fileCopy: {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      textAlign: 'right',
    },
  })
);

export const WhistleblowerSubmitForm: React.FC<{
  code?: string;
  onUpdate?: (data: IWhistleblowerApiEntity) => void;
  onCreate?: (data: IWhistleblowerCreateResponse) => void;
}> = ({ code, onUpdate, onCreate }) => {
  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);
  const { data } = usePageContentQuery(ContentPage.WHISTLEBLOWER);

  const dispatch = useDispatch<TDispatch>();
  const caseState: TCasesState = useSelector<IAppState, TCasesState>(
    (state: IAppState) => state.caseState
  );
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackType, setSnackType] = React.useState<Color>('success');
  const [snackMessage, setSnackMessage] = React.useState('');
  const classes = useStyles();
  const { t } = useTranslation();
  const [problem, setProblem] = useState('');
  const [recaptcha, setRecaptcha] = useState('');

  const {
    mutate: createWhistleblower,
    isSuccess: isCreateSuccess,
    error: createError,
    data: createData,
  } = useWhistleblowerCreate();

  const { mutate: updateWhistleblower, error: updateError } =
    useWhistleblowerUpdate();

  const deleteUploadedPhoto = async (photoId: number) => {
    await dispatch(deletePhoto(photoId));
  };

  const handlePhotoUpload = async (fileName: string, base64Content: string) => {
    await dispatch(
      uploadPhoto(fileName, base64Content, {
        whistleblower: true,
      })
    );
  };

  const copyToClipboard = () => {
    const whistleblowerLink = `${window.location.origin}${routes.prefix}${routes.whistleblowerCommunication}`;
    const whistleblowerCode = createData?.data.code ?? '';

    navigator.clipboard.writeText(`
      ${t('whistleblower.report.success.link')}
      ${whistleblowerLink}
      ${t('whistleblower.report.success.personal_code')} ${whistleblowerCode}
    `).then(
        () => {
          setSnackMessage(t('whistleblower.report.copy_to_clipboard.success'));
          setOpenSnack(true);
        })
      .catch((e) => {
        setSnackType('error');
        setSnackMessage(t('whistleblower.report.copy_to_clipboard.error'));
        setOpenSnack(true);

        throw e;
      });
  }

  const submitCaseReport = async (e: FormEvent) => {
    e.preventDefault();
    const photos: Array<number> = [];
    if (caseState.photos && caseState.photos.length > 0) {
      caseState.photos.forEach((photo: TApiCasePhoto) => {
        return photos.push(photo.id);
      });
    }

    if (code) {
      const requestData: IWhistleblowerUpdateRequest = {
        message: problem,
        photos,
        code,
      };

      return updateWhistleblower(requestData, {
        onSuccess: async (data) => {
          setProblem('');
          await dispatch(clearPhotos());

          if (typeof onUpdate === 'function') {
            onUpdate(data.data);
          }
        },
        onSettled: () => {
          window.scrollTo(0, 0);
        },
      });
    }

    const requestData: IWhistleblowerCreateRequest = {
      problemDescription: problem,
      photos,
      recaptcha,
    };

    return createWhistleblower(requestData, {
      onSuccess: (data) => {
        setProblem('');
        setIsFormSubmitted(true);

        if (typeof onCreate === 'function') {
          onCreate(data.data);
        }
      },
      onSettled: () => {
        window.scrollTo(0, 0);
      },
    });
  };

  return (
    <>
      {isCreateSuccess && data && (
        <>
          <AlertBlock type='success'>
            {data ? <Paragraph body={data?.articles[1].bodyText} /> : null}
          </AlertBlock>
          <AlertBlock type='info'>
            {data ? <Paragraph body={data?.articles[2].bodyText} /> : null}
            <p className={classes.personalContainer}>
              <p>
                <b>{t('whistleblower.report.success.link')}</b>:{' '}
                <Link to={routes.whistleblowerCommunication} target='_blank'>
                  {`${window.location.origin}${routes.prefix}${routes.whistleblowerCommunication}`}
                </Link>
              </p>
              <p>
                <b>{t('whistleblower.report.success.personal_code')}</b>:{' '}
                {createData?.data.code}
              </p>
              <p className={classes.fileCopy}>
                <IconButton
                  aria-label='copy'
                  onClick={() => copyToClipboard()}
                >
                  <FileCopyIcon
                    color="primary"
                  />
                </IconButton>
              </p>
            </p>
          </AlertBlock>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={openSnack}
            autoHideDuration={6000}
            onClose={() => setOpenSnack(false)}
          >
            <Alert severity={snackType} onClose={() => setOpenSnack(false)}>
              {snackMessage}
            </Alert>
          </Snackbar>
        </>
      )}
      {axios.isAxiosError(createError) ? (
        <AlertBlock type={'error'}>
          {createError.response?.data.message}
        </AlertBlock>
      ) : null}

      {axios.isAxiosError(updateError) ? (
        <AlertBlock type={'error'}>
          {updateError.response?.data.message}
        </AlertBlock>
      ) : null}

      {isFormSubmitted ? (
        <Grid container spacing={1} justifyContent="space-around">
          <Grid item xs={6}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => window.location.reload()}
            >
              {t('whistleblower.add_another')}
            </Button>
          </Grid>
        </Grid>
      ) : (
        <form onSubmit={submitCaseReport}>
          <CasePhotoSelect handlePhotoUpload={handlePhotoUpload} accept='*' />

          {caseState.photos && caseState.photos.length > 0 && (
            <CasePhotoList
              photos={caseState.photos}
              deleteUploadedPhoto={deleteUploadedPhoto}
            />
          )}

          <TextField
            variant='outlined'
            margin='normal'
            fullWidth
            name='password'
            label={
              code
                ? `${t('whistleblower.chat.label')}`
                : ` ${t('whistleblower.report.label')}`
            }
            type='text'
            id='problem'
            multiline
            rows={3}
            value={problem}
            onChange={(e) => setProblem(e.target.value)}
            helperText={code ? t('whistleblower.chat.helper_text') : null}
            required
          />

          {!code ? (
            <Grid container>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as any}
                  onChange={(value) => setRecaptcha(value as string)}
                />
              </Grid>
            </Grid>
          ) : null}

          <Grid container spacing={1} justifyContent="space-around">
            <Grid item xs={6}>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
              >
                {code
                  ? t('whistleblower.chat.submit.button')
                  : t('whistleblower.report.submit.button')}
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

import * as React from "react";
import { useState } from "react";
import QuestionIcons from "./QuestionIcons";
import List from "@material-ui/core/List";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import { TApiAnswerEntity, TApiQuestionEntity } from "../../types/Survey";
import ListItem from "@material-ui/core/ListItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FeedbackIcon from "@material-ui/icons/Feedback";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import QuestionHelpTextModal from "../modals/QuestionHelpTextModal";
import SurveyTranslation from "./SurveyTranslation";
import { makeStyles } from "@material-ui/core/styles";
import { Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { useContentState } from "../../hooks/useСontentState";

interface IQuestionPageProps {
  handleReportCaseClick?: () => void;
  question: TApiQuestionEntity;
  selectedAnswer: any;
  questionRequired: string | null;
  questionSkipButton: string | null;
  handleAnswerSubmit: (answerId: number) => void;
  comment: string;
  redirectToNextQuestion: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  chip: {
    backgroundColor: "orange",
  },
  reportCaseButton: {
    backgroundColor: "white",
  },
  answerButton: {
    border: "1px solid #e8e8e8",
    borderRadius: "8px",
    marginBottom: "8px",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "1px 1px 2px 0px rgba(0,0,0,0.15)",
  },
}));

const Question: React.FC<IQuestionPageProps> = ({
  handleReportCaseClick,
  question,
  selectedAnswer,
  questionRequired,
  questionSkipButton,
  handleAnswerSubmit,
  comment,
  redirectToNextQuestion,
}): JSX.Element => {
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyles();
  const { isCasePagePublished } = useContentState();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <QuestionHelpTextModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        question={question}
      />

      <h1 style={{ textAlign: "left" }}>{question.body}</h1>
      <QuestionIcons question={question} setModalOpen={setModalOpen} />
      <List component="nav" aria-label="secondary mailbox folder">
        <FormControl component="fieldset" style={{ width: "100%" }}>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={selectedAnswer?.id || ""}
          >
            <List component="nav" aria-labelledby="nested-list-subheader">
              {question.answers.map((answer: TApiAnswerEntity) => {
                return (
                  <ListItem
                    button
                    key={answer.id}
                    onClick={() => handleAnswerSubmit(answer.id)}
                    className={classes.answerButton}
                  >
                    <FormControlLabel
                      value={answer.id}
                      control={<Radio />}
                      label={answer?.title}
                    />
                    {selectedAnswer &&
                      answer &&
                      selectedAnswer?.id === answer?.id &&
                      comment && <FeedbackIcon />}
                  </ListItem>
                );
              })}
            </List>
          </RadioGroup>
        </FormControl>
      </List>
      <Grid container spacing={1} justify="flex-end">
        {handleReportCaseClick && isCasePagePublished && (
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              onClick={handleReportCaseClick}
              classes={{
                root: classes.reportCaseButton,
              }}
            >
              <SurveyTranslation translate={"case.report.submit.button"} />
            </Button>
          </Grid>
        )}
        <Grid item xs={isXs ? 6 : 3} style={{ alignSelf: "center", textAlign: "right" }}>
          {question.mandatory ? (
            <Chip
              label={
                questionRequired !== null ? (
                  questionRequired
                ) : (
                  <SurveyTranslation
                    translate={"survey.question.required.label"}
                  />
                )
              }
              classes={{
                root: classes.chip,
              }}
            />
          ) : (
            <Button
              fullWidth
              color="primary"
              onClick={() => redirectToNextQuestion()}
            >
              {questionSkipButton !== null ? (
                questionSkipButton
              ) : (
                <SurveyTranslation translate={"survey.question.skip"} />
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default Question;

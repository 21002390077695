import { AxiosError, AxiosResponse } from 'axios';

export type TApiChemicalProductProducer = {
  name: string;
  streetAddress: string;
  postalCode: string;
  city: string;
  country: string;
  phone: string;
  email: string;
};

export type TApiChemicalProductVendor = {
  name: string;
  streetAddress: string;
  postalCode: string;
  city: string;
  country: string;
  phone: string;
  email: string;
};

export type TApiChemicalProductSignalWord = {
  name: string;
  color: string;
}

export type TApiChemicalLocation = {
  title: string;
};

export type TApiChemicalProductEntity = {
  id: number;
  name: string;
  revisedDate: Date;
  image: string;
  producer: TApiChemicalProductProducer;
  vendor: TApiChemicalProductVendor;
  protectiveEquipment?: string;
  firstAidMeasures?: string;
  handlingAndStorage?: string;
  safetyDataSheet: string;
  locations: Array<TApiChemicalLocation>;
  adviceForFirefighters?: string;
  containmentAndCleanUp?: string;
  controlParameters?: string;
  disposalConsiderations?: string;
  emergencyInformation?: string;
  environmentalPrecautions?: string;
  exposureControls?: string;
  extinguishingMedia?: string;
  hazardClassification?: string;
  hazardStatements?: string;
  otherHazards?: string;
  personalPrecautions?: string;
  precautionaryStatements?: string;
  productIdentifier?: string;
  recommendations?: string;
  recommendedUse?: string;
  referenceToOtherSections?: string;
  signalWordType?: TApiChemicalProductSignalWord;
  specialFireHazards?: string;
  specificEndUses?: string;
  storage?: string;
  symptomsOrEffects?: string;
};

export type TApiChemicalProductHazard = {
  label: string;
  description: string;
  image: string;
};

export interface TChemicalState {
  readonly loading?: boolean;
  readonly data?: Array<TApiChemicalProductEntity>;
  readonly activeChemical?: {
    data: TApiChemicalProductEntity;
    hazards: Array<TApiChemicalProductHazard>;
    ppe: Array<TApiChemicalProductHazard>;
  };
  readonly filteredList?: Array<TApiChemicalProductEntity>;
  readonly searchPhrase?: string;
  readonly searchLocation?: string;
}

export const initialChemicalState: TChemicalState = {
  loading: false,
  data: undefined,
  activeChemical: undefined,
};

/*
    Get List
 */
export enum GetChemicalList {
  REQUEST = 'GET_CHEMICAL_LIST_REQUEST',
  SUCCESS = 'GET_CHEMICAL_LIST_SUCCESS',
  FAILED = 'GET_CHEMICAL_LIST_FAILED',
}

export type TGetChemicalListAction =
  | {
      type: GetChemicalList.REQUEST;
    }
  | {
      type: GetChemicalList.SUCCESS;
      payload: AxiosResponse<{ products: Array<TApiChemicalProductEntity> }>;
    }
  | {
      type: GetChemicalList.FAILED;
      payload: AxiosError;
    };

/*
    Get Chemical Product
 */
export enum GetChemicalProduct {
  REQUEST = 'GET_CHEMICAL_PRODUCT_REQUEST',
  SUCCESS = 'GET_CHEMICAL_PRODUCT_SUCCESS',
  FAILED = 'GET_CHEMICAL_PRODUCT_FAILED',
}

export type TGetChemicalProductAction =
  | {
      type: GetChemicalProduct.REQUEST;
    }
  | {
      type: GetChemicalProduct.SUCCESS;
      payload: AxiosResponse<{
        product: TApiChemicalProductEntity;
        hazards: Array<TApiChemicalProductHazard>;
        ppe: Array<TApiChemicalProductHazard>;
      }>;
    }
  | {
      type: GetChemicalProduct.FAILED;
      payload: AxiosError;
    };

export enum FilterChemicalsList {
  SUBMIT = 'FILTER_CHEMICALS_LIST',
}

export type TFilterChemicalsListActionPayload = {
  search: string;
  location: string;
};

export type TFilterChemicalsListAction = {
  type: FilterChemicalsList.SUBMIT;
  payload: TFilterChemicalsListActionPayload;
};

export type TChemicalActions =
  | TGetChemicalListAction
  | TGetChemicalProductAction
  | TFilterChemicalsListAction;

import { apiCall } from './BaseAction';
import { GetSurveyList, TGetSurveyListAction } from '../types/Survey';
import { Dispatch } from 'redux';
import {
  SaveInspectionQuestionData,
  TSaveInspectionQuestionDataAction,
} from '../types/Inspection';

export const getInspectionList = () => {
  return apiCall<TGetSurveyListAction, null>(
    GetSurveyList,
    'GET',
    '/inspections',
    true
  );
};

export const setInspectionQuestion = (status: boolean) => {
  return (dispatch: Dispatch<TSaveInspectionQuestionDataAction>) => {
    dispatch({
      type: SaveInspectionQuestionData.SET,
      payload: {
        status,
      },
    });
  };
};

export const setInspectionSent = (sent: boolean) => {
  return (dispatch: Dispatch<TSaveInspectionQuestionDataAction>) => {
    dispatch({
      type: SaveInspectionQuestionData.SENT,
      payload: {
        sent,
      },
    });
  };
};

export const setInspectionLocation = (locationId: number) => {
  return (dispatch: Dispatch<TSaveInspectionQuestionDataAction>) => {
    dispatch({
      type: SaveInspectionQuestionData.SET_LOCATION,
      payload: {
        locationId,
      },
    });
  };
};

import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  separator: {
    width: '100%',
  },
}));

const Separator: React.FC = (): JSX.Element => {
  const classes = useStyles();
  return <Divider variant='fullWidth' className={classes.separator} />;
};

export default Separator;

import * as React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Button from '@material-ui/core/Button';
import { routes } from '../Routes';
import { useTranslation } from 'react-i18next';
import { useApp } from '../hooks/useApp';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';

interface IErrorPageProps {
  message?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    noPadding: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
    },
    mtContainer: {
      marginTop: 119,
    },
  })
);

const ErrorPage: React.FC<IErrorPageProps> = ({ message }): JSX.Element => {
  const { t } = useTranslation();
  const { app } = useApp();
  const classes = useStyles();

  return (
    <>
      {!app.isLoading() && (
        <>
          <div
            className={clsx('MuiButton-containedPrimary', classes.noPadding)}
            style={{
              textAlign: 'center',
              marginBottom: '15px',
              paddingTop: '15px',
              paddingBottom: '15px',
            }}
          >
            <ErrorOutlineIcon
              style={{
                fontSize: '74px',
              }}
            />
          </div>
          <Container
            component='main'
            maxWidth='xs'
            className={classes.mtContainer}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography>
                  {message ? (
                    <p>{message}</p>
                  ) : (
                    <>
                      <p>{message || t('error_404.message_1')}</p>
                      <p>{message || t('error_404.message_2')}</p>
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  style={{ backgroundColor: '#ffffff' }}
                  onClick={() => window.location.reload()}
                >
                  {t('error.page.try.again')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  style={{ backgroundColor: '#ffffff' }}
                  onClick={() => window.location.replace(routes.home)}
                >
                  {t('error.page.go.home')}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='secondary'
                  onClick={() =>
                    window.location.replace(routes.prefix + routes.login)
                  }
                >
                  {t('error.page.login')}
                </Button>
              </Grid>
            </Grid>
          </Container>
        </>
      )}
    </>
  );
};

export default ErrorPage;

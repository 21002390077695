import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useChemicalListStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    searchField: {
      marginBottom: '30px',
      width: '100%',
    },
    gridList: {
      width: '100%',
    },
    icon: {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    formControl: {
      width: '100%',
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(8),
    },
  })
);

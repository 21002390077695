import * as React from 'react';
import { TApiCasePhoto } from '../../types/Cases';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PhotoIcon from '@material-ui/icons/Photo';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filename: {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
      overflow: 'hidden',
    },
  })
);

interface ICasePhotoListProps {
  photos: Array<TApiCasePhoto>;
  deleteUploadedPhoto: (photoId: number) => void;
}

const CasePhotoList: React.FC<ICasePhotoListProps> = ({
  photos,
  deleteUploadedPhoto,
}): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <Divider />
      <List>
        {photos.map((photo: TApiCasePhoto, index: number) => {
          return (
            <ListItem key={index}>
              <ListItemAvatar>
                <Avatar>
                  <PhotoIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={photo.filename}
                className={classes.filename}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge='end'
                  aria-label='delete'
                  onClick={() => deleteUploadedPhoto(photo.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
      <Divider />
    </>
  );
};

export default React.memo(CasePhotoList);

import * as React from 'react';
import { simpleDateFormat } from '../../Helpers';
import { TApiMeetingShortEntity } from '../../types/Meeting';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import GroupIcon from '@material-ui/icons/Group';
import { useTranslation } from 'react-i18next';

interface IMeetingListProps {
  meetings: Array<TApiMeetingShortEntity>;
  onSelect: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    iconEnabled: {
      backgroundColor: '#8dc63f',
      color: 'white',
    },
    iconDisabled: {
      backgroundColor: '#cc3f44',
      color: 'white',
    },
    meetingIcon: {
      fontSize: '2rem',
    },
  })
);

const MeetingList: React.FC<IMeetingListProps> = ({
  meetings,
  onSelect,
}): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <List className={classes.root}>
      {meetings.map((meeting: TApiMeetingShortEntity) => {
        return (
          <ListItem
            button
            key={meeting.id}
            onClick={() => onSelect(meeting.id)}
          >
            <ListItemAvatar>
              <Avatar
                className={
                  meeting.enabled ? classes.iconEnabled : classes.iconDisabled
                }
              >
                <GroupIcon fontSize={'large'} className={classes.meetingIcon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={meeting.name}
              secondary={
                <React.Fragment>
                  {meeting.enabled
                    ? t('meetings.status.open')
                    : t('meetings.status.closed')}
                  {' - '}
                  {meeting.organisations.join(', ')}
                  {' - '}
                  {simpleDateFormat(meeting.startTime)}
                </React.Fragment>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default MeetingList;

import { apiCall } from './BaseAction';
import {
  FilterChemicalsList,
  GetChemicalList,
  GetChemicalProduct,
  TFilterChemicalsListAction,
  TGetChemicalListAction,
  TGetChemicalProductAction,
} from '../types/Chemical';
import { Dispatch } from 'redux';

export const getChemicalsList = () => {
  return apiCall<TGetChemicalListAction, null>(
    GetChemicalList,
    'GET',
    '/chemical/products',
    true
  );
};

export const getChemicalProduct = (productId: number) => {
  return apiCall<TGetChemicalProductAction, null>(
    GetChemicalProduct,
    'GET',
    `/chemical/product/${productId}`,
    true
  );
};

export const filterChemicalsList = (search: string, location: string) => {
  return (dispatch: Dispatch<TFilterChemicalsListAction>) => {
    dispatch({
      type: FilterChemicalsList.SUBMIT,
      payload: {
        search,
        location,
      },
    });
  };
};

import * as React from 'react';
import { DynamicRoute } from './DynamicRoute';
import { routes } from '../../Routes';
import SurveyStartPage from '../../containers/pages/surveys/SurveyStartPage';
import SurveyIdentificationPage from '../../containers/pages/surveys/SurveyIdentificationPage';
import SurveyCategoryPage from '../../containers/pages/surveys/SurveyCategoryPage';
import SurveyQuestionPage from '../../containers/pages/surveys/SurveyQuestionPage';
import SurveyCategoryComment from '../../containers/pages/surveys/SurveyCategoryComment';
import SurveyEndPage from '../../containers/pages/surveys/SurveyEndPage';
import SurveySendAnswers from '../../containers/pages/surveys/SurveySendAnswers';
import SurveyPrintPage from '../../containers/pages/surveys/SurveyPrintPage';
import SurveyTranslation from '../survey/SurveyTranslation';
import { useUser } from '../../hooks/useUser';
import InspectionIdentificationPage from '../../containers/pages/inspections/InspectionIdentificationPage';
import { useCurrentActiveSurvey } from '../../hooks/useCurrentActiveSurvey';

const SurveyRoutes: React.FC = (): JSX.Element => {
  const { isUserAnonymous } = useUser();
  const { isInspection } = useCurrentActiveSurvey();

  return (
    <>
      <DynamicRoute
        navbarText={
          <SurveyTranslation
            translate={
              isInspection
                ? 'inspection.start.page.title'
                : 'survey.start.page.title'
            }
          />
        }
        showBackButton={true}
        key={'survey-start-page'}
        hideNavbar={isUserAnonymous()}
        exact
        path={routes.surveyStart}
        component={SurveyStartPage}
      />
      <DynamicRoute
        navbarText={
          <SurveyTranslation translate={'survey.identification.page.title'} />
        }
        showBackButton={true}
        key={'survey-identification-page'}
        exact
        path={routes.surveyIdentification}
        component={SurveyIdentificationPage}
      />
      <DynamicRoute
        navbarText={
          <SurveyTranslation
            translate={'inspection.identification.page.title'}
          />
        }
        showBackButton={true}
        key={'inspection-identification-page'}
        exact
        path={routes.inspectionIdentification}
        component={InspectionIdentificationPage}
      />
      <DynamicRoute
        navbarText={
          <SurveyTranslation translate={'survey.category.page.title'} />
        }
        showBackButton={true}
        key={'survey-category-page'}
        exact
        path={routes.surveyCategory}
        component={SurveyCategoryPage}
      />
      <DynamicRoute
        navbarText={
          <SurveyTranslation translate={'survey.question.page.title'} />
        }
        showBackButton={true}
        key={'survey-question-page'}
        exact
        path={routes.surveyQuestion}
        component={SurveyQuestionPage}
      />
      <DynamicRoute
        navbarText={
          <SurveyTranslation
            translate={
              isInspection
                ? 'inspection.category.comment.page.title'
                : 'survey.category.comment.page.title'
            }
          />
        }
        showBackButton={true}
        key={'survey-category-comment-page'}
        exact
        path={routes.surveyCategoryComment}
        component={SurveyCategoryComment}
      />
      <DynamicRoute
        navbarText={
          <SurveyTranslation
            translate={
              isInspection
                ? 'inspection.end.page.title'
                : 'survey.end.page.title'
            }
          />
        }
        key={'survey-end-page'}
        exact
        hideNavbar={isUserAnonymous()}
        path={routes.surveyEndPage}
        component={SurveyEndPage}
      />
      <DynamicRoute
        navbarText={
          <SurveyTranslation translate={'survey.send.answers.page.title'} />
        }
        showBackButton={true}
        hideNavbar={isUserAnonymous()}
        key={'survey-send-answers-page'}
        exact
        path={routes.surveySendAnswersPage}
        component={SurveySendAnswers}
      />
      <DynamicRoute
        navbarText={<SurveyTranslation translate={'survey.print.page.title'} />}
        hideNavbar={true}
        key={'survey-print-page'}
        exact
        path={routes.surveyPrintPage}
        component={SurveyPrintPage}
      />
    </>
  );
};

export default SurveyRoutes;

import * as React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { CaseType, TApiCaseType, TCasesState } from '../../types/Cases';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useInspection } from '../../hooks/useInspection';

interface ICaseTypeSelectProps {
  type: number | undefined;
  caseState: TCasesState;
  setType: (value: React.SetStateAction<number | undefined>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      marginTop: theme.typography.pxToRem(16),
      marginBottom: theme.typography.pxToRem(16),
    },
  })
);

const CaseTypeSelect: React.FC<ICaseTypeSelectProps> = ({
  type,
  caseState,
  setType,
}): JSX.Element => {
  const classes = useStyles();
  const { isInspectionStored } = useInspection();
  const { t } = useTranslation();
  return (
    <>
      {caseState.data && caseState.data?.length >= 1 && (
        <FormControl variant='outlined' className={classes.formControl}>
          <InputLabel id='case-type-label'>
            {t('case.type.select.label')}
          </InputLabel>
          <Select
            labelId='case-type-label'
            id='case-type-select'
            value={type || caseState.data[0].type}
            onChange={(e) => setType(e.target.value as number)}
            label={t('case.type.select.label')}
          >
            {caseState?.data?.map((type: TApiCaseType, index: number) => {
              if (isInspectionStored && type.type === CaseType.WHISTLEBLOWER)
                return null;

              return (
                <MenuItem value={type.type} key={index}>
                  {type.title}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      )}
    </>
  );
};

export default React.memo(CaseTypeSelect);

import * as React from 'react';
import PasswordResetForm from '../../../components/forms/auth/PasswordResetForm';
import { FormEvent, useEffect, useState } from 'react';
import { useApp } from '../../../hooks/useApp';
import { ChangeForgotPassword } from '../../../types/Auth';
import { useDispatch } from 'react-redux';
import {
  changeForgotPassword,
  checkPasswordToken,
} from '../../../actions/AuthActions';
import { RouteComponentProps, withRouter } from 'react-router';
import { TDispatch } from '../../../types/Thunk';
import AlertBlock from '../../../components/AlertBlock';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { routes } from '../../../Routes';
import Button from '@material-ui/core/Button';

const PasswordResetPage: React.FC<RouteComponentProps<any>> = (
  props
): JSX.Element => {
  const { app } = useApp();
  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch<TDispatch>();
  const query = new URLSearchParams(props.location.search);
  const token = query.get('token');
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(checkPasswordToken(token as string));
    //eslint-disable-next-line
  }, [token]);

  const submitForm = (e: FormEvent) => {
    e.preventDefault();
    dispatch(
      changeForgotPassword(token as string, password, passwordRepeat)
    ).then(() => {
      setSuccess(true);
    });
  };

  return (
    <>
      {success ? (
        <>
          <AlertBlock type={'success'} onClose={() => setSuccess(false)}>
            {t('auth.password.changed')}
          </AlertBlock>
          <Grid container>
            <Grid item xs>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='secondary'
                component={Link}
                to={routes.login}
              >
                {t('password.changed.proceed.to.login')}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <PasswordResetForm
          password={password}
          setPassword={setPassword}
          passwordRepeat={passwordRepeat}
          setPasswordRepeat={setPasswordRepeat}
          submitForm={submitForm}
          errors={app.getError(ChangeForgotPassword)}
        />
      )}
    </>
  );
};

export default withRouter(PasswordResetPage);

import * as React from 'react';
import {
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { reverse } from 'named-urls';
import { routes } from '../../Routes';
import { simpleDateFormat } from '../../Helpers';
import InfoIcon from '@material-ui/icons/Info';
import { TApiChemicalProductEntity } from '../../types/Chemical';
import { useChemicalListStyles } from './ChemicalList.styles';
import Image from '../cms/Image';
import { useMediaQuery, useTheme } from '@material-ui/core';

interface IChemicalListProps {
  chemicals?: Array<TApiChemicalProductEntity>;
}

const ChemicalList: React.FC<IChemicalListProps> = ({
  chemicals,
}): JSX.Element => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useChemicalListStyles();

  return (
    <GridList cellHeight={180} cols={isXs ? 2 : 4} className={classes.gridList}>
      {chemicals?.map((chemical) => {
        return (
          <GridListTile key={chemical.id}>
            <Link
              to={reverse(routes.chemicalProduct, {
                productId: chemical.id,
              })}
            >
              {chemical.image ? (
                <Image
                  image={{
                    url: chemical.image as string,
                    name: chemical.name as string,
                  }}
                />
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <i
                    className='fas fa-flask'
                    style={{ fontSize: '150px', color: 'lightgray' }}
                  />
                </div>
              )}

              <GridListTileBar
                title={chemical.name}
                subtitle={simpleDateFormat(chemical.revisedDate)}
                actionIcon={
                  <IconButton
                    aria-label={`info about ${chemical.name}`}
                    className={classes.icon}
                  >
                    <InfoIcon />
                  </IconButton>
                }
              />
            </Link>
          </GridListTile>
        );
      })}
    </GridList>
  );
};

export default ChemicalList;

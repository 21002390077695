import { AxiosError, AxiosResponse} from 'axios';
import { TApiChemicalProductEntity } from './Chemical';

export type TApiProcessLocation = {
  title: string;
};

export type TApiProcessDepartment = {
  title: string;
};

export interface TApiProcessCategory {
  id: number;
  nameDa: string;
  nameEn:string;
  orderNr: number;
  description: string;
  parentId: number;
}

export enum ProcessFileType {
  NONE = 0,
  DESCRIPTION = 1,
  PLANNING = 2,
  EXECUTION = 3,
}

export type TApiProcessFile = {
  id: number;
  media: string;
  published: boolean;
  title?: string;
  notes?: string;
  type: ProcessFileType
}

export type TApiProcessEntity = {
  id: number;
  name: string;
  description?: string;
  planning?: string;
  execution?: string;
  image: string;
  chemicals: Array<TApiChemicalProductEntity>;
  updateDate: Date;
  locations: Array<TApiProcessLocation>;
  departments: Array<TApiProcessDepartment>
  category: TApiProcessCategory;
  processFiles: Array<TApiProcessFile>;
};

export interface TProcessState {
  readonly loading?: boolean;
  readonly data?: Array<TApiProcessEntity>;
  readonly activeProcess?: TApiProcessEntity;
  readonly filteredList?: Array<TApiProcessEntity>;
  readonly categories?: Array<TApiProcessCategory>;
  readonly searchPhrase?: string;
  readonly searchCategory?: string;
}

export const initialProcessState: TProcessState = {};

export enum ProcessCategories {
  REQUEST = 'PROCESS_CATEGORIES_REQUEST',
  SUCCESS = 'PROCESS_CATEGORIES_SUCCESS',
  FAILED = 'PROCESS_CATEGORIES_FAILED'
}

export type TGetProcessCategories =
  | {
      type: ProcessCategories.REQUEST
    }
  | {
      type: ProcessCategories.SUCCESS;
      payload: AxiosResponse<{ categories: Array<TApiProcessCategory>}>;
    }
  | {
      type: ProcessCategories.FAILED;
      payload: AxiosError;
    };

export enum ProcessList {
  REQUEST = 'PROCESS_LIST_REQUEST',
  SUCCESS = 'PROCESS_LIST_SUCCESS',
  FAILED = 'PROCESS_LIST_FAILED'
}

export type TGetProcessList =
  | {
    type: ProcessList.REQUEST
  }
  | {
    type: ProcessList.SUCCESS;
    payload: AxiosResponse<{ processes: Array<TApiProcessEntity>}>;
  }
  | {
    type: ProcessList.FAILED;
    payload: AxiosError;
  };

export enum Process {
  REQUEST = 'PROCESS_REQUEST',
  SUCCESS = 'PROCESS_SUCCESS',
  FAILED = 'PROCESS_FAILED'
}

export type TGetProcess =
  | {
    type: Process.REQUEST
  }
  | {
    type: Process.SUCCESS;
    payload: AxiosResponse<{ process: TApiProcessEntity}>;
  }
  | {
    type: Process.FAILED;
    payload: AxiosError;
  };

export enum FilterProcessList {
  SUBMIT = 'FILTER_PROCESS_LIST',
}

export type TFilterProcessListActionPayload = {
  search: string;
}

export type TFilterProcessListAction = {
  type: FilterProcessList.SUBMIT;
  payload: TFilterProcessListActionPayload;
};

export type TProcessActions =
  | TGetProcessCategories
  | TGetProcessList
  | TGetProcess
  | TFilterProcessListAction;

import * as React from 'react';
import { routes } from '../../Routes';
import LoginPage from '../../containers/pages/auth/LoginPage';
import PasswordRemindPage from '../../containers/pages/auth/PasswordRemindPage';
import { DynamicRoute } from './DynamicRoute';
import PasswordResetPage from '../../containers/pages/auth/PasswordResetPage';

const UnauthorizedRoutes: React.FC = (): JSX.Element => {
  return (
    <>
      <DynamicRoute
        key={'login'}
        exact
        path={routes.login}
        component={LoginPage}
      />
      <DynamicRoute
        key={'remind'}
        exact
        path={routes.remind}
        component={PasswordRemindPage}
      />
      <DynamicRoute
        key={'reset'}
        exact
        path={routes.reset}
        component={PasswordResetPage}
      />
    </>
  );
};

export default UnauthorizedRoutes;

import * as React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { FormEvent } from "react";
import { TApiQuestionEntity } from "../../types/Survey";
import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SurveyTranslation from "../survey/SurveyTranslation";
import { interpolate } from "../../Helpers";

interface IAnswerCommentModalProps {
  showCommentBlock: boolean;
  setShowCommentBlock: (value: React.SetStateAction<boolean>) => void;
  submitAnswerCommentForm: (e: FormEvent, answerId: number) => void;
  question: TApiQuestionEntity;
  answerCommentHeader: string | null;
  answerCommentBody: string | null;
  answerCommentLabel: string | null;
  answerCommentSaveButton: string | null;
  comment: string;
  setComment: (value: React.SetStateAction<string>) => void;
  selectedAnswer: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const AnswerCommentModal: React.FC<IAnswerCommentModalProps> = ({
  showCommentBlock,
  setShowCommentBlock,
  submitAnswerCommentForm,
  question,
  answerCommentHeader,
  answerCommentBody,
  answerCommentLabel,
  answerCommentSaveButton,
  comment,
  setComment,
  selectedAnswer,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Dialog
      open={showCommentBlock}
      onClose={() => setShowCommentBlock(false)}
      aria-labelledby="answer-comment-title"
      aria-describedby="answer-comment-description"
    >
      <form
        noValidate
        autoComplete="off"
        onSubmit={(e) => submitAnswerCommentForm(e, selectedAnswer.id)}
      >
        <DialogTitle disableTypography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => setShowCommentBlock(false)}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <h2 style={{ textAlign: "center" }}>
            {answerCommentHeader !== null ? (
              <>{answerCommentHeader}</>
            ) : (
              <SurveyTranslation translate={"survey.question.comment.header"} />
            )}
          </h2>
          {(() => {
            const variables = {
              question: question ? `${question.body}` : "",
              answer: selectedAnswer ? `${selectedAnswer.title}` : "",
            };
            return (
              <p style={{ textAlign: "center" }}>
                {answerCommentBody !== null ? (
                  <>{interpolate(answerCommentBody, variables)}</>
                ) : (
                  <SurveyTranslation
                    translate={"survey.question.comment.intro"}
                    options={{
                      ...variables,
                      interpolation: {
                        escapeValue: false,
                      },
                    }}
                  />
                )}
              </p>
            );
          })()}
          <TextField
            id="outlined-basic"
            label={
              answerCommentLabel !== null ? (
                <>{answerCommentLabel}</>
              ) : (
                <SurveyTranslation translate={"survey.answer.comment.label"} />
              )
            }
            variant="outlined"
            value={comment}
            multiline
            rows={3}
            onChange={(e) => setComment(e.target.value)}
            style={{ width: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" autoFocus>
            {answerCommentSaveButton !== null ? (
              <>{answerCommentSaveButton}</>
            ) : (
              <SurveyTranslation translate={"survey.submit.answer.comment"} />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AnswerCommentModal;

import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TProcessState } from '../../../types/Process';
import { IAppState } from '../../../store/Store';
import { useEffect, useState } from 'react';
import { getProcess } from '../../../actions/ProcessActions';
import { setCurrentPageTitle } from '../../../actions/ContentActions';
import Process from '../../../components/processes/Process';

const ProcessPage: React.FC<
  RouteComponentProps<{
    id?: string;
  }>
> = ({
  match: {
    params: { id },
  },
}): JSX.Element => {
  const dispatch = useDispatch();
  const processState: TProcessState = useSelector<IAppState, TProcessState>(
    (state) => state.processState
  );

  useEffect(() => {
    dispatch(getProcess(parseInt(id as string)));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      setCurrentPageTitle(processState.activeProcess?.name as string)
    )
  }, [processState, dispatch])

  const [expandedDescription, setExpandedDescription] = useState(
    !!localStorage.getItem('expandedDescription')
  );

  const [expandedPlanning, setExpandedPlanning] = useState(
    !!localStorage.getItem('expandedPlanning')
  );

  const [expandedExecution, setExpandedExecution] = useState(
    !!localStorage.getItem('expandedExecution')
  );

  const [expandedChemical, setExpandedChemical] = useState(
    !!localStorage.getItem('expandedChemical')
  );

  const [expandedRevised, setExpandedRevised] = useState(
    !!localStorage.getItem('expandedRevised')
  );

  const updateLocalStorage = (title: string, isExpanded: boolean) => {
    if (isExpanded) {
      localStorage.setItem(title, String(isExpanded));
    } else {
      localStorage.removeItem(title);
    }
  }

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      switch (panel) {
        case 'expandedDescription':
          setExpandedDescription(isExpanded);
          updateLocalStorage('expandedDescription', isExpanded);
          break;
        case 'expandedPlanning':
          setExpandedPlanning(isExpanded);
          updateLocalStorage('expandedPlanning', isExpanded);
          break;
        case 'expandedExecution':
          setExpandedExecution(isExpanded);
          updateLocalStorage('expandedExecution', isExpanded);
          break;
        case 'expandedChemical':
          setExpandedChemical(isExpanded);
          updateLocalStorage('expandedChemical', isExpanded);
          break;
        case 'expandedRevised':
          setExpandedRevised(isExpanded);
          updateLocalStorage('expandedRevised', isExpanded);
          break;
      }
    };

  return (
    <>
      {processState.activeProcess && (
        <Process
          process={processState}
          expandedDescription={expandedDescription}
          expandedPlanning={expandedPlanning}
          expandedExecution={expandedExecution}
          expandedChemical={expandedChemical}
          expandedRevised={expandedRevised}
          handleChange={handleChange}
          />
        )}
    </>
  );
}

export default withRouter(ProcessPage);
import AlertBlock from "../../../../components/AlertBlock";
import PageContent from "../../../../components/PageContent";
import { ContentPage } from "../../../../types/Content";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ReCAPTCHA from "react-google-recaptcha";
import Button from "@material-ui/core/Button";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IWhistleblowerRequest } from "../hooks/useWhistleblowerSearch";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { IWhistleblowerApiEntity } from "../types/Whistleblower";
import { AxiosResponse } from "axios";
import { UseMutateFunction } from "@tanstack/react-query";
import { usePageContentQuery } from "../../../../shared/hooks/usePageContentQuery";
import Loader from "../../../../components/Loader";
import Paragraph from "../../../../components/cms/Paragraph";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    submit: {
      margin: theme.spacing(3, 0, 3)
    }
  })
);

interface Props {
  onSearch: (data: IWhistleblowerApiEntity, code: string) => void;
  searchWhistleblower: UseMutateFunction<
    AxiosResponse<IWhistleblowerApiEntity>,
    unknown,
    IWhistleblowerRequest,
    unknown
  >;
}

export const WhistleblowerSearchForm: React.FC<Props> = ({
  onSearch,
  searchWhistleblower
}) => {
  const { data, isLoading } = usePageContentQuery(ContentPage.WHISTLEBLOWER);
  const { t } = useTranslation();
  const [code, setCode] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const classes = useStyles();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchWhistleblower(
      { code, recaptcha },
      {
        onSuccess: data => {
          onSearch(data.data, code);
        }
      }
    );
  };

  if (isLoading) return <Loader visible={true} />;

  return (
    <form onSubmit={handleFormSubmit}>
      <AlertBlock type={"info"} hideCloseButton={true}>
        <PageContent pageType={ContentPage.WHISTLEBLOWER} hideContent={true} />
        {data ? <Paragraph body={data?.articles[3].bodyText} /> : null}
      </AlertBlock>

      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        name="code"
        label={` ${t("whistleblower.search_label")} *`}
        type="text"
        id="code"
        value={code}
        onChange={e => setCode(e.target.value)}
      />

      <Grid container>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as any}
            onChange={value => setRecaptcha(value as string)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} justifyContent="space-around">
        <Grid item xs={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {t("whistleblower.search.submit")}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

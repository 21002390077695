import * as React from 'react';
import { useRef, useState } from 'react';

interface IImageUploaderProps {
  handlePhotoUpload: (fileName: string, base64Content: string) => void;
  accept: string;
}

interface IUploadFile {
  filename: string;
  base64Content: string;
}

const ImageUploader: React.FC<IImageUploaderProps> = ({
  handlePhotoUpload,
  accept,
  children,
}): JSX.Element => {
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState('');

  const getImageData = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () =>
        resolve({
          filename: file.name,
          base64Content: reader.result,
        } as IUploadFile);
      reader.onerror = () => reject(reader.result);
      reader.readAsDataURL(file);
    });

  const convertFilesToImageData = async (files: Array<File>) => {
    return await Promise.all(
      files.map(async (file) => await getImageData(file))
    );
  };

  const handleFileInputChange = (selectorFiles: FileList) => {
    convertFilesToImageData(Array.from(selectorFiles)).then((result: any) => {
      result.forEach((photo: IUploadFile) => {
        handlePhotoUpload(photo.filename, photo.base64Content);
      });
    });
    setValue('');
  };

  const handleUploadIconClick = (e: any) => {
    e.preventDefault();
    uploadInputRef.current?.click();
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          border: '1px solid #e8e8e8',
          padding: '8px 0',
        }}
        onClick={handleUploadIconClick}
      >
        {children}
      </div>
      <input
        ref={uploadInputRef}
        onChange={(event) =>
          handleFileInputChange(event.target.files as FileList)
        }
        type='file'
        accept={accept}
        style={{ display: 'none' }}
        value={value}
        multiple
        tabIndex={-1}
      />
    </>
  );
};

export default ImageUploader;

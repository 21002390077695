import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { FormEvent, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AlertBlock from '../../AlertBlock';
import { Link } from 'react-router-dom';
import { routes } from '../../../Routes';
import ReCAPTCHA from 'react-google-recaptcha';

interface ILoginFormProps {
  username: string;
  setUsername: (value: React.SetStateAction<string>) => void;
  password: string;
  setPassword: (value: React.SetStateAction<string>) => void;
  recaptcha: string;
  setRecaptcha: (value: React.SetStateAction<string>) => void;
  submitForm: (e: FormEvent) => void;
  errors: {
    message: string;
    recaptcha?: boolean;
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 3),
  },
  forgotLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: '20px',
  },
}));

const LoginForm: React.FC<ILoginFormProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(props.errors?.recaptcha ?? false);

  const handleRecaptchaChange = (value: string | null) => {
    props.setRecaptcha(value as string);
    if (value) {
      setIsDisabled(false);
    }
  };

  return (
    <>
      {props.errors && (
        <AlertBlock type={'error'}>{props.errors.message}</AlertBlock>
      )}
      <form className={classes.form} noValidate onSubmit={props.submitForm}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='email'
          label={t('auth.email.label')}
          name='email'
          autoFocus
          value={props.username}
          type={'email'}
          helperText={t('auth.email.helperText')}
          onChange={(e) => props.setUsername(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          name='password'
          label={t('auth.password.label')}
          type='password'
          id='password'
          autoComplete='current-password'
          value={props.password}
          helperText={t('auth.password.helperText')}
          onChange={(e) => props.setPassword(e.target.value)}
        />
        {props.errors?.recaptcha && (
          <Grid container>
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as any}
                onChange={handleRecaptchaChange}
              />
            </Grid>
          </Grid>
        )}
        <Grid container>
          <Grid item xs className={classes.forgotLink}>
            <Link to={routes.remind}>{t('auth.forgot.link')}</Link>
          </Grid>
          <Grid item xs>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={isDisabled}
            >
              {t('auth.login.submit')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default LoginForm;

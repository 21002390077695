import * as React from 'react';
import { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { TAuthState } from '../types/Auth';
import { useSelector } from 'react-redux';
import { IAppState } from '../store/Store';
import logo_da from '../assets/images/add_da.png';
import logo_en from '../assets/images/add_en.png';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';

const useStyles = makeStyles((theme: Theme) => ({
  popover: {
    position: 'fixed',
    left: '50%',
    bottom: '15px',
    transform: 'translateX(-50%)',
    width: '90%',
    border: '1px solid #ddd',
  },
  cardArea: {
    '&:hover': {
      opacity: '1',
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    border: '0',
    borderColor: 'transparent !important',
  },
}));

const AddToHomeScreen: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [show, setShow] = useState(isMobile && (isIOS || isAndroid));
  const authState: TAuthState = useSelector<IAppState, TAuthState>(
    (state) => state.authState
  );
  const { i18n } = useTranslation();

  const handlePopupClose = () => {
    setShow(false);
  };

  return (
    <>
      {show && (
        <Card className={classes.popover}>
          <CardHeader
            title={t('add-to-home-screen.title')}
            action={
              <IconButton
                aria-label='close'
                className={classes.closeButton}
                onClick={() => handlePopupClose()}
              >
                <CloseIcon />
              </IconButton>
            }
          />
          <CardContent>
            {isAndroid && (
              <p>
                {t('add-to-home-screen.content.android', {
                  company: authState.data?.solution.company,
                  interpolation: {
                    escapeValue: false,
                  },
                })}
              </p>
            )}
            {isIOS && (
              <>
                <p>
                  {t('add-to-home-screen.content.ios', {
                    company: authState.data?.solution.company,
                    interpolation: {
                      escapeValue: false,
                    },
                  })}
                </p>
                {i18n.language === 'en' ? (
                  <img src={logo_en} alt='' />
                ) : (
                  <img src={logo_da} alt='' />
                )}
              </>
            )}
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default AddToHomeScreen;

import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PasswordRemindForm from '../../../components/forms/auth/PasswordRemindForm';
import { FormEvent, useState } from 'react';
import { useApp } from '../../../hooks/useApp';
import { ResetPassword } from '../../../types/Auth';
import Typography from '@material-ui/core/Typography';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../actions/AuthActions';
import { TDispatch } from '../../../types/Thunk';
import AlertBlock from '../../../components/AlertBlock';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const PasswordRemindPage: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const { app } = useApp();
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch<TDispatch>();

  const submitPasswordRemindForm = (e: FormEvent) => {
    e.preventDefault();
    dispatch(resetPassword(email)).then(() => {
      setSuccess(true);
    });
  };

  return (
    <>
      {success && (
        <AlertBlock type={'success'} onClose={() => setSuccess(false)}>
          {t('auth.reset.password.success.message')}
        </AlertBlock>
      )}
      <Typography>{t('remind.page.intro')}</Typography>
      <div className={classes.paper}>
        <PasswordRemindForm
          errors={app.getError(ResetPassword)}
          email={email}
          setEmail={setEmail}
          submitForm={submitPasswordRemindForm}
        />
      </div>
    </>
  );
};

export default PasswordRemindPage;

import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store/Store';
import { RouteComponentProps } from 'react-router';
import { setCurrentPageTitle } from '../../../actions/ContentActions';
import { withRouter } from 'react-router-dom';
import { getMeeting } from '../../../actions/MeetingActions';
import { TMeetingState } from '../../../types/Meeting';
import Meeting from '../../../components/meetings/Meeting';

const MeetingPage: React.FC<
  RouteComponentProps<{
    id?: string;
  }>
> = ({
  match: {
    params: { id },
  },
}): JSX.Element => {
  const dispatch = useDispatch();
  const meetingState: TMeetingState = useSelector<IAppState, TMeetingState>(
    (state) => state.meetingState
  );

  useEffect(() => {
    dispatch(getMeeting(parseInt(id as string)));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(
      setCurrentPageTitle(meetingState.activeMeeting?.meeting.name as string)
    );
  }, [meetingState, dispatch]);

  return (
    <>
      {meetingState.activeMeeting && (
        <Meeting meeting={meetingState.activeMeeting} />
      )}
    </>
  );
};

export default withRouter(MeetingPage);

import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import apv_logo from '../assets/images/apvlogo.svg';
import Container from '@material-ui/core/Container';
import { RouteComponentProps, withRouter } from 'react-router';

interface ILogoProps extends RouteComponentProps<any> {
  visible: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(4, 1, 2),
  },
  logoImage: {
    maxWidth: '45%',
  },
}));

const Logo: React.FC<ILogoProps> = (props): JSX.Element => {
  const classes = useStyles();
  const solutionLogo = `${window.location.origin}/logo`;

  return (
    <>
      {(props.visible || props.location.pathname.includes('/auth')) && (
        <Container component='header' maxWidth='xs'>
          <div className={classes.logo}>
            {solutionLogo ? (
              <img
                className={classes.logoImage}
                src={solutionLogo}
                alt='logo'
              />
            ) : (
              <img className={classes.logoImage} src={apv_logo} alt='logo' />
            )}
          </div>
        </Container>
      )}
    </>
  );
};

export default withRouter(Logo);

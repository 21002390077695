import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import './wdyr';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import configureStore from './store/Store';
import { Provider } from 'react-redux';
import App from './App';
import { ThemeProvider } from '@material-ui/core/styles';
import { OSHURETheme } from './config/OSHURETheme';
import * as Sentry from '@sentry/browser';
import i18n from './config/i18next';
import { I18nextProvider } from 'react-i18next';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_RELEASE_ID,
  ignoreErrors: [
    // Email link Microsoft Outlook crawler compatibility error
    // cf. https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    "Non-Error promise rejection captured with value: Object Not Found Matching Id:",
  ],
  beforeSend: (event, hint) => {
    const ex = hint?.originalException;
    if (ex) {
      const status = (ex as any)?.response?.status;
      if (status === 401 || status === 404) {
        return null;
      }
    }
    return event;
  }
});

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={OSHURETheme}>
        <App />
      </ThemeProvider>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: async (registration) => {
    if (registration && registration.waiting) {
      await registration.unregister();
      if (registration.waiting) {
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      window.location.reload();
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Reducer } from 'redux';
import { endReducer } from '../store/Store';
import {
  FilterMeetingsList,
  GetMeeting,
  GetMeetingsList,
  initialMeetingState,
  TMeetingActions,
  TMeetingState,
} from '../types/Meeting';

export const meetingReducer: Reducer<TMeetingState, TMeetingActions> = (
  state = initialMeetingState,
  action
) => {
  switch (action.type) {
    case GetMeetingsList.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetMeetingsList.SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload.data.meetings,
        filteredList: action.payload.data.meetings,
      };

    case GetMeetingsList.FAILED:
      return {
        ...state,
        loading: false,
      };

    case FilterMeetingsList.SUBMIT:
      const searchOrganisation =
        action.payload.organisation.toLocaleLowerCase();

      return {
        ...state,
        filteredList: state.list?.filter((val) => {
          if (searchOrganisation === '') return true;
          return val.organisations
            .map((organisation) => organisation.toLocaleLowerCase())
            .includes(searchOrganisation);
        }),
        searchOrganisation,
      };

    case GetMeeting.REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GetMeeting.FAILED:
      return {
        ...state,
        loading: false,
      };
    case GetMeeting.SUCCESS:
      return {
        ...state,
        loading: false,
        activeMeeting: action.payload.data,
      };

    default:
      return endReducer(state, action);
  }
};

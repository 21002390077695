import { fetchWithAuthentication } from '../actions/BaseAction';

const fetchAndReplaceImages = async (container: HTMLElement) => {
  const imgElements = container.querySelectorAll('img');
  imgElements.forEach((img: HTMLImageElement) => {
    if (img.hasAttribute('data-original-src')) {
      const url = img.getAttribute('data-original-src');
      if (url !== null) {
        fetchWithAuthentication(url)
          .then(blob => {
            if (blob !== null) {
              img.src = URL.createObjectURL(blob);
              img.removeAttribute('data-original-src');
            }
          })
          .catch(error => {throw error});
      }
    }
  });
};

export default fetchAndReplaceImages;
import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ContentPage } from '../../../types/Content';
import PageContent from '../../../components/PageContent';
import { IAppState } from '../../../store/Store';
import { TSurveysState } from '../../../types/Survey';
import SurveyList from '../../../components/survey/SurveyList';
import { RouteComponentProps } from 'react-router';
import { reverse } from 'named-urls';
import { routes } from '../../../Routes';
import { getInspectionList } from '../../../actions/InspectionActions';

const InspectionListPage: React.FC<RouteComponentProps> = (
  props
): JSX.Element => {
  const dispatch = useDispatch();
  const surveyState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state) => state.surveysState
  );

  useEffect(() => {
    dispatch(getInspectionList());
  }, [dispatch]);

  const onSelect = (id: number) => {
    props.history.push(reverse(routes.surveyStart, { id }));
  };

  return (
    <>
      <PageContent
        pageType={ContentPage.INSPECTIONS_LIST}
        showAsParagraph={true}
      />
      {surveyState.data && (
        <SurveyList surveys={surveyState.data} onSelect={onSelect} />
      )}
    </>
  );
};

export default withRouter(InspectionListPage);

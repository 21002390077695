import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AlertBlock from '../../AlertBlock';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormEvent } from 'react';
import Grid from '@material-ui/core/Grid';

interface IChangePasswordFormProps {
  currentPassword: string;
  setCurrentPassword: (value: React.SetStateAction<string>) => void;
  newPassword: string;
  setNewPassword: (value: React.SetStateAction<string>) => void;
  confirmNewPassword: string;
  setConfirmNewPassword: (value: React.SetStateAction<string>) => void;
  submitForm: (e: FormEvent) => void;
  errors: any | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const ChangePasswordForm: React.FC<IChangePasswordFormProps> = (
  props
): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      {props.errors && (
        <AlertBlock type={'error'}>{props.errors.message}</AlertBlock>
      )}
      <form className={classes.form} noValidate onSubmit={props.submitForm}>
        <TextField
          error={!!props.errors?.fields?.currentPassword}
          helperText={props.errors?.fields?.currentPassword}
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='currentPassword'
          label={t('profile.current.password')}
          name='currentPassword'
          autoFocus
          value={props.currentPassword}
          type={'password'}
          onChange={(e) => props.setCurrentPassword(e.target.value)}
        />
        <TextField
          error={!!props.errors?.fields?.newPassword}
          helperText={props.errors?.fields?.newPassword}
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='newPassword'
          label={t('profile.new.password')}
          name='newPassword'
          value={props.newPassword}
          type={'password'}
          onChange={(e) => props.setNewPassword(e.target.value)}
        />
        <TextField
          error={!!props.errors?.fields?.confirmNewPassword}
          helperText={props.errors?.fields?.confirmNewPassword}
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='confirmNewPassword'
          label={t('profile.confirm.new.password')}
          name='confirmNewPassword'
          value={props.confirmNewPassword}
          type={'password'}
          onChange={(e) => props.setConfirmNewPassword(e.target.value)}
        />
        <Grid container>
          <Grid item xs={12}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              {t('submit.button.save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default ChangePasswordForm;

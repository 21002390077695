import { AxiosError, AxiosResponse } from 'axios';
import { TApiUserEntity, TApiUserPermissions } from './User';

export interface TApiAuthEntity {
  apiToken: string;
  user: TApiUserEntity | null;
  permissions: TApiUserPermissions | null;
  solution: {
    company: string;
  };
}

interface IAppActiveModules {
  surveys: boolean;
  actionplans: boolean;
  cases: boolean;
}

interface ILoginConfig {
  localAllowed: boolean;
  googleAllowed: boolean;
  microsoftAllowed: boolean;
}

export interface IAppSettingEntity {
  languageCode: string;
  activeModules: IAppActiveModules;
  loginConfig: ILoginConfig;
}

export interface TAuthState {
  readonly data?: TApiAuthEntity;
  readonly settings?: IAppSettingEntity;
  readonly loading?: boolean;
}

export const initialAuthState: TAuthState = {
  data: undefined,
};

/*
    Get App Settings
 */
export enum GetAppSettings {
  REQUEST = 'GET_APP_SETTINGS_REQUEST',
  SUCCESS = 'GET_APP_SETTINGS_SUCCESS',
  FAILED = 'GET_APP_SETTINGS_FAILED',
}

export type TGetAppSettingsAction =
  | {
      type: GetAppSettings.REQUEST;
    }
  | {
      type: GetAppSettings.SUCCESS;
      payload: AxiosResponse<IAppSettingEntity>;
    }
  | {
      type: GetAppSettings.FAILED;
      payload: AxiosResponse<any>;
    };

/*
    Login
 */
export enum UserLogin {
  REQUEST = 'USER_LOGIN_REQUEST',
  SUCCESS = 'USER_LOGIN_SUCCESS',
  FAILED = 'USER_LOGIN_FAILED',
}

export type TUserLoginAction =
  | {
      type: UserLogin.REQUEST;
    }
  | {
      type: UserLogin.SUCCESS;
      payload: AxiosResponse<TApiAuthEntity>;
    }
  | {
      type: UserLogin.FAILED;
      payload: AxiosResponse<any>;
    };
/*
    Unified login
 */
export enum OAuthServiceInit {
  REQUEST = 'OAUTH_SERVICE_INIT_REQUEST',
  SUCCESS = 'OAUTH_SERVICE_INIT_SUCCESS',
  FAILED = 'OAUTH_SERVICE_INIT_FAILED',
}

export type TOAuthServiceInitAction =
  | {
      type: OAuthServiceInit.REQUEST;
    }
  | {
      type: OAuthServiceInit.SUCCESS;
      payload: AxiosResponse<{ gotoUrl: string }>;
    }
  | {
      type: OAuthServiceInit.FAILED;
      payload: AxiosResponse<any>;
    };

/*
    Anonymous Login
 */
export enum AnonymousUserLogin {
  REQUEST = 'ANONYMOUS_USER_LOGIN_REQUEST',
  SUCCESS = 'ANONYMOUS_USER_LOGIN_SUCCESS',
  FAILED = 'ANONYMOUS_USER_LOGIN_FAILED',
}

export type TAnonymousUserLoginAction =
  | {
      type: AnonymousUserLogin.REQUEST;
    }
  | {
      type: AnonymousUserLogin.SUCCESS;
      payload: AxiosResponse<TApiAuthEntity>;
    }
  | {
      type: AnonymousUserLogin.FAILED;
      payload: AxiosError;
    };

/*
    Logout
 */
export enum UserLogout {
  REQUEST = 'USER_LOGOUT_REQUEST',
  SUCCESS = 'USER_LOGOUT_SUCCESS',
  FAILED = 'USER_LOGOUT_FAILED',
}

export type TUserLogoutAction =
  | {
      type: UserLogout.REQUEST;
    }
  | {
      type: UserLogout.SUCCESS;
    }
  | {
      type: UserLogout.FAILED;
    };

/*
    Password remind
 */
export enum UserPasswordRemind {
  REQUEST = 'USER_PASSWORD_REMIND_REQUEST',
  SUCCESS = 'USER_PASSWORD_REMIND_SUCCESS',
  FAILED = 'USER_PASSWORD_REMIND_FAILED',
}

export type TUserPasswordRemindAction =
  | {
      type: UserPasswordRemind.REQUEST;
    }
  | {
      type: UserPasswordRemind.SUCCESS;
      payload: AxiosResponse<TApiAuthEntity>;
    }
  | {
      type: UserPasswordRemind.FAILED;
      payload: AxiosError;
    };

/*
    Check token
 */
export enum UserCheckToken {
  REQUEST = 'USER_CHECK_TOKEN_REQUEST',
  SUCCESS = 'USER_CHECK_TOKEN_SUCCESS',
  FAILED = 'USER_CHECK_TOKEN_FAILED',
}

export type TUserCheckTokenAction =
  | {
      type: UserCheckToken.REQUEST;
    }
  | {
      type: UserCheckToken.SUCCESS;
      payload: AxiosResponse<TApiAuthEntity>;
    }
  | {
      type: UserCheckToken.FAILED;
      payload: AxiosError;
    };

/*
    Profile edit
 */
export enum ProfileEdit {
  REQUEST = 'PROFILE_EDIT_REQUEST',
  SUCCESS = 'PROFILE_EDIT_SUCCESS',
  FAILED = 'PROFILE_EDIT_FAILED',
}

export type TProfileEditAction =
  | {
      type: ProfileEdit.REQUEST;
    }
  | {
      type: ProfileEdit.SUCCESS;
      payload: AxiosResponse<TApiAuthEntity>;
    }
  | {
      type: ProfileEdit.FAILED;
      payload: AxiosError;
    };
/*
    Change Password
 */
export enum ChangePassword {
  REQUEST = 'CHANGE_PASSWORD_REQUEST',
  SUCCESS = 'CHANGE_PASSWORD_SUCCESS',
  FAILED = 'CHANGE_PASSWORD_FAILED',
}

export type TChangePasswordAction =
  | {
      type: ChangePassword.REQUEST;
    }
  | {
      type: ChangePassword.SUCCESS;
      payload: AxiosResponse<TApiAuthEntity>;
    }
  | {
      type: ChangePassword.FAILED;
      payload: AxiosError;
    };
/*
    Reset Password
 */
export enum ResetPassword {
  REQUEST = 'RESET_PASSWORD_REQUEST',
  SUCCESS = 'RESET_PASSWORD_SUCCESS',
  FAILED = 'RESET_PASSWORD_FAILED',
}

export type TResetPasswordAction =
  | {
      type: ResetPassword.REQUEST;
    }
  | {
      type: ResetPassword.SUCCESS;
      payload: AxiosResponse<{}>;
    }
  | {
      type: ResetPassword.FAILED;
      payload: AxiosError;
    };
/*
    Change Password
 */
export enum ChangeForgotPassword {
  REQUEST = 'CHANGE_FORGOT_PASSWORD_REQUEST',
  SUCCESS = 'CHANGE_FORGOT_PASSWORD_SUCCESS',
  FAILED = 'CHANGE_FORGOT_PASSWORD_FAILED',
}

export type TChangeForgotPasswordAction =
  | {
      type: ChangeForgotPassword.REQUEST;
    }
  | {
      type: ChangeForgotPassword.SUCCESS;
      payload: AxiosResponse<{}>;
    }
  | {
      type: ChangeForgotPassword.FAILED;
      payload: AxiosError;
    };
/*
    Check password reset token
 */
export enum CheckPasswordToken {
  REQUEST = 'CHECK_PASSWORD_TOKEN_REQUEST',
  SUCCESS = 'CHECK_PASSWORD_TOKEN_SUCCESS',
  FAILED = 'CHECK_PASSWORD_TOKEN_FAILED',
}

export type TCheckPasswordToken =
  | {
      type: CheckPasswordToken.REQUEST;
    }
  | {
      type: CheckPasswordToken.SUCCESS;
      payload: AxiosResponse<{}>;
    }
  | {
      type: CheckPasswordToken.FAILED;
      payload: AxiosError;
    };

export type TAuthActions =
  | TUserLoginAction
  | TUserPasswordRemindAction
  | TUserCheckTokenAction
  | TUserLogoutAction
  | TProfileEditAction
  | TChangePasswordAction
  | TAnonymousUserLoginAction
  | TGetAppSettingsAction
  | TOAuthServiceInitAction;

import * as React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { WhistleblowerSubmitForm } from "../forms/WhistleblowerSubmitForm";
import AlertBlock from "../../../../components/AlertBlock";
import { ContentPage } from "../../../../types/Content";
import { usePageContentQuery } from "../../../../shared/hooks/usePageContentQuery";
import Loader from "../../../../components/Loader";
import PageContent from "../../../../components/PageContent";
import Paragraph from "../../../../components/cms/Paragraph";
import Logo from '../../../../components/Logo';

const WhistleblowerPage: React.FC<RouteComponentProps> = (): JSX.Element => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const { data, isLoading } = usePageContentQuery(ContentPage.WHISTLEBLOWER);

  if (isLoading) {
    return <Loader visible={true} />;
  }

  return (
    <>
      <Logo visible={true}/>
      {!isSubmitted ? (
        <AlertBlock type={"info"} hideCloseButton={true}>
          <PageContent
            pageType={ContentPage.WHISTLEBLOWER}
            hideContent={true}
          />
          {data ? <Paragraph body={data?.articles[0].bodyText} /> : null}
        </AlertBlock>
      ) : null}

      <WhistleblowerSubmitForm onCreate={() => setIsSubmitted(true)} />
    </>
  );
};

export default withRouter(WhistleblowerPage);

import * as React from 'react';
import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import PageContent from '../../components/PageContent';
import { ContentPage } from '../../types/Content';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes } from '../../Routes';
import AddToHomeScreen from '../../components/AddToHomeScreen';
import { useApp } from '../../hooks/useApp';
import { useContentState } from '../../hooks/useСontentState';

const useStyles = makeStyles((theme: Theme) => ({
  caseButton: {
    position: 'fixed',
    bottom: '15px',
    right: '15px',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const HomePage: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const { app } = useApp();
  const { t } = useTranslation();
  const { isCasePagePublished } = useContentState();
  const [showCaseReport, setShowCaseReport] = useState(false);

  useEffect(() => {
    //Checking if report case page is published
    setShowCaseReport(isCasePagePublished);
  }, [isCasePagePublished]);

  return (
    <>
      <PageContent pageType={ContentPage.FRONTPAGE} showAsCard={true} />
      {showCaseReport && !app.isLoading() && (
        <Fab
          variant='extended'
          className={classes.caseButton}
          component={Link}
          to={routes.reportCase}
        >
          <AddIcon className={classes.extendedIcon} />
          {t('add.case.homepage.button')}
        </Fab>
      )}
      {!app.isLoading() &&
        !window.matchMedia('(display-mode: standalone)').matches && (
          <AddToHomeScreen />
        )}
    </>
  );
};

export default withRouter(HomePage);

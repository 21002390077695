import * as React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import AlertBlock from '../../AlertBlock';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormEvent } from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { routes } from '../../../Routes';
import { TAuthState } from '../../../types/Auth';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../store/Store';

interface IEditProfileFormProps {
  name: string;
  setName: (value: React.SetStateAction<string>) => void;
  email: string | undefined;
  setEmail: (value: React.SetStateAction<string>) => void;
  organization: string;
  location: string;
  submitForm: (e: FormEvent) => void;
  errors: any | undefined;
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const EditProfileForm: React.FC<IEditProfileFormProps> = (
  props
): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();
  const authState: TAuthState = useSelector<IAppState, TAuthState>(
    (state) => state.authState
  );

  return (
    <>
      {props.errors && (
        <AlertBlock type={'error'}>{props.errors.message}</AlertBlock>
      )}
      <form className={classes.form} noValidate onSubmit={props.submitForm}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='name'
          label={t('profile.name')}
          name='name'
          autoFocus
          disabled={!authState.data?.permissions?.canEditProfile}
          value={props.name}
          type={'text'}
          onChange={(e) => props.setName(e.target.value)}
        />
        <TextField
          error={!!props.errors?.fields?.email}
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='email'
          label={t('profile.email')}
          disabled
          name='email'
          value={props.email}
          helperText={props.errors?.fields?.email}
          type={'email'}
          onChange={(e) => props.setEmail(e.target.value)}
        />
        {props.organization && (
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='organization'
            label={t('profile.organization')}
            name='organization'
            value={props.organization}
            type={'text'}
            disabled
          />
        )}
        {props.location && (
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='location'
            label={t('profile.location')}
            name='location'
            autoFocus
            value={props.location}
            type={'text'}
            disabled
          />
        )}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              fullWidth
              color='primary'
              className={classes.submit}
              disabled={!authState.data?.permissions?.canEditProfile}
              component={Link}
              to={routes.changePassword}
            >
              {t('profile.change.password.button')}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={!authState.data?.permissions?.canEditProfile}
            >
              {t('submit.button.save')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default EditProfileForm;

import * as React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSurveyList, getUserData } from '../../../actions/SurveyActions';
import { withRouter } from 'react-router-dom';
import { ContentPage } from '../../../types/Content';
import PageContent from '../../../components/PageContent';
import { IAppState } from '../../../store/Store';
import { TSurveysState } from '../../../types/Survey';
import SurveyList from '../../../components/survey/SurveyList';
import { RouteComponentProps } from 'react-router';
import { reverse } from 'named-urls';
import { routes } from '../../../Routes';
import { useCurrentActiveSurvey } from '../../../hooks/useCurrentActiveSurvey';
import { TDispatch } from '../../../types/Thunk';

const SurveyListPage: React.FC<RouteComponentProps<any>> = (
  props
): JSX.Element => {
  const dispatch = useDispatch<TDispatch>();
  const {
    loadRespondentsStorage,
    setRespondentsStorage,
    getCompletedSurveysStorage,
    setCompletedSurveysStorage,
  } = useCurrentActiveSurvey();

  const surveyState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state) => state.surveysState
  );

  useEffect(() => {
    dispatch(
      getUserData(loadRespondentsStorage(), getCompletedSurveysStorage())
    ).then((res: any) => {
      setRespondentsStorage(res.data.incomplete);
      setCompletedSurveysStorage(res.data.completed);
      dispatch(getSurveyList());
    });
    //eslint-disable-next-line
  }, []);

  const onSurveySelect = (id: number) => {
    props.history.push(reverse(routes.surveyStart, { id }));
  };

  return (
    <>
      <PageContent pageType={ContentPage.SURVEYS_LIST} showAsParagraph={true} />
      {surveyState.data && (
        <SurveyList surveys={surveyState.data} onSelect={onSurveySelect} />
      )}
    </>
  );
};

export default withRouter(SurveyListPage);

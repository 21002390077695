import { apiCall } from './BaseAction';
import {
  ContentPage,
  GetCustomPages,
  GetPageContent,
  SetCurrentPageTitle,
  TGetCustomPagesAction,
  TGetPageContentAction,
  TSetCurrentPageTitleAction,
} from '../types/Content';
import { Dispatch } from 'redux';

export const getPageContentByType = (type: ContentPage) => {
  return apiCall<TGetPageContentAction, null>(
    GetPageContent,
    'GET',
    `/content/byType`,
    true,
    null,
    { type }
  );
};
export const getPageContentById = (id: number) => {
  return apiCall<TGetPageContentAction, null>(
    GetPageContent,
    'GET',
    `/content/byId`,
    true,
    null,
    { id }
  );
};

export const getCustomPages = () => {
  return apiCall<TGetCustomPagesAction, null>(
    GetCustomPages,
    'GET',
    '/content/menu',
    true
  );
};

export const setCurrentPageTitle = (title: string) => {
  return (dispatch: Dispatch<TSetCurrentPageTitleAction>) => {
    dispatch({
      type: SetCurrentPageTitle.SUCCESS,
      payload: { title, url: window.location.pathname },
    });
  };
};

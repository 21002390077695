import {
  ISurveySequence,
  TApiQuestionEntity,
  TApiSurveyCategoryComment,
  TApiSurveyItem,
  TApiSurveyResponseAnswer,
} from '../types/Survey';
import * as _ from 'lodash';

export const getCategory = (
  surveySequence?: Array<ISurveySequence>,
  categoryId?: number | null,
  next: boolean = false
): ISurveySequence | undefined => {
  if (surveySequence) {
    if (!categoryId) {
      return surveySequence[0];
    }

    if (surveySequence && next) {
      const currentIndex = _.findIndex(
        surveySequence,
        (c) => c.data.id === categoryId
      );

      if (surveySequence[currentIndex + 1]) {
        return surveySequence[currentIndex + 1];
      }

      return undefined;
    }

    return _.find(surveySequence, (c) => c.data.id === categoryId);
  }

  return undefined;
};

export const getQuestion = (
  questions: Array<TApiQuestionEntity>,
  currentQuestionId: number | null,
  next: boolean = false
): TApiQuestionEntity | undefined => {
  if (questions) {
    if (!currentQuestionId) {
      return questions[0];
    }

    if (next) {
      const currentIndex = _.findIndex(
        questions,
        (q) => q.id === currentQuestionId
      );

      if (questions[currentIndex + 1]) {
        return questions[currentIndex + 1];
      }

      return undefined;
    }

    return _.find(questions, (q) => q.id === currentQuestionId);
  }

  return undefined;
};

export const getAnswer = (
  answers: Array<TApiSurveyResponseAnswer>,
  questionId: number
): number | undefined => {
  const answer = _.find(answers, (a) => a.questionId === questionId);
  if (answer) {
    return answer.answerId;
  }

  return undefined;
};
export const getAnswerComment = (
  answers: Array<TApiSurveyResponseAnswer>,
  questionId: number
): string | undefined => {
  const answer = _.find(answers, (a) => a.questionId === questionId);
  if (answer) {
    return answer.answerComment;
  }

  return undefined;
};

export const getCategoryComment = (
  categoryComments: Array<TApiSurveyCategoryComment>,
  categoryId: number
): string => {
  const comment = _.find(
    categoryComments,
    (cat) => cat.categoryId === categoryId
  );

  return comment?.comment || '';
};

export const isSurveyExpired = (currentSurvey: TApiSurveyItem) => {
  const currentDate = new Date();
  if (currentSurvey?.survey.endTime) {
    const surveyEndTime = new Date(currentSurvey?.survey.endTime);

    if (surveyEndTime < currentDate) {
      return true;
    }
  }

  return !!currentSurvey?.survey.disabled;
};

export const isSurveyNotPublished = (currentSurvey: TApiSurveyItem) => {
  const currentDate = new Date();

  if (currentSurvey?.survey.startTime) {
    const surveyStartTime = new Date(currentSurvey?.survey.startTime);

    if (surveyStartTime > currentDate) {
      return true;
    }
  }

  return false;
};

export const getResumeSurveyInfo = function (
  sequence: Array<ISurveySequence> | undefined,
  answeredQuestions: Array<number>,
  commentedCategories: Array<number>,
  isCommentsEnabled: boolean | undefined,
  level: number = 0
) {
  // To find where in survey user should be resumed we traverse the survey from end to start.
  // Once an answered question or category comment was found we stop traversing.
  // After stopping go 1 step back and that is where user should be resumed.

  let categoryId = 0;
  let questionId = 0;
  let subCategoryId = 0;
  let responseFound = false;

  if (level > 1) {
    throw new Error("Level can't be more than 2");
  }

  if (sequence) {
    const reversedSequence = [...sequence].reverse();

    reversedSequence.every((category) => {
      const catId = category.data.id;

      if (level === 0) {
        // Found a category comment - stopping search. 
        if (isCommentsEnabled && commentedCategories.includes(catId)) {
          responseFound = true;
          return false;
        }

        // Look into subcategories
        const subSearchResult = getResumeSurveyInfo(
          category.subCategories,
          answeredQuestions,
          commentedCategories,
          isCommentsEnabled,
          level + 1
        );

        if (subSearchResult.responseFound) {
          if (subSearchResult.questionId && subSearchResult.categoryId) {
            // Answer found in subcategories
            categoryId = catId;
            subCategoryId = subSearchResult.categoryId;
            questionId = subSearchResult.questionId;
          } else if (isCommentsEnabled) {
            // Comment found in subcategories
            categoryId = catId;
          }

          return false;
        }

        // Answer/Comment was not found in subcategories. Remember the subcategory and question in case we need to resume there.
        subCategoryId = subSearchResult.categoryId;
        questionId = subSearchResult.questionId;
      }

      const reversedQuestions = [...category.questions ?? []].reverse();

      let lastQuestionInCategoryAnswered = true;
    
      const continueSearching = reversedQuestions.every((question: any) => {
        if (answeredQuestions.includes(question.id)) {
          responseFound = true;
          return false;
        }
        questionId = question.id;
        lastQuestionInCategoryAnswered = false;

        return true;
      });

      if (!continueSearching) {
        // Found an answered question
        if (lastQuestionInCategoryAnswered) {
          // Since last question was answered we will resume either in 1st question of next category or in comments of current category.
          if (isCommentsEnabled && level === 0 && !category.subCategories) {
            questionId = 0;
            categoryId = catId;
          }
          if (subCategoryId !== 0) {
            categoryId = catId;
          }
        } else {
          // Answered question wasn't last so we will resume at next question.
          subCategoryId = 0;
          categoryId = catId;
        }

        return false;
      }

      // No answers found in this category. Remember the category in case we need to resume here and traverse forwards.
      categoryId = catId;

      return true;
    });
  }

  return { categoryId, subCategoryId, questionId, responseFound };
};

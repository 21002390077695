export const routes = {
  prefix: '/app',

  //Unauthorized routes
  login: '/auth/login',
  remind: '/auth/remind',
  logout: '/auth/logout',
  reset: '/auth/reset',

  //Authorized routes
  home: '/',
  editProfile: '/profile/edit',
  changePassword: '/profile/change-password',
  actionPlanList: '/action-plan',
  actionPlanItem: '/action-plan/:id',
  cmsPage: '/page/:id?',

  //Surveys
  surveyList: '/survey',
  surveyStart: '/survey/:id/start',
  surveyIdentification: '/survey/:id/identification',
  surveyCategory:
    '/survey/:id/category/description/:categoryId?/:subCategoryId?',
  surveyQuestion:
    '/survey/:id/category/:categoryId/question/:questionId?/:subCategoryId?',
  surveyCategoryComment: '/survey/:id/category/comment/:categoryId',
  surveySendAnswersPage: '/survey/:id/send-answers',
  surveyEndPage: '/survey/:id/end',
  surveyPrintPage: '/survey/:id/print',

  //inspection
  inspectionList: '/inspection',
  inspectionIdentification: '/inspection/:id/identification',

  //Cases
  reportCase: '/case/report',

  //Chemicals
  chemicalsList: '/chemicals',
  chemicalProduct: '/chemical/:productId',

  //Processes
  processList: '/process',
  processItem: '/process/:id',

  //Meetings
  meetingsList: '/meetings',
  meetingsPage: '/meetings/:id',

  whistleblower: '/anonymous/whistleblower',
  whistleblowerCommunication: '/anonymous/whistleblower/communication',
  whistleblowerCommunicationChat: '/anonymous/whistleblower/communication/:id',
};

import axios, { AxiosInstance } from "axios";
import { baseApiUrl } from "../../actions/BaseAction";

export const useApiClient = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: baseApiUrl,
    headers: {
      "Content-Type": "application/json;text/html",
      Accept: "application/json;text/html"
    }
  });

  instance.interceptors.request.use(request => {
    const token = localStorage.getItem("token");

    request.headers["Authorization"] = `Bearer ${token}`;

    return request;
  });

  return instance;
};

import * as React from 'react';
import EditProfileForm from '../../../components/forms/profile/EditProfileForm';
import { FormEvent, useState } from 'react';
import { ProfileEdit, TAuthState } from '../../../types/Auth';
import { useApp } from '../../../hooks/useApp';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store/Store';
import { checkUserToken, editProfile } from '../../../actions/AuthActions';
import { TDispatch } from '../../../types/Thunk';
import AlertBlock from '../../../components/AlertBlock';
import { useTranslation } from 'react-i18next';

const ProfileEditPage: React.FC = (): JSX.Element => {
  const authState: TAuthState = useSelector<IAppState, TAuthState>(
    (state) => state.authState
  );
  const [name, setName] = useState(authState.data?.user?.name as string);
  const [email, setEmail] = useState(authState.data?.user?.email as string);
  const [success, setSuccess] = useState(false);
  const { app } = useApp();
  const dispatch = useDispatch<TDispatch>();
  const { t } = useTranslation();

  const submitEditProfile = (e: FormEvent) => {
    e.preventDefault();
    if (!success) {
      return dispatch(editProfile(name)).then(() => {
        dispatch(checkUserToken()).then(() => setSuccess(true));
      });
    }

    return false;
  };

  return (
    <>
      {success && (
        <AlertBlock type={'success'} onClose={() => setSuccess(false)}>
          {t('profile.update.success.message')}
        </AlertBlock>
      )}
      {authState.data?.user && (
        <EditProfileForm
          errors={app.getError(ProfileEdit)}
          name={name}
          setName={setName}
          email={email}
          setEmail={setEmail}
          organization={authState.data?.user?.department?.title}
          location={authState.data?.user?.location?.title}
          submitForm={submitEditProfile}
        />
      )}
    </>
  );
};

export default ProfileEditPage;

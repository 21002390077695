import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

interface LoaderProps {
  visible: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  loader: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(60, 176, 253, .25)',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
}));

const Loader: React.FC<LoaderProps> = (props): JSX.Element => {
  const classes = useStyles();
  return (
    <div
      className={classes.loader}
      style={{ display: props.visible ? 'flex' : 'none' }}
    >
      <CircularProgress />
    </div>
  );
};

export default Loader;

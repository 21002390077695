import { TAuthState } from '../types/Auth';
import { useSelector } from 'react-redux';
import { IAppState } from '../store/Store';
import { Md5 } from 'md5-typescript';
import { TApiUserDepartment, TApiUserLocation } from '../types/User';

export const useUser = () => {
  const authState: TAuthState = useSelector<IAppState, TAuthState>(
    (state) => state.authState
  );

  const isUserAnonymous = (): boolean => {
    return authState.data?.user === null && !!authState.data.apiToken;
  };

  const getUniqueUserId = (): string => {
    const companyName = authState.data?.solution.company;
    const userName = authState.data?.user?.email;
    return Md5.init(companyName + '_' + userName);
  };

  const getUserDepartment = (): TApiUserDepartment | null => {
    return authState.data?.user?.department || null;
  };

  const getUserLocation = (): TApiUserLocation | null => {
    return authState.data?.user?.location || null;
  };

  return {
    isUserAnonymous,
    getUniqueUserId,
    getUserDepartment,
    getUserLocation,
  };
};

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { TApiChemicalProductHazard, TApiChemicalProductSignalWord } from '../../../types/Chemical';
import { useChemicalProductStyles } from '../ChemicalProduct.styles';

interface IHazardsProps {
  hazards: Array<TApiChemicalProductHazard>;
  expanded: boolean;
  signalWordType?: TApiChemicalProductSignalWord;
  hazardStatements?: string;
  precautionaryStatements?: string
  otherHazards?: string,
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const Hazards: React.FC<IHazardsProps> = ({
  hazards,
  expanded,
  signalWordType,
  hazardStatements,
  precautionaryStatements,
  otherHazards,
  handleChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useChemicalProductStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange(`panel3`)}
      key={'panel3'}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel3bh-content`}
        id={`panel3bh-header`}
      >
        <Typography className={classes.heading}>{t('chemicals.hazards')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.hazardsList}>
        {hazards.map((hazard) => {
          return (
            <ListItem key={hazard.label}>
              <ListItemAvatar>
                <Avatar className={classes.hazardIcon}>
                  <img src={hazard.image} alt='hazard.label' />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={hazard.label} />
            </ListItem>
          );
        })}
        {signalWordType && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.signal_word')}</b> <p style="color: ${signalWordType.color}">${t(signalWordType.name)}</p>` }} />
        )}
        {hazardStatements && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.hazard_statements')}</b> ${hazardStatements}` }} />
        )}
        {precautionaryStatements && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.precautionary_statements')}</b> ${precautionaryStatements}` }} />
        )}
        {otherHazards && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.other_hazards')}</b> ${otherHazards}` }} />
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Hazards;

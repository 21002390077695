import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  DeepPartial,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { authReducer } from '../reducers/AuthReducer';
import { requestReducer } from '../reducers/RequestReducer';
import { TAuthState } from '../types/Auth';
import { TActionPlanState } from '../types/Actionplan';
import { actionPlanReducer } from '../reducers/ActionPlanReducer';
import { TContentState } from '../types/Content';
import { contentReducer } from '../reducers/ContentReducer';
import { TSurveysState } from '../types/Survey';
import { surveyReducer } from '../reducers/SurveyReducer';
import { TCasesState } from '../types/Cases';
import { caseReducer } from '../reducers/CaseReducer';
import { ErrorHandler } from '../middlewares/ErrorHandler';
import { TErrorState } from '../types/Error';
import { errorReducer } from '../reducers/ErrorReducer';
import { ClearStoreState } from '../types/App';
import { TChemicalState } from '../types/Chemical';
import { chemicalReducer } from '../reducers/ChemicalReducer';
import { inspectionReducer } from '../reducers/InspectionReducer';
import { TInspectionState } from '../types/Inspection';
import { meetingReducer } from '../reducers/MeetingReducer';
import { TMeetingState } from '../types/Meeting';
import { TProcessState } from '../types/Process';
import { processReducer } from '../reducers/ProcessReducer';

export interface IAppState {
  authState: TAuthState;
  actionPlanState: TActionPlanState;
  appState: any;
  contentState: TContentState;
  surveysState: TSurveysState;
  caseState: TCasesState;
  errorState: TErrorState;
  chemicalState: TChemicalState;
  inspectionState: TInspectionState;
  meetingState: TMeetingState;
  processState: TProcessState;
}

export interface IRequestReducer {
  [key: string]: boolean | {};
}

const initialAppState: DeepPartial<IAppState> = {
  authState: undefined,
  actionPlanState: {},
  appState: {},
  contentState: {},
  surveysState: {},
  caseState: {},
  errorState: {},
  chemicalState: {},
  inspectionState: {},
  processState: {},
};

const appReducer = combineReducers<IAppState>({
  authState: authReducer,
  actionPlanState: actionPlanReducer,
  appState: requestReducer,
  contentState: contentReducer,
  surveysState: surveyReducer,
  caseState: caseReducer,
  errorState: errorReducer,
  chemicalState: chemicalReducer,
  inspectionState: inspectionReducer,
  meetingState: meetingReducer,
  processState: processReducer,
});

const rootReducer = (state: IAppState | undefined, action: AnyAction) => {
  if (action.type === ClearStoreState.SUBMIT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export function endReducer<T>(this: void, state: T, action: any): T {
  return state;
}

export default function configureStore(): Store<IAppState, AnyAction> {
  return createStore(
    rootReducer,
    initialAppState,
    composeWithDevTools(applyMiddleware(thunk, ErrorHandler))
  );
}

import moment from "moment";
import { reduce } from "lodash";

export const simpleDateFormat = (date: Date | undefined) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD");
  }

  return "";
};

export const simpleDateTimeFormat = (date: Date | undefined) => {
  if (date) {
    return moment(date).format("YYYY-MM-DD HH:mm");
  }

  return false;
};

export const capitalize = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function interpolate(
  template: string,
  variables: Record<string, string | number>
): string {
  return reduce(
    variables,
    (str: string, value: string | number, key: string) => {
      return str.replace(`{{${key}}}`, String(value));
    },
    template
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';

interface IHandlingAndStorage {
  handlingAndStorage?: string;
  storage?: string;
  disposalConsiderations?: string;
  expanded: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
  })
);

const HandlingAndStorage: React.FC<IHandlingAndStorage> = ({
  handlingAndStorage,
  storage,
  disposalConsiderations,
  expanded,
  handleChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange(`panel5`)}
      key={'panel5'}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel5bh-content`}
        id={`panel5bh-header`}
      >
        <Typography className={classes.heading}>{t('chemicals.handling.and.storage')}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.list}>
        {handlingAndStorage && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.handling_and_storage')}</b> ${handlingAndStorage}` }} />
        )}
        {storage && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.storage')}</b> ${storage}` }} />
        )}
        {disposalConsiderations && (
          <Typography dangerouslySetInnerHTML={{ __html: `<b>${t('chemicals.disposal_considerations')}</b> ${disposalConsiderations}` }} />
        )}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default HandlingAndStorage;

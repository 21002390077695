import {
  TApiSurveyEntity,
  TSkipLogic,
  TSurveyRespondentId,
  TSurveysState,
} from '../types/Survey';
import { useSelector } from 'react-redux';
import { IAppState } from '../store/Store';
import { SurveyInspectionType } from '../types/Content';
import { useUser } from './useUser';
import { reverse } from 'named-urls';
import { routes } from '../Routes';
import { RouteComponentProps } from 'react-router-dom';

type TRespondentState = {
  id: TSurveyRespondentId;
  surveyId: number;
  lang: string;
};

const respondentsStorage = 'respondentsStorage';
const completedSurveyStorage = 'completedSurveyStorage';

export const loadStoredRespondentState = (): TRespondentState | null => {
  const respondentData = JSON.parse(
    localStorage.getItem('respondent') as string
  );

  if (!respondentData || typeof respondentData !== 'object') {
    return null;
  }

  const id = respondentData['id'];
  if (typeof id !== 'string') {
    return null;
  }

  let surveyId = respondentData['surveyId'];
  if (typeof surveyId === 'string') {
    surveyId = Number(surveyId);
  }
  if (typeof surveyId !== 'number') {
    return null;
  }

  let lang = respondentData['lang'];
  if (typeof lang !== 'string') {
    return null;
  }

  return {
    id: id as unknown as TSurveyRespondentId,
    surveyId,
    lang,
  };
};

export const generateDummyRespondentId = (): TSurveyRespondentId => {
  // this needs to be falsy to not be confused with real ids
  return `` as unknown as TSurveyRespondentId;
};

const isObject = (obj: any): boolean => {
  return obj && typeof obj === 'object' && typeof obj.length === 'undefined';
};

const isArray = (obj: any): boolean => {
  return obj && typeof obj === 'object' && typeof obj.length === 'number';
};

export const useCurrentActiveSurvey = () => {
  const { getUniqueUserId } = useUser();

  const respondentData = loadStoredRespondentState();

  const isSurveyStarted = (surveyId: number) => {
    return respondentData?.surveyId === surveyId;
  };

  const getRespondentId = () => {
    return respondentData?.id;
  };

  const getSelectedSurveyId = (): number | null => {
    return respondentData ? respondentData.surveyId : null;
  };

  const getSelectedSurveyLang = (surveyId: number) => {
    if (respondentData && respondentData.surveyId === surveyId) {
      return respondentData.lang;
    }
    return null;
  };

  const setRespondent = (
    id: TSurveyRespondentId,
    surveyId: number,
    lang: string
  ) => {
    localStorage.setItem(
      'respondent',
      JSON.stringify({
        id,
        surveyId,
        lang,
      })
    );
  };

  const removeRespondentData = () => {
    localStorage.removeItem('respondent');
  };

  const loadCommonRespondentsStorage = () => {
    const ret = JSON.parse(localStorage.getItem(respondentsStorage) as string);

    if (!isObject(ret)) {
      return {};
    }

    return ret;
  };

  const loadRespondentsStorage = () => {
    const mainObj = loadCommonRespondentsStorage();

    const ret = mainObj[getUniqueUserId()];

    if (!isObject(ret)) {
      return {};
    }

    return ret;
  };

  const setRespondentsStorage = (data: object) => {
    const obj = loadCommonRespondentsStorage();
    obj[getUniqueUserId()] = data;
    localStorage.setItem(respondentsStorage, JSON.stringify(obj));
  };

  const addToRespondentsStorage = (
    surveyId: number,
    id: TSurveyRespondentId
  ) => {
    const storage = loadRespondentsStorage();
    storage[surveyId] = id;
    setRespondentsStorage(storage);
  };

  const removeFromRespondentsStorage = (surveyId: number) => {
    const storage = loadRespondentsStorage();
    delete storage[surveyId];
    setRespondentsStorage(storage);
  };

  const getCommonCompletedSurveysStorage = () => {
    const ret = JSON.parse(
      localStorage.getItem(completedSurveyStorage) as string
    );

    if (!isObject(ret)) {
      return {};
    }

    return ret;
  };

  const getCompletedSurveysStorage = () => {
    const commonStorage = getCommonCompletedSurveysStorage();
    const ret = commonStorage[getUniqueUserId()];

    if (!isObject(ret)) {
      return {};
    }

    return ret;
  };

  const setCompletedSurveysStorage = (storage: object) => {
    const commonStorage = getCommonCompletedSurveysStorage();
    commonStorage[getUniqueUserId()] = storage;
    localStorage.setItem(completedSurveyStorage, JSON.stringify(commonStorage));
  };

  const addCompletedSurvey = (
    surveyId: string,
    respondentId: TSurveyRespondentId
  ) => {
    const completedSurveys = getCompletedSurveysStorage();
    if (!isArray(completedSurveys[surveyId])) {
      completedSurveys[surveyId] = [];
    }
    completedSurveys[surveyId].push(respondentId);
    setCompletedSurveysStorage(completedSurveys);
  };

  const isSurveyCompleted = (survey: TApiSurveyEntity) => {
    if (!survey.limitMultipleSubmissions) return false;
    const completedSurveys = getCompletedSurveysStorage();
    const data = completedSurveys[survey.id];
    return isArray(data) && data.length > 0;
  };

  const surveyState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state) => state.surveysState
  );

  const isInspection =
    surveyState.currentSurvey?.survey.surveyType ===
    SurveyInspectionType.INSPECTION;

  return {
    isSurveyStarted,
    getRespondentId,
    setRespondent,
    getSelectedSurveyLang,
    getSelectedSurveyId,
    removeRespondentData,
    isInspection,
    loadRespondentsStorage,
    addToRespondentsStorage,
    setRespondentsStorage,
    removeFromRespondentsStorage,
    addCompletedSurvey,
    isSurveyCompleted,
    getCompletedSurveysStorage,
    setCompletedSurveysStorage,
  };
};

export const useSurveyNavigation = (
  history: RouteComponentProps['history']
) => {
  const goToQuestion = (
    surveyId: number,
    categoryId: number,
    questionId: number,
    subCategoryId: number | null
  ) => {
    history.push(
      reverse(routes.surveyQuestion, {
        id: surveyId,
        categoryId,
        questionId,
        ...(subCategoryId && { subCategoryId }),
      })
    );
  };

  const goToCategory = (
    surveyId: number,
    categoryId: number,
    subCategoryId: number | null
  ) => {
    history.push(
      reverse(routes.surveyCategory, {
        id: surveyId,
        categoryId,
        ...(subCategoryId && { subCategoryId }),
      })
    );
  };

  const goToFinishSurveyPage = (surveyId: number) => {
    history.push(reverse(routes.surveySendAnswersPage, { id: surveyId }));
  };

  return {
    goToQuestion,
    goToCategory,
    goToFinishSurveyPage,
  };
};

export const useSkipLogic = () => {
  const surveysState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state) => state.surveysState
  );

  const findSkipLogic = (
    questionId: number,
    answerId: number
  ): TSkipLogic | null => {
    if (surveysState.skipLogic) {
      for (const item of surveysState.skipLogic) {
        if (item.questionId === questionId && item.answerId === answerId) {
          return item;
        }
      }
    }

    return null;
  };

  const getQuestionParentInfo = (
    questionId: number
  ): {
    categoryId: number;
    subcategoryId?: number;
  } | null => {
    if (!surveysState.surveySequence) return null;

    for (const seqCategory of surveysState.surveySequence) {
      if (seqCategory.questions) {
        for (const question of seqCategory.questions) {
          if (question.id === questionId) {
            return {
              categoryId: seqCategory.data.id,
            };
          }
        }
      }

      if (seqCategory.subCategories) {
        for (const seqSubCategory of seqCategory.subCategories) {
          if (seqSubCategory.questions) {
            for (const question of seqSubCategory.questions) {
              if (question.id === questionId) {
                return {
                  categoryId: seqCategory.data.id,
                  subcategoryId: seqSubCategory.data.id,
                };
              }
            }
          }
        }
      }
    }

    return null;
  };

  const getCategoryParentId = (categoryId: number): number | null => {
    if (!surveysState.surveySequence) return null;

    for (const seqCategory of surveysState.surveySequence) {
      if (seqCategory.data.id === categoryId) return null;

      if (seqCategory.subCategories) {
        for (const seqSubCategory of seqCategory.subCategories) {
          if (seqSubCategory.data.id === categoryId) return seqCategory.data.id;
        }
      }
    }

    return null;
  };

  return {
    findSkipLogic,
    getQuestionParentInfo,
    getCategoryParentId,
  };
};

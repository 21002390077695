import * as React from 'react';
import { useEffect, useRef } from 'react';
import fetchAndReplaceImages from './helpers/ImageLoader';
import {
  cleanupSpecialAnchorButtons,
  handleSpecialAnchorButtons,
} from './helpers/AnchorLoader';

interface GlobalImageComponentProps {
  children: React.ReactNode;
}

const UploadsComponent: React.FC<GlobalImageComponentProps> = ({ children }) => {
  const containerRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      fetchAndReplaceImages(container);
      handleSpecialAnchorButtons(container);
    }

    return () => {
      if (container) {
        cleanupSpecialAnchorButtons(container);
      }
    }
  }, [children]);

  return <div ref={containerRef as React.RefObject<HTMLDivElement>}>{children}</div>
}

export default UploadsComponent;


import { fetchWithAuthentication } from '../actions/BaseAction';


const retrieveButtonElements = (container: HTMLElement) => {
  return container.querySelectorAll('button.button--upload-link-replacement');
}

const handleClick = (event: any) => {
  const target = event.target;
  const anchor = target.querySelector('a');
  const url = new URL(anchor.href);
  const anchorPath = url.pathname;

  fetchWithAuthentication(anchorPath)
    .then(blob => {
      if (blob !== null) {
        anchor.href = URL.createObjectURL(blob);
        target.parentNode.insertBefore(anchor, target);
        target.parentNode.removeChild(target);

        anchor.click();
      } else {
        event.preventDefault();
      }
    })
    .catch(error=> {throw error});
}

export const fetchAndReplaceAnchors = (container: HTMLElement | null) => {
  if (container) {
    const anchorElements = container.querySelectorAll('a');
    anchorElements.forEach((anchor: HTMLAnchorElement) => {
      if (anchor.href.includes('uploads')) {
        const url = new URL(anchor.href);
        const anchorPath = url.pathname;

        fetchWithAuthentication(anchorPath)
          .then(blob => {
            if (blob !== null) {
              anchor.href = URL.createObjectURL(blob);
            }
          })
          .catch(error=> {throw error});
      }
    });
  }
};

export const handleSpecialAnchorButtons = (container: HTMLElement) => {
  const buttonElements = retrieveButtonElements(container);

  buttonElements.forEach(button => {
    button.addEventListener('click', handleClick);
  });
}

export const cleanupSpecialAnchorButtons = (container: HTMLElement) => {
  const buttonElements = retrieveButtonElements(container);

  buttonElements.forEach(button => {
    button.removeEventListener('click', handleClick);
  });
}
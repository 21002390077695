export enum SaveInspectionQuestionData {
  SET = 'INSPECTION_QUESTION_DATA_SET',
  SENT = 'INSPECTION_QUESTION_DATA_SENT',
  SET_LOCATION = 'INSPECTION_SET_LOCATION',
}

export interface TInspectionState {
  readonly status?: boolean;
  readonly sent?: boolean;
  readonly locationId?: number;
}

export type TSaveInspectionQuestionDataAction = {
  type:
    | SaveInspectionQuestionData.SET
    | SaveInspectionQuestionData.SENT
    | SaveInspectionQuestionData.SET_LOCATION;
  payload: TInspectionState;
};

export type TInspectionActions = TSaveInspectionQuestionDataAction;

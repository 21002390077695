import { Reducer } from 'redux';
import {
  AnonymousUserLogin,
  GetAppSettings,
  initialAuthState,
  TAuthActions,
  TAuthState,
  UserCheckToken,
  UserLogin,
  UserLogout,
} from '../types/Auth';
import { endReducer } from '../store/Store';

export const authReducer: Reducer<TAuthState, TAuthActions> = (
  state = initialAuthState,
  action
) => {
  switch (action.type) {
    case UserLogin.SUCCESS: {
      localStorage.setItem('token', action.payload.data.apiToken);
      return {
        ...state,
        data: action.payload.data,
      };
    }

    case UserLogin.FAILED:
    case AnonymousUserLogin.FAILED:
      return {
        settings: state.settings,
      };

    case AnonymousUserLogin.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case AnonymousUserLogin.SUCCESS: {
      localStorage.setItem('token', action.payload.data.apiToken);
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    }

    case UserCheckToken.SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      };
    }

    case UserCheckToken.REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case UserCheckToken.FAILED: {
      localStorage.removeItem('token');
      return {
        settings: state.settings,
      };
    }

    case UserLogout.REQUEST: {
      return {
        loading: true,
      };
    }

    case UserLogout.SUCCESS: {
      localStorage.removeItem('token');
      return initialAuthState;
    }

    case UserLogout.FAILED: {
      return state;
    }

    case GetAppSettings.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetAppSettings.SUCCESS:
      return {
        ...state,
        loading: false,
        settings: action.payload.data,
      };

    case GetAppSettings.FAILED:
      return state;

    default:
      return endReducer(state, action);
  }
};

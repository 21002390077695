import React from "react";
import { withRouter } from "react-router-dom";
import { WhistleblowerSearchForm } from "../forms/WhistleblowerSearchForm";
import { IWhistleblowerApiEntity } from "../types/Whistleblower";
import { useWhistleblowerSearch } from "../hooks/useWhistleblowerSearch";
import { CommunicationChat } from "../components/CommunicationChat";

const WhistleblowerCommunication = () => {
  const [
    whistleblower,
    setWhistleblower
  ] = React.useState<IWhistleblowerApiEntity | null>(null);
  const [code, setCode] = React.useState<string | null>(null);

  const { mutate: searchWhistleblower } = useWhistleblowerSearch();

  const handleOnSearch = (data: IWhistleblowerApiEntity, code: string) => {
    setWhistleblower(data);
    setCode(code);
  };

  const handleChatUpdate = (data: IWhistleblowerApiEntity) => {
    // After user submits a message, we need to update the chat
    setWhistleblower(data);
  };

  return (
    <>
      {code && whistleblower ? (
        <CommunicationChat
          code={code}
          data={whistleblower}
          onUpdate={handleChatUpdate}
        />
      ) : (
        <WhistleblowerSearchForm
          onSearch={handleOnSearch}
          searchWhistleblower={searchWhistleblower}
        />
      )}
    </>
  );
};

export default withRouter(WhistleblowerCommunication);

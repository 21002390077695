import { apiCall } from './BaseAction';
import {
  FilterProcessList,
  Process,
  ProcessCategories,
  ProcessList,
  TApiProcessCategory,
  TApiProcessEntity,
  TFilterProcessListAction,
  TGetProcess,
  TGetProcessCategories,
  TGetProcessList,
} from '../types/Process';
import { Dispatch } from 'redux';


export const getCategories = () => {
  return apiCall<TGetProcessCategories, null, Array<TApiProcessCategory>>(
    ProcessCategories,
    'GET',
    '/processes/categories',
    true
  );
};

export const getProcessList = (categoryId?: number) => {
  return apiCall<TGetProcessList, null, Array<TApiProcessEntity>>(
    ProcessList,
    'GET',
    '/processes',
    true,
    null,
    { category: categoryId}
  );
};

export const getProcess = (processId: number) => {
  return apiCall<TGetProcess, null, TApiProcessEntity>(
    Process,
    'GET',
    `/processes/${processId}`,
    true
  );
};

export const filterProcessList = (search: string) => {
  return (dispatch: Dispatch<TFilterProcessListAction>) => {
    dispatch({
      type: FilterProcessList.SUBMIT,
      payload: {search},
    });
  };
};
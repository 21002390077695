import { useSelector } from 'react-redux';
import { IAppState } from '../store/Store';
import { ContentPage, TContentState } from '../types/Content';

export const useContentState = () => {
  const contentState: TContentState = useSelector<IAppState, TContentState>(
    (state) => state.contentState
  );

  const isPagePublished = (pageType: ContentPage) =>
    !!contentState?.customPages?.find((page) => page.type === pageType);

  const isCasePagePublished = isPagePublished(ContentPage.CASES_PAGE);

  return { isCasePagePublished };
};

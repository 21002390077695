import * as React from "react";
import { useEffect } from "react";
import { routes } from "../../Routes";
import HomePage from "../../containers/pages/HomePage";
import { authenticated } from "../hoc/requiresAuthentication";
import { withRouter } from "react-router";
import ActionPlanListPage from "../../containers/pages/actionplans/ActionPlanListPage";
import ActionPlanPage from "../../containers/pages/actionplans/ActionPlanPage";
import { DynamicRoute } from "./DynamicRoute";
import { useTranslation } from "react-i18next";
import { ContentPage, TContentState } from "../../types/Content";
import ProfileEditPage from "../../containers/pages/profile/ProfileEditPage";
import ChangePasswordPage from "../../containers/pages/profile/ChangePasswordPage";
import { useDispatch, useSelector } from "react-redux";
import { getCustomPages } from "../../actions/ContentActions";
import SurveyListPage from "../../containers/pages/surveys/SurveyListPage";
import CmsPage from "../../containers/pages/CmsPage";
import { IAppState } from "../../store/Store";
import SurveyRoutes from "./SurveyRoutes";
import LogoutPage from "../../containers/pages/auth/LogoutPage";
import CaseReport from "../../containers/pages/cases/CaseReport";
import { useUser } from "../../hooks/useUser";
import { isEmpty } from "lodash";
import ChemicalListPage from "../../containers/pages/chemicals/ChemicalListPage";
import ChemicalProductPage from "../../containers/pages/chemicals/ChemicalProductPage";
import InspectionListPage from "../../containers/pages/inspections/InspectionListPage";
import { useInspection } from "../../hooks/useInspection";
import MeetingsListPage from "../../containers/pages/meetings/MeetingsListPage";
import MeetingPage from "../../containers/pages/meetings/MeetingPage";
import WhistleblowerPage from "../../containers/pages/whistleblower/pages/WhistleblowerPage";
import WhistleblowerCommunication from "../../containers/pages/whistleblower/pages/WhistleblowerCommunication";
import SecurityIcon from '@material-ui/icons/Security';
import ProcessListPage from '../../containers/pages/processes/ProcessListPage';
import ProcessPage from '../../containers/pages/processes/ProcessPage';

const AuthRoutes: React.FC = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const contentState: TContentState = useSelector<IAppState, TContentState>(
    state => state.contentState
  );
  const { isInspectionStored } = useInspection();
  const { isUserAnonymous } = useUser();

  useEffect(() => {
    if (isEmpty(contentState.customPages) && !contentState.loading) {
      dispatch(getCustomPages());
    }
  }, [
    dispatch,
    contentState.customPages,
    isUserAnonymous,
    contentState.loading
  ]);

  return (
    <>
      <DynamicRoute
        navbarText={t("navbar.title")}
        key={"home"}
        exact
        path={routes.home}
        component={HomePage}
        pageType={ContentPage.FRONTPAGE}
      />
      <DynamicRoute
        navbarText={t("actionplan.list.page.title")}
        key={"actionplan-list"}
        exact
        path={routes.actionPlanList}
        component={ActionPlanListPage}
        pageType={ContentPage.ACTIONPLANS}
      />
      <DynamicRoute
        navbarText={t("actionplan.page.title")}
        showBackButton={true}
        key={"actionplan"}
        exact
        path={routes.actionPlanItem}
        component={ActionPlanPage}
        pageType={ContentPage.ACTIONPLAN}
      />
      <DynamicRoute
        navbarText={t("profile.edit.page.title")}
        showBackButton={true}
        key={"profile-page"}
        exact
        path={routes.editProfile}
        component={ProfileEditPage}
      />
      <DynamicRoute
        navbarText={t("profile.change.password.page.title")}
        showBackButton={true}
        key={"change-password-page"}
        exact
        path={routes.changePassword}
        component={ChangePasswordPage}
      />
      <DynamicRoute
        navbarText={t("survey.list.page.title")}
        key={"survey-list-page"}
        exact
        path={routes.surveyList}
        component={SurveyListPage}
        pageType={ContentPage.SURVEYS_LIST}
      />
      <DynamicRoute
        navbarText={t("inspection.list.page.title")}
        key={"inspection-list-page"}
        exact
        path={routes.inspectionList}
        component={InspectionListPage}
        pageType={ContentPage.INSPECTIONS_LIST}
      />
      <DynamicRoute
        navbarText={" "}
        key={"cms-page"}
        exact
        path={routes.cmsPage}
        component={CmsPage}
      />
      <DynamicRoute
        navbarText={"Logout"}
        key={"logout-page"}
        exact
        path={routes.logout}
        component={LogoutPage}
      />
      <DynamicRoute
        showBackButton={isInspectionStored}
        navbarText={t("report.case.page.title")}
        key={"report-case-page"}
        exact
        path={routes.reportCase}
        component={CaseReport}
        pageType={ContentPage.CASES_PAGE}
      />
      <DynamicRoute
        navbarText={t("chemicals.page.title")}
        key={"chemicals-list-page"}
        exact
        path={routes.chemicalsList}
        component={ChemicalListPage}
      />
      <DynamicRoute
        navbarText={t("chemical.product.page.title")}
        key={"chemical-product-page"}
        showBackButton={true}
        exact
        path={routes.chemicalProduct}
        component={ChemicalProductPage}
      />
      <DynamicRoute
        navbarText={t("meetings.page.title")}
        key={"meetings-list-page"}
        exact
        path={routes.meetingsList}
        component={MeetingsListPage}
      />
      <DynamicRoute
        navbarText={t("meetings.product.page.title")}
        key={"meeting-page"}
        showBackButton={true}
        exact
        path={routes.meetingsPage}
        component={MeetingPage}
      />
      <SurveyRoutes />
      <DynamicRoute
        key={"whistleblower-page"}
        exact
        showBackButton={false}
        hideAccountMenu={true}
        customIcon={SecurityIcon}
        path={routes.whistleblower}
        component={WhistleblowerPage}
        pageType={ContentPage.WHISTLEBLOWER}
      />
      <DynamicRoute
        navbarText="Whistleblower communication"
        key={"whistleblower-communication-page"}
        showBackButton={false}
        hideAccountMenu={true}
        exact
        customIcon={SecurityIcon}
        path={routes.whistleblowerCommunication}
        component={WhistleblowerCommunication}
        pageType={ContentPage.WHISTLEBLOWER}
      />
      <DynamicRoute
        navbarText={t("process.page.title")}
        key={"processes-list-page"}
        exact
        path={routes.processList}
        component={ProcessListPage}
        pageType={ContentPage.PROCESSES}
      />
      <DynamicRoute
        navbarText={"Process"}
        key={"process-page"}
        showBackButton={true}
        exact
        path={routes.processItem}
        component={ProcessPage}
      />
    </>
  );
};

export default authenticated(withRouter(AuthRoutes));

import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

interface ICmsCardProps {
  headline: string;
  bodyText: string;
  image?: string;
}

const useStyles = makeStyles({
  root: {
    marginBottom: '15px',
  },
  media: {
    height: 140,
  },
});

const CmsCard: React.FC<ICmsCardProps> = ({
  bodyText,
  headline,
  image,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        {image && <CardMedia className={classes.media} image={image} />}

        <CardContent>
          <Typography
            gutterBottom
            variant='h5'
            component='h2'
            dangerouslySetInnerHTML={{ __html: headline }}
          />
          <Typography
            variant='body2'
            color='textSecondary'
            component='p'
            dangerouslySetInnerHTML={{ __html: bodyText }}
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CmsCard;

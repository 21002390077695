import * as React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { TDispatch } from '../../types/Thunk';
import { oAuthServiceInit } from '../../actions/AuthActions';
import { AxiosResponse } from 'axios';
import { useState } from 'react';
import Loader from '../Loader';
import microsoftLogo from '../../assets/images/microsoft.svg';
import googleLogo from '../../assets/images/google.svg';

const useStyles = makeStyles((theme: Theme) => ({
  authButtons: {
    margin: theme.spacing(2, 0, 2),
    display: 'flex',
    width: '100%',
    '& button': {
      width: '49%',
    },
  },
  google: {
    color: 'white',
    width: '18px !important',
    marginRight: '8px',
    filter: 'invert(1)',
  },
  microsoft: {
    color: 'white',
    width: '18px !important',
    marginRight: '8px',
    filter: 'invert(1)',
  },
}));

interface ISocialButtonsProps {
  googleAllowed: boolean;
  microsoftAllowed: boolean;
}

const SocialButtons: React.FC<ISocialButtonsProps> = ({
  googleAllowed,
  microsoftAllowed,
}): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch<TDispatch>();
  const [loading, setLoading] = useState(false);
  const currentUrl = window.location.pathname.replace('/app', '');

  const onlyOneEnabled =
    (googleAllowed && !microsoftAllowed) ||
    (!googleAllowed && microsoftAllowed);

  const handleLoginService = (service: 'microsoft' | 'google') => {
    setLoading(true);
    dispatch(oAuthServiceInit(service, { fallbackUrl: currentUrl }))
      .then((res: AxiosResponse<any>) => {
        window.location.replace(res.data.gotoUrl);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Loader visible={loading} />;
  }

  return (
    <div
      className={classes.authButtons}
      style={{ justifyContent: onlyOneEnabled ? 'center' : 'space-between' }}
    >
      {googleAllowed && (
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={() => handleLoginService('google')}
        >
          <img src={googleLogo} className={classes.google} alt='' />
          Google
        </Button>
      )}

      {microsoftAllowed && (
        <Button
          type='button'
          variant='contained'
          color='primary'
          onClick={() => handleLoginService('microsoft')}
        >
          <img src={microsoftLogo} className={classes.microsoft} alt='' />
          Microsoft
        </Button>
      )}
    </div>
  );
};

export default SocialButtons;

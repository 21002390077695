import * as React from 'react';
import { ComponentClass, FC, ReactElement } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import NavbarItemList from './NavbarItemList';
import { routes } from '../../Routes';
import { RouteComponentProps } from 'react-router';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useUser } from '../../hooks/useUser';

interface INavbarProps extends RouteComponentProps<any> {
  visible: boolean;
  text: string | ReactElement<any>;
  showBackButton?: boolean;
  hideAccountMenu?: boolean;
  customIcon?: ComponentClass | FC;
}

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: 'white',
      marginBottom: theme.spacing(2),
    },
    menuButton: {
      marginRight: theme.spacing(2),
      color: 'white',
    },
    title: {
      flexGrow: 1,
      color: 'white',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'block',
      overflow: 'hidden',
    },
    profileIcon: {
      color: 'white',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    icon: {
      minWidth: '35px',
    },
  })
);

const Navbar: React.FC<INavbarProps> = (props): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const profileDropdownOpen = Boolean(anchorEl);
  const { t } = useTranslation();
  const { isUserAnonymous } = useUser();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <>
      {props.visible && (
        <div className={classes.root}>
          <AppBar
            position='fixed'
            className={clsx(classes.appBar, {
              [classes.appBarShift]: drawerOpen,
            })}
          >
            <Toolbar>
              {props.customIcon ? (
                <IconButton
                  color='inherit'
                  edge='start'
                  className={classes.menuButton}
                >
                  {React.createElement(props.customIcon)}
                </IconButton>
              ) : !props.showBackButton ? (
                <>
                  <IconButton
                    color='inherit'
                    aria-label='open drawer'
                    onClick={handleDrawerOpen}
                    edge='start'
                    className={clsx(
                      classes.menuButton,
                      drawerOpen && classes.hide
                    )}
                  >
                    <MenuIcon />
                  </IconButton>
                </>
              ) : (
                <IconButton
                  color='inherit'
                  aria-label='open drawer'
                  onClick={() => props.history.goBack()}
                  edge='start'
                  className={clsx(
                    classes.menuButton,
                    drawerOpen && classes.hide
                  )}
                >
                  <KeyboardArrowLeftIcon fontSize={'large'} />
                </IconButton>
              )}

              <Typography variant='h6' className={classes.title}>
                {drawerOpen ? '' : props.text}
              </Typography>

              {!props.hideAccountMenu && (
                <div>
                  <IconButton
                    aria-label='account of current user'
                    aria-controls='menu-appbar'
                    aria-haspopup='true'
                    onClick={handleMenu}
                    color='default'
                    className={classes.profileIcon}
                  >
                    <AccountCircle />
                  </IconButton>

                  <Menu
                    id='menu-appbar'
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={profileDropdownOpen}
                    onClose={handleClose}
                  >
                    {!isUserAnonymous() ? (
                      <div>
                        <MenuItem
                          component={Link}
                          to={routes.editProfile}
                          onClick={handleClose}
                        >
                          <ListItemIcon className={classes.icon}>
                            <PersonIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={t('navbar.edit.profile.link')}
                          />
                        </MenuItem>
                        <MenuItem
                          onClick={() => props.history.push(routes.logout)}
                        >
                          <ListItemIcon className={classes.icon}>
                            <LockIcon />
                          </ListItemIcon>
                          <ListItemText primary={t('navbar.logout.link')} />
                        </MenuItem>
                      </div>
                    ) : (
                      <MenuItem
                        onClick={() => props.history.push(routes.login)}
                      >
                        <ListItemIcon className={classes.icon}>
                          <LockIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('auth.login.submit')} />
                      </MenuItem>
                    )}
                  </Menu>
                </div>
              )}
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant='persistent'
            anchor='left'
            open={drawerOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <NavbarItemList close={handleDrawerClose} />
          </Drawer>
        </div>
      )}
    </>
  );
};

export default withRouter(React.memo(Navbar));

import * as React from "react";
import { withRouter, RouteComponentProps } from "react-router";
import ReactToPrint from "react-to-print";
import { useEffect, useRef } from "react";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { TSurveyRespondentId, TSurveysState } from "../../../types/Survey";
import { IAppState } from "../../../store/Store";
import {
  getAdminSurveyPrint,
  getSurveyPrint,
} from "../../../actions/SurveyActions";
import SurveyTranslation from "../../../components/survey/SurveyTranslation";

interface IComponentToPrintProps {
  data: string;
}
class ComponentToPrint extends React.Component<IComponentToPrintProps> {
  render() {
    return <div dangerouslySetInnerHTML={{ __html: this.props.data }} />;
  }
}

const SurveyPrintPage: React.FC<RouteComponentProps<any>> = (
  props
): JSX.Element => {
  const componentRef = useRef<any>();
  const query = new URLSearchParams(props.location.search);
  const respondentId = query.get(
    "respondentId"
  ) as unknown as TSurveyRespondentId;
  const surveyLanguage = query.get("lang");
  const dispatch = useDispatch();
  const surveysState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state) => state.surveysState
  );

  useEffect(() => {
    if (surveyLanguage || !respondentId) {
      dispatch(getAdminSurveyPrint(props.match.params.id, surveyLanguage));
    } else {
      dispatch(getSurveyPrint(respondentId));
    }
  }, [
    dispatch,
    respondentId,
    props.location.search,
    props.match.params.id,
    surveyLanguage,
  ]);

  return (
    <>
      <ReactToPrint
        trigger={() => (
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ margin: "15px 0" }}
          >
            {typeof surveysState.currentSurvey?.survey.surveyPrintButton ===
            "string" ? (
              surveysState.currentSurvey?.survey.surveyPrintButton
            ) : (
              <SurveyTranslation translate={"survey.print"} />
            )}
          </Button>
        )}
        content={() => componentRef.current}
      />
      {surveysState.surveyPrint && (
        <ComponentToPrint data={surveysState.surveyPrint} ref={componentRef} />
      )}
    </>
  );
};

export default withRouter(SurveyPrintPage);

import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import ImageUploader from '../ImageUploader';
import IconButton from '@material-ui/core/IconButton';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cameraButtonIcon: {
      fontSize: '62px',
      color: 'black',
    },
    cameraButton: {
      backgroundColor: 'white',
    },
    gridItem: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    fileGrid: {
      margin: theme.spacing(2, 0),
    },
  })
);

interface ICasePhotoSelectProps {
  handlePhotoUpload: (fileName: string, base64Content: string) => void;
  accept: string;
}

const CasePhotoSelect: React.FC<ICasePhotoSelectProps> = ({
  handlePhotoUpload,
  accept
}): JSX.Element => {
  const classes = useStyles();
  return (
    <Grid container className={classes.fileGrid}>
      <Grid item xs={12} className={classes.gridItem}>
        <ImageUploader handlePhotoUpload={handlePhotoUpload} accept={accept}>
          <IconButton className={classes.cameraButton}>
            <PhotoCameraIcon className={classes.cameraButtonIcon} />
          </IconButton>
        </ImageUploader>
      </Grid>
    </Grid>
  );
};

export default React.memo(CasePhotoSelect);

import * as React from "react";
import { FormEvent, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IdentificationForm from "../../../components/forms/survey/IdentificationForm";
import { useApp } from "../../../hooks/useApp";
import { StartSurvey, TSurveysState } from "../../../types/Survey";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/Store";
import { TApiUserLocation } from "../../../types/User";
import { TDispatch } from "../../../types/Thunk";
import { startSurvey } from "../../../actions/SurveyActions";
import { RouteComponentProps, withRouter } from "react-router";
import { reverse } from "named-urls";
import { routes } from "../../../Routes";
import { useCurrentActiveSurvey } from "../../../hooks/useCurrentActiveSurvey";
import SurveyTranslation from "../../../components/survey/SurveyTranslation";
import TextField from "@material-ui/core/TextField";
import { TAuthState } from "../../../types/Auth";
import { compose } from "redux";
import { withSurveyData } from "../../../components/hoc/requiresSurveyData";
import { head, isEmpty } from "lodash";
import { setInspectionLocation } from "../../../actions/InspectionActions";
import { StaticContext } from "react-router";

type LocationState = {
  language: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(24),
      textAlign: "center"
    }
  })
);

const InspectionIdentificationPage: React.FC<RouteComponentProps<
  any,
  StaticContext,
  LocationState
>> = (props): JSX.Element => {
  const classes = useStyles();
  const { app } = useApp();
  const dispatch = useDispatch<TDispatch>();
  const surveysState: TSurveysState = useSelector<IAppState, TSurveysState>(
    state => state.surveysState
  );
  const [error, setError] = useState(false);
  const { setRespondent, addToRespondentsStorage } = useCurrentActiveSurvey();

  const auth: TAuthState = useSelector<IAppState, TAuthState>(
    (state: IAppState) => state.authState
  );

  const [location, setLocation] = React.useState<TApiUserLocation>({
    id: 0,
    title: "",
    children: []
  });

  const goToCategory = () => {
    props.history.push(
      reverse(routes.surveyCategory, {
        id: surveysState.currentSurvey?.survey.id as number
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(surveysState.currentSurveyIdentification?.locations)) {
      setLocation(
        head(
          surveysState.currentSurveyIdentification?.locations
        ) as TApiUserLocation
      );
    }
  }, [surveysState.currentSurveyIdentification]);

  const submitIdentificationForm = (
    e: FormEvent,
    anonymous: boolean = false
  ) => {
    e.preventDefault();

    const surveyData = {
      surveyId: surveysState.currentSurvey!.survey.id,
      languageCode: props.location.state.language,
      respondentName: auth.data?.user?.name,
      departmentId: auth.data?.user?.department?.id,
      locationId: location?.id
    };

    dispatch(startSurvey(surveyData))
      .then((res: any) => {
        dispatch(setInspectionLocation(location?.id ?? 0));

        setRespondent(
          res.data.respondent,
          surveysState.currentSurvey!.survey.id,
          props.location.state.language
        );
        if (surveysState.currentSurvey?.survey.allowSavingProgress) {
          addToRespondentsStorage(
            surveysState.currentSurvey?.survey.id,
            res.data.respondent
          );
        }
        goToCategory();
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <>
      <Typography className={classes.heading}>
        {typeof surveysState.currentSurvey?.survey.segmentationHeader ===
        "string" ? (
          <>{surveysState.currentSurvey?.survey.segmentationHeader}</>
        ) : (
          <SurveyTranslation
            translate={"inspection.identification.before.begin.title"}
          />
        )}
      </Typography>
      <p>
        {typeof surveysState.currentSurvey?.survey.segmentationBody ===
        "string" ? (
          <>{surveysState.currentSurvey?.survey.segmentationBody}</>
        ) : (
          <SurveyTranslation
            translate={"inspection.identification.before.begin.description"}
          />
        )}
      </p>
      <TextField
        variant="outlined"
        disabled={true}
        margin="normal"
        fullWidth
        label={
          typeof surveysState.currentSurvey?.survey.segmentationId ===
          "string" ? (
            <>{surveysState.currentSurvey?.survey.segmentationId}</>
          ) : (
            <SurveyTranslation translate={"survey.identification.name"} />
          )
        }
        value={auth.data?.user?.name}
        type="text"
      />
      <IdentificationForm
        location={location}
        setLocation={setLocation}
        submitForm={submitIdentificationForm}
        survey={surveysState}
        errors={error && app.getError(StartSurvey)}
        segmentationContinueButton={
          typeof surveysState.currentSurvey?.survey
            .segmentationContinueButton === "string"
            ? surveysState.currentSurvey?.survey.segmentationContinueButton
            : null
        }
        segmentationId={
          typeof surveysState.currentSurvey?.survey.segmentationId === "string"
            ? surveysState.currentSurvey?.survey.segmentationId
            : null
        }
        selectSegmentationLocation={
          typeof surveysState.currentSurvey?.survey
            .selectSegmentationLocation === "string"
            ? surveysState.currentSurvey?.survey.selectSegmentationLocation
            : null
        }
        selectSegmentationOrganisation={
          typeof surveysState.currentSurvey?.survey
            .selectSegmentationOrganisation === "string"
            ? surveysState.currentSurvey?.survey.selectSegmentationOrganisation
            : null
        }
      />
    </>
  );
};

export default compose(
  withSurveyData,
  withRouter
)(InspectionIdentificationPage) as React.FC;

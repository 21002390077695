import * as React from 'react';
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom';
import UnauthorizedRoutes from './components/routes/UnauthorizedRoutes';
import AuthRoutes from './components/routes/AuthRoutes';
import { useEffect, useState } from 'react';
import { routes } from './Routes';
import ErrorPage from './components/ErrorPage';
import { useApp } from './hooks/useApp';

const AppRoutes: React.FC<RouteComponentProps> = (props): JSX.Element => {
  const [pageNotFound, setPageNotFound] = useState(true);
  const [pathsChecked, setPathsChecked] = useState(false);
  const { app } = useApp();

  useEffect(() => {
    for (const [, value] of Object.entries(routes)) {
      if (matchPath(props.location.pathname, { path: value, exact: true })) {
        setPageNotFound(false);
        setPathsChecked(true)
      }
    }
    //eslint-disable-next-line
  }, []);

  if (pageNotFound && !app.isLoading() && pathsChecked) {
    return <ErrorPage />;
  }

  return (
    <>
      <UnauthorizedRoutes />
      <AuthRoutes />
    </>
  );
};

export default withRouter(AppRoutes);

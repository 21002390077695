import * as React from "react";
import { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyStatistics } from "../../../actions/SurveyActions";
import { compose } from "redux";
import { withSurveyData } from "../../../components/hoc/requiresSurveyData";
import { TSurveysState } from "../../../types/Survey";
import { IAppState } from "../../../store/Store";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { TAuthState } from "../../../types/Auth";
import SurveyTranslation from "../../../components/survey/SurveyTranslation";
import Loader from "../../../components/Loader";
import { loadStoredRespondentState } from "../../../hooks/useCurrentActiveSurvey";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(32),
      textAlign: "center",
      fontWeight: "bold",
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    logoImage: {
      maxWidth: "45%",
      display: "flex",
      margin: "15px auto 50px auto",
    },
  })
);

interface ISurveyEndPagePrintButton extends RouteComponentProps<any> {
  surveyPrintButton: string;
}

const SurveySendAnswers: React.FC<ISurveyEndPagePrintButton> = (
  props
): JSX.Element => {
  const dispatch = useDispatch();
  const respondentIdentifier = loadStoredRespondentState();
  const surveysState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state) => state.surveysState
  );
  const classes = useStyles();
  const solutionLogo = `${window.location.origin}/logo`;
  const authState: TAuthState = useSelector<IAppState, TAuthState>(
    (state) => state.authState
  );

  if (!respondentIdentifier) {
    throw new Error(`No stored respondent`);
  }

  useEffect(() => {
    dispatch(getSurveyStatistics(respondentIdentifier.id));
  }, [dispatch, respondentIdentifier.id]);

  if (!surveysState.surveyStatistics) {
    return <Loader visible={true} />;
  }

  const surveyEndMessage = surveysState.currentSurvey?.survey.endText as string;
  const surveyEndMessageOptions = {
    company: surveysState.surveyStatistics?.solution.company,
    companyPercent: surveysState.surveyStatistics?.companyAverage,
    answers: surveysState.surveyStatistics?.responseCount,
    interpolation: {
      escapeValue: false,
    },
  };

  return (
    <>
      {authState.data?.user === null && solutionLogo && (
        <img src={solutionLogo} alt="logo" className={classes.logoImage} />
      )}
      {surveyEndMessage && (
        <p
          dangerouslySetInnerHTML={{
            __html: surveyEndMessage.replace(/\n/g, "<br />"),
          }}
        />
      )}
      {surveysState.currentSurvey?.survey.showResultToRespondent && (
        <>
          <Typography className={classes.heading}>
            <SurveyTranslation translate={"survey.end.your.result"} />
          </Typography>
          <Typography className={classes.heading}>
            {surveysState.surveyStatistics?.respondentResult}%
          </Typography>
          <p>
            <strong>
              <SurveyTranslation
                translate={"survey.end.company.average"}
                options={surveyEndMessageOptions}
              />
            </strong>
          </p>
          <p>
            <SurveyTranslation
              translate={"survey.end.page.survey.averages.number_1"}
            />
          </p>
          <p>
            <SurveyTranslation
              translate={"survey.end.page.survey.averages.number_2"}
            />
          </p>
          <p>
            <SurveyTranslation
              translate={"survey.end.page.survey.averages.number_3"}
            />
          </p>
        </>
      )}

      <Grid
        container
        spacing={1}
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <Grid item xs={12}>
          <Button
            fullWidth
            color="primary"
            className={classes.submit}
            component={Link}
            target={"_blank"}
            to={`/survey/${props.match.params.id}/print?respondentId=${respondentIdentifier.id}`}
          >
            {typeof surveysState.currentSurvey?.survey.surveyPrintButton ===
            "string" ? (
              <>{surveysState.currentSurvey?.survey.surveyPrintButton}</>
            ) : (
              <SurveyTranslation translate={"survey.end.button.print"} />
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default compose(
  withSurveyData,
  withRouter
)(SurveySendAnswers) as React.FC;

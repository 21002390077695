import * as React from 'react';
import { IAppState } from '../../store/Store';
import { connect } from 'react-redux';
import { TApiAuthEntity, TAuthState, TUserLoginAction } from '../../types/Auth';
import { RouteComponentProps, withRouter } from 'react-router';
import { isEmpty } from 'lodash';
import { bindActionCreators } from 'redux';
import { checkUserToken, loginAnonymousUser } from '../../actions/AuthActions';
import LoginPage from '../../containers/pages/auth/LoginPage';
import { SurveyType, TSurveysState } from '../../types/Survey';
import { TDispatch } from '../../types/Thunk';
import { AxiosResponse } from 'axios';
import Loader from '../Loader';
import { SurveyInspectionType } from '../../types/Content';

interface IStateProps {
  authState: TAuthState;
  surveyState: TSurveysState;
}

interface IDispatchProps {
  checkUserToken: () => (
    dispatch: TDispatch<TUserLoginAction>
  ) => Promise<AxiosResponse<TApiAuthEntity>>;
  loginAnonymousUser: () => any;
}

type IProps = IStateProps & IDispatchProps & RouteComponentProps<any>;

export const userIsAuthenticated = (auth: TAuthState) => {
  return (
    !isEmpty(auth) && auth.data !== undefined && localStorage.getItem('token')
  );
};

export const authenticated = (Component: React.ComponentType) => {
  const anonymousIsAllowed =
    window.location.pathname.includes("/survey/") ||
    window.location.pathname.includes("/page/");
  const currentUrl = window.location.pathname.replace('/app', '');

  class requiresAuthentication extends React.Component<IProps> {
    componentDidMount() {
      const { checkUserToken } = this.props;
      const query = new URLSearchParams(this.props.location.search);
      const unifiedKey = query.get('authenticate');
      if (
        localStorage.getItem('token') &&
        isEmpty(this.props.authState.data) &&
        !this.props.authState.loading &&
        !unifiedKey
      ) {
        try {
          checkUserToken();
        } catch ($e) {
          return window.location.reload();
        }
      }

      if (
        !localStorage.getItem('token') &&
        isEmpty(this.props.authState.data) &&
        !this.props.authState.loading &&
        !unifiedKey &&
        anonymousIsAllowed
      ) {
        this.handleAnonymousLogin();
      }
    }

    private async handleAnonymousLogin() {
      await this.props.loginAnonymousUser();
    }

    public render() {
      if (
        (this.props.surveyState?.currentSurvey?.survey.anonymity ===
          SurveyType.REQUIRES_LOGIN ||
          this.props.surveyState?.currentSurvey?.survey.surveyType ===
            SurveyInspectionType.INSPECTION) &&
        this.props.authState?.data?.user === null
      ) {
        localStorage.removeItem('token');
        return <LoginPage fallbackUrl={this.props.location.pathname} />;
      }

      if (this.props.authState.loading) {
        return <Loader visible={true} />;
      }

      if (
        this.props.authState.data?.user === null &&
        !anonymousIsAllowed
      ) {
        localStorage.removeItem('token');
        return <LoginPage fallbackUrl={this.props.location.pathname} />;
      }

      return userIsAuthenticated(this.props.authState) ? (
        <Component />
      ) : (
        <>
          {!this.props.location.pathname.includes('/auth') && (
            <LoginPage fallbackUrl={currentUrl} />
          )}
        </>
      );
    }
  }

  const mapStateToProps = (state: IAppState): IStateProps => {
    return {
      authState: state.authState,
      surveyState: state.surveysState,
    };
  };

  const mapDispatchToProps = (dispatch: any): IDispatchProps => {
    return bindActionCreators({ checkUserToken, loginAnonymousUser }, dispatch);
  };

  return withRouter(
    connect(mapStateToProps, mapDispatchToProps)(requiresAuthentication)
  );
};

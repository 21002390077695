import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { deepOrange } from "@material-ui/core/colors";

interface Props {
  message: string;
  timestamp: string;
  displayName: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageRow: {
      display: "flex",
      flexDirection: "column",
      minWidth: "30%"
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
      flexDirection: "column",
      alignItems: "flex-end"
    },
    messageBlue: {
      position: "relative",
      marginLeft: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: theme.palette.primary.light,
      width: "60%",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #97C6E3",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: `15px solid ${theme.palette.primary.light}`,
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        left: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: `17px solid ${theme.palette.primary.light}`,
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px"
      }
    },
    messageOrange: {
      position: "relative",
      marginRight: "20px",
      marginBottom: "10px",
      padding: "10px",
      backgroundColor: theme.palette.secondary.light,
      width: "60%",
      textAlign: "left",
      font: "400 .9em 'Open Sans', sans-serif",
      border: "1px solid #dfd087",
      borderRadius: "10px",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: `15px solid ${theme.palette.secondary.light}`,
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px"
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: `17px solid ${theme.palette.secondary.light}`,
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        right: "-17px"
      }
    },
    messageContent: {
      padding: 0,
      margin: theme.spacing(0, 0, 1.5)
    },
    messageTimeStampRight: {
      position: "absolute",
      fontSize: ".85em",
      fontWeight: 300,
      marginTop: "10px",
      bottom: "5px",
      right: "5px"
    },
    orange: {
      color: theme.palette.getContrastText(deepOrange[500]),
      backgroundColor: deepOrange[500],
      width: theme.spacing(4),
      height: theme.spacing(4)
    },
    displayName: {
      color: theme.palette.grey[600],
      marginLeft: "20px"
    },
    displayNameRight: {
      color: theme.palette.grey[600],
      textAlign: "right",
      marginRight: "20px"
    }
  })
);

export const MessageLeft: React.FC<Props> = props => {
  const message = props.message ? props.message : "-";
  const timestamp = props.timestamp ? props.timestamp : "";
  const displayName = props.displayName ? props.displayName : "";

  const classes = useStyles();

  return (
    <div className={classes.messageRow}>
      <div className={classes.displayName}>{displayName}</div>
      <div className={classes.messageBlue}>
        <div>
          <p className={classes.messageContent}>{message}</p>
        </div>
        <div className={classes.messageTimeStampRight}>{timestamp}</div>
      </div>
    </div>
  );
};

export const MessageRight: React.FC<Props> = props => {
  const classes = useStyles();
  const message = props.message ? props.message : "-";
  const timestamp = props.timestamp ? props.timestamp : "";
  const displayName = props.displayName ? props.displayName : "";

  return (
    <div className={classes.messageRowRight}>
      <div className={classes.displayNameRight}>{displayName}</div>
      <div className={classes.messageOrange}>
        <p className={classes.messageContent}>{message}</p>
        <div className={classes.messageTimeStampRight}>{timestamp}</div>
      </div>
    </div>
  );
};

import { createTheme, ThemeOptions } from '@material-ui/core';

export const OSHURETheme: ThemeOptions = createTheme({
  palette: {
    primary: {
      main: '#3cb0fd',
      contrastText: '#ffffff',
    },
    secondary: {
      main: 'rgb(141, 198, 63)',
    },
    background: {
      default: '#ffffff',
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: 'white',
      },
      containedSecondary: {
        color: 'white',
      },
    },
    MuiDivider: {
      root: {
        color: 'black',
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        margin: '16px 0',
      },
    },
  },
});

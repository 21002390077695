import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useApiClient } from "../../../../shared/hooks/useApiClient";
import { whistleblowerKeys } from "../utils/whistleblowerKeys";
import { IWhistleblowerApiEntity } from "../types/Whistleblower";

export interface IWhistleblowerRequest {
  code: string;
  recaptcha: string;
}

export const useWhistleblowerSearch = () => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  const searchWhistleblower = async (
    data: IWhistleblowerRequest
  ): Promise<AxiosResponse<IWhistleblowerApiEntity>> => {
    return await apiClient.post<IWhistleblowerApiEntity>(
      "/whistleblower/search",
      {
        ...data
      }
    );
  };

  return useMutation(searchWhistleblower, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(whistleblowerKeys.all);
    }
  });
};

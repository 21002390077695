import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import PageContent from '../../components/PageContent';

const CmsPage: React.FC<RouteComponentProps<any>> = (props): JSX.Element => {
  return (
    <>
      <PageContent pageId={props.match.params.id} pageType={null} />
    </>
  );
};

export default withRouter(CmsPage);

import * as React from 'react';
import Expansion from '../../cms/Expansion';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';

interface IRevisedDateProps {
  bodyText: string;
  expanded: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const RevisedDate: React.FC<IRevisedDateProps> = ({
  bodyText,
  expanded,
  handleChange,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Expansion
      headline={t('chemicals.revision_information')}
      bodyText={bodyText}
      index={6}
      expanded={expanded ? `panel6` : false}
      handleChange={handleChange}
    />
  );
};

export default RevisedDate;

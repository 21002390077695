import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import HelpIcon from '@material-ui/icons/Help';
import { TApiQuestionEntity } from '../../types/Survey';

interface IQuestionIconsProps {
  question: TApiQuestionEntity;
  setModalOpen: (value: React.SetStateAction<boolean>) => void;
}

const QuestionIcons: React.FC<IQuestionIconsProps> = ({
  question,
  setModalOpen,
}): JSX.Element => {
  return (
    <Grid container spacing={1} style={{ justifyContent: 'flex-end' }}>
      {question.helpText && (
        <Grid item xs={1}>
          <HelpIcon onClick={() => setModalOpen(true)} />
        </Grid>
      )}
    </Grid>
  );
};

export default QuestionIcons;

import * as React from 'react';
import { TApiChemicalProductEntity } from '../../../types/Chemical';
import { ChangeEvent } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ChemicalList from '../../chemicals/ChemicalList';

interface IChemicalProps {
  chemicals: Array<TApiChemicalProductEntity>;
  expanded: boolean;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
  })
);

const Chemical: React.FC<IChemicalProps> = ({
  chemicals,
  expanded,
  handleChange,
}): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleChange('expandedChemical')}
      key={'expandedChemical'}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`expandedChemicalnbh-content`}
        id={`expandedChemicalbh-header`}
      >
        <Typography className={classes.heading}>{t("process.chemicals")}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={classes.list}>
        <div className={classes.root}>
          <ChemicalList chemicals={chemicals} />
        </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
}

export default Chemical;

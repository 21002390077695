import * as React from 'react';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { ChangeEvent } from 'react';

interface IExpansionProps {
  headline: string;
  bodyText: string;
  index: number;
  expanded: string | false;
  handleChange: (
    panel: string
  ) => ((event: ChangeEvent<{}>, expanded: boolean) => void) | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '80%',
      flexShrink: 0,
    },
  })
);

const Expansion: React.FC<IExpansionProps> = ({
  headline,
  bodyText,
  index,
  expanded,
  handleChange,
}): JSX.Element => {
  const classes = useStyles();

  return (
    <ExpansionPanel
      expanded={expanded === `panel${index}`}
      onChange={handleChange(`panel${index}`)}
      key={index}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${index}bh-content`}
        id={`panel${index}bh-header`}
      >
        <Typography
          className={classes.heading}
          dangerouslySetInnerHTML={{ __html: headline }}
        />
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography dangerouslySetInnerHTML={{ __html: bodyText }} />
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default Expansion;

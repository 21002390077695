import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { FormEvent } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AlertBlock from '../../AlertBlock';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { routes } from '../../../Routes';

interface ILoginFormProps {
  email: string;
  setEmail: (value: React.SetStateAction<string>) => void;
  submitForm: (e: FormEvent) => void;
  errors: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  forgotLink: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginRight: '20px',
  },
}));

const PasswordRemindForm: React.FC<ILoginFormProps> = (props): JSX.Element => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      {props.errors && (
        <AlertBlock hideCloseButton={true} type={'error'}>
          {props.errors.message}
        </AlertBlock>
      )}
      <form className={classes.form} noValidate onSubmit={props.submitForm}>
        <TextField
          variant='outlined'
          margin='normal'
          required
          fullWidth
          id='email'
          label={t('auth.remind.label')}
          name='email'
          autoComplete='email'
          autoFocus
          value={props.email}
          type={'email'}
          error={!!props?.errors?.fields?.emailAddress}
          helperText={
            props?.errors?.fields?.emailAddress || t('auth.remind.helperText')
          }
          onChange={(e) => props.setEmail(e.target.value)}
        />
        <Grid container>
          <Grid item xs className={classes.forgotLink}>
            <Link to={routes.login}>{t('auth.login.link')}</Link>
          </Grid>
          <Grid item xs>
            <Button
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              className={classes.submit}
            >
              {t('auth.remind.submit')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default PasswordRemindForm;

import { Reducer } from 'redux';
import { endReducer } from '../store/Store';
import {
  GetCustomPages,
  GetPageContent,
  initialContentState,
  SetCurrentPageTitle,
  TContentActions,
  TContentState,
} from '../types/Content';

export const contentReducer: Reducer<TContentState, TContentActions> = (
  state = initialContentState,
  action
) => {
  switch (action.type) {
    case GetPageContent.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetPageContent.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data.menuItem,
      };

    case GetPageContent.FAILED:
      return state;

    case GetCustomPages.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetCustomPages.SUCCESS:
      return {
        ...state,
        customPages: action.payload.data.items,
      };

    case GetCustomPages.FAILED:
      return state;

    case SetCurrentPageTitle.SUCCESS:
      return {
        ...state,
        currentPageTitle: action.payload,
      };

    case SetCurrentPageTitle.FAILED:
      return state;

    default:
      return endReducer(state, action);
  }
};

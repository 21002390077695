import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Separator from '../Separator';

interface ICategoryDescriptionProps {
  mainCategoryTitle: string;
  mainCategoryDescription: string;
  subCategoryTitle?: string;
  subCategoryDescription?: string;
  hideMainCategory?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginBottom: '30px',
    },
    mainTitle: {
      fontSize: '28px',
      fontWeight: 'bold',
      textAlign: 'left',
    },
    subTitle: {
      fontSize: '22px',
      fontWeight: 'bold',
      textAlign: 'left',
    },
  })
);

const CategoryDescription: React.FC<ICategoryDescriptionProps> = (
  props
): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {!props.hideMainCategory && (
        <>
          <Typography className={classes.mainTitle} component={'h1'}>
            {props.mainCategoryTitle}
          </Typography>
          <Typography
            component={'p'}
            dangerouslySetInnerHTML={{
              __html: props.mainCategoryDescription.replace(/\n/g, '<br />'),
            }}
          />
        </>
      )}

      {!props.hideMainCategory &&
        props.mainCategoryTitle &&
        props.subCategoryTitle && <Separator />}

      <Typography
        className={classes.subTitle}
        component={props.hideMainCategory ? 'h1' : 'h2'}
      >
        {props.subCategoryTitle}
      </Typography>
      {props.subCategoryDescription && (
        <Typography
          component={'p'}
          dangerouslySetInnerHTML={{
            __html: props.subCategoryDescription.replace(/\n/g, '<br />'),
          }}
        />
      )}
    </div>
  );
};

export default CategoryDescription;

import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { TApiActionPlanEntity } from '../../types/Actionplan';
import ActionPlanAccordion from './ActionPlanAccordion';
import ActionPlanDetails from './ActionPlanDetails';
import AlertBlock from '../AlertBlock';
import { fetchWithAuthentication } from '../../actions/BaseAction';
import { useState } from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DescriptionIcon from '@material-ui/icons/Description';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
  })
);

interface IActionPlanItemProps {
  actionPlan: TApiActionPlanEntity;
}

const ActionPlanItem: React.FC<IActionPlanItemProps> = ({
  actionPlan,
}): JSX.Element => {
  const [urlCache, setUrlCache] = useState<{[key: string]: string}>({});
  const classes = useStyles();

  const handleFileClick = (filePath: string) => () => {
    if (urlCache[filePath]) {
      const cachedBlob = urlCache[filePath];

      window.open(cachedBlob, '_blank');

      return;
    }

    fetchWithAuthentication(filePath)
      .then(blob => {
        if (blob !== null) {
          const blobUrl = URL.createObjectURL(blob);

          setUrlCache((prevCache) => ({ ...prevCache, [filePath]: blobUrl }));

          window.open(blobUrl, '_blank');
        }
      })
      .catch(error => {throw  error});
  }

  return (
    <div className={classes.root}>
      {actionPlan && (
        <>
          {actionPlan.news && (
            <AlertBlock type={'info'} hideCloseButton={true}>
              <div dangerouslySetInnerHTML={{ __html: actionPlan.news }} />
            </AlertBlock>
          )}
          <ActionPlanAccordion
            problem={actionPlan.problem}
            consequence={actionPlan.consequence}
            solution={actionPlan.solution}
          />
          {actionPlan.actionplanFiles && Object.values(actionPlan.actionplanFiles).map((file) => (
            <ListItem
              key={file.id}
              role={undefined}
              dense
              button
              onClick={handleFileClick(file.media)}
            >
              <ListItemIcon>
                <DescriptionIcon/>
              </ListItemIcon>
              <ListItemText
                primary={file.title}
                secondary={file.notes ? file.notes : null}
              />
            </ListItem>
          ))}
          <ActionPlanDetails actionPlan={actionPlan} />
        </>
      )}
    </div>
  );
};

export default ActionPlanItem;

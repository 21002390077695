import * as React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import { useChemicalProductStyles } from '../ChemicalProduct.styles';
import { TApiChemicalProductHazard } from '../../../types/Chemical';

interface IProtectiveMeasurements {
  text: string;
  ppe: Array<TApiChemicalProductHazard>;
}

const ProtectiveMeasurements: React.FC<IProtectiveMeasurements> = ({
  text,
  ppe,
}): JSX.Element => {
  const classes = useChemicalProductStyles();

  return (
    <div className={classes.protectiveMeasurementsContainer}>
      {ppe.map((e) => (
        <ListItem>
          <ListItemAvatar>
            <Avatar className={classes.hazardIcon}>
              <img src={e.image} alt={e.label} />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={e.label} secondary={e.description} />
        </ListItem>
      ))}

      <div
        className={classes.protectiveMeasurementsContainerText}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

export default ProtectiveMeasurements;

export interface IApp {
  loading: boolean;
  loaded: boolean;
  error: boolean | string[];
}

export enum ClearStoreState {
  SUBMIT = 'CLEAR_STORE_STATE_SUBMIT',
}

export type TClearStoreStateAction = {
  type: ClearStoreState.SUBMIT;
};

export type TAppError = string | string[];

import * as React from "react";
import * as Sentry from "@sentry/browser";
import ErrorPage from "./components/ErrorPage";

interface IErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
  eventId: any;
}

class ErrorBoundary extends React.Component<{}, IErrorBoundaryState> {
  constructor(props: any) {
    super(props);

    this.state = {
      hasError: false,
      errorMessage: "",
      eventId: null
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    this.setState({
      errorMessage: error.message
    });
    Sentry.withScope(scope => {
      scope.setExtras((errorInfo as unknown) as Record<string, unknown>);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage message={this.state.errorMessage} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Expansion from '../cms/Expansion';

interface IActionPlanAccordionProps {
  problem: string | undefined;
  consequence: string | undefined;
  solution: string | undefined;
}

const ActionPlanAccordion: React.FC<IActionPlanAccordionProps> = ({
  problem,
  consequence,
  solution,
}): JSX.Element => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <>
      {problem && (
        <Expansion
          headline={t('actionplan.problem')}
          bodyText={problem as string}
          index={1}
          expanded={expanded}
          handleChange={handleChange}
        />
      )}

      {consequence && (
        <Expansion
          headline={t('actionplan.consequence')}
          bodyText={consequence as string}
          index={2}
          expanded={expanded}
          handleChange={handleChange}
        />
      )}

      {solution && (
        <Expansion
          headline={t('actionplan.solution')}
          bodyText={solution as string}
          index={3}
          expanded={expanded}
          handleChange={handleChange}
        />
      )}
    </>
  );
};

export default ActionPlanAccordion;

import * as React from 'react';
import { ActionPlanStatus, TApiActionPlanEntity } from '../../types/Actionplan';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { simpleDateFormat } from '../../Helpers';
import { isEmpty } from 'lodash';
import { getActionPlanStyling } from '../../containers/pages/actionplans/ActionPlanListPage';

interface IActionPlanListProps {
  actionPlans: Array<TApiActionPlanEntity>;
  onSelect: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    icon: {
      backgroundColor: 'white',
    },
  })
);

const ActionPlanList: React.FC<IActionPlanListProps> = (props): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (isEmpty(props.actionPlans)) {
    return <>{t('list.is.empty')}</>;
  }

  return (
    <List className={classes.root}>
      {props.actionPlans.map((actionPlan: TApiActionPlanEntity) => {
        const secondaryTitle = `${t(actionPlan.statusLabel)} - ${t(
          'actionplan.endDate'
        )} ${simpleDateFormat(actionPlan.endDate)}`;

        return (
          <ListItem
            button
            key={actionPlan.id}
            onClick={() => props.onSelect(actionPlan.id)}
          >
            <ListItemAvatar>
              <Avatar
                className={classes.icon}
                style={{
                  color: getActionPlanStyling(
                    actionPlan.status as ActionPlanStatus
                  ).color,
                }}
              >
                {
                  getActionPlanStyling(actionPlan.status as ActionPlanStatus)
                    .icon
                }
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={actionPlan.name}
              secondary={secondaryTitle}
            />
          </ListItem>
        );
      })}
    </List>
  );
};

export default ActionPlanList;

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { TDispatch } from '../../../types/Thunk';
import { logoutUser } from '../../../actions/AuthActions';
import { withRouter, RouteComponentProps } from 'react-router';

const LogoutPage: React.FC<RouteComponentProps<any>> = (): JSX.Element => {
  const dispatch = useDispatch<TDispatch>();

  React.useEffect(() => {
    dispatch(logoutUser()).then(() => {
      window.location.replace('/app');
    });
    //eslint-disable-next-line
  }, []);

  return <>Loading</>;
};

export default withRouter(LogoutPage);

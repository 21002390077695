import { AxiosError, AxiosResponse } from 'axios';

export interface TMeetingState {
  readonly loading?: boolean;
  readonly list?: Array<TApiMeetingShortEntity>;
  readonly filteredList?: Array<TApiMeetingShortEntity>;
  readonly searchOrganisation?: string;
  readonly activeMeeting?: TApiMeetingEntity;
}

export const initialMeetingState: TMeetingState = {
  loading: false,
  list: undefined,
  activeMeeting: undefined,
};

export enum GetMeetingsList {
  REQUEST = 'GET_MEETINGS_LIST_REQUEST',
  SUCCESS = 'GET_MEETINGS_LIST_SUCCESS',
  FAILED = 'GET_MEETINGS_LIST_FAILED',
}

export type TGetMeetingsListAction =
  | {
      type: GetMeetingsList.REQUEST;
    }
  | {
      type: GetMeetingsList.SUCCESS;
      payload: AxiosResponse<{
        meetings: Array<TApiMeetingShortEntity>;
      }>;
    }
  | {
      type: GetMeetingsList.FAILED;
      payload: AxiosError;
    };

export type TMeetingActions =
  | TGetMeetingsListAction
  | TFilterMeetingsListAction
  | TGetMeetingAction;

export type TApiMeetingShortEntity = {
  id: number;
  name: string;
  enabled: boolean;
  startTime: Date;
  organisations: Array<string>;
};

export type TApiMeetingEntity = {
  meeting: {
    id: number;
    name: string;
    enabled: boolean;
    startTime: Date;
    organisations: Array<string>;
    organizer: string;
    attendees: Array<{
      name: string;
      title: string;
    }>;
  };
  agenda: Array<{
    name: string;
    description: string;
    minutes: string;
  }> | null;
  file: string | null;
};

export enum FilterMeetingsList {
  SUBMIT = 'FILTER_MEETINGS_LIST',
}

export enum GetMeeting {
  REQUEST = 'GET_MEETING_REQUEST',
  SUCCESS = 'GET_MEETING_SUCCESS',
  FAILED = 'GET_MEETING_FAILED',
}

export type TGetMeetingAction =
  | {
      type: GetMeeting.REQUEST;
    }
  | {
      type: GetMeeting.SUCCESS;
      payload: AxiosResponse<TApiMeetingEntity>;
    }
  | {
      type: GetMeeting.FAILED;
      payload: AxiosError;
    };

export type TFilterMeetingsListActionPayload = {
  organisation: string;
};

export type TFilterMeetingsListAction = {
  type: FilterMeetingsList.SUBMIT;
  payload: TFilterMeetingsListActionPayload;
};

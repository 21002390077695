import { Reducer } from 'redux';
import { endReducer } from '../store/Store';
import { SetAppError, TErrorActions, TErrorState } from '../types/Error';
import { AnonymousUserLogin, TAuthActions, UserLogin } from '../types/Auth';

export const errorReducer: Reducer<
  TErrorState,
  TErrorActions | TAuthActions
> = (state = {}, action) => {
  switch (action.type) {
    case SetAppError.SUCCESS:
      return action.payload;

    case AnonymousUserLogin.REQUEST:
      return {};

    case UserLogin.REQUEST:
      return {};

    default:
      return endReducer(state, action);
  }
};

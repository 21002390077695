import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { useApiClient } from "../../../../shared/hooks/useApiClient";
import { whistleblowerKeys } from "../utils/whistleblowerKeys";

export interface IWhistleblowerCreateRequest {
  problemDescription: string;
  photos: Array<number>;
  recaptcha: string;
}

export interface IWhistleblowerCreateResponse {
  caseId: number;
  code: string;
}

export const useWhistleblowerCreate = () => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  const createWhistleBlower = async (
    data: IWhistleblowerCreateRequest
  ): Promise<AxiosResponse<IWhistleblowerCreateResponse>> => {
    return await apiClient.post<IWhistleblowerCreateResponse>(
      "/whistleblower",
      {
        ...data
      }
    );
  };

  return useMutation(createWhistleBlower, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(whistleblowerKeys.all);
    }
  });
};

import { Reducer } from 'redux';
import { endReducer } from '../store/Store';
import {
  ClearSurveyResponses,
  GetAdminSurveyPrint,
  GetSurvey,
  GetSurveyIdentificationInfo,
  GetSurveyList,
  GetSurveyPrint,
  GetSurveyResponses,
  GetSurveyStatistics,
  GetSurveyStructure,
  initialSurveysState,
  ISurveySequence,
  StartSurvey, SurveyCategoryComment,
  SurveyRespond,
  TSurveyActions,
  TSurveysState,
} from '../types/Survey';

export const surveyReducer: Reducer<TSurveysState, TSurveyActions> = (
  state = initialSurveysState,
  action
) => {
  switch (action.type) {
    case GetSurveyList.REQUEST:
      return {
        loading: true,
      };

    case GetSurveyList.SUCCESS:
      return {
        loading: false,
        data: action.payload.data.surveys,
      };

    case GetSurveyList.FAILED:
      return state;

    case GetSurvey.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetSurvey.SUCCESS:
      return {
        ...state,
        loading: false,
        currentSurvey: action.payload.data,
      };

    case GetSurvey.FAILED:
      return state;

    case GetSurveyStructure.REQUEST:
      return { ...state, loading: true };

    case GetSurveyStructure.SUCCESS:
      let total: any = [];
      action.payload.data.categories.forEach((category) => {
        if (category.questions) {
          category.questions.map((question) => {
            return total.push(question.id);
          });
        }

        if (category.subCategories) {
          category.subCategories.map((subCategory) => {
            return subCategory.questions.map((question) => {
              return total.push(question.id);
            });
          });
        }
      });

      return {
        ...state,
        loading: false,
        surveySequence: action.payload.data.categories.map((category) => {
          let o: ISurveySequence = { data: category };

          if (category.questions.length > 0) {
            o = { ...o, questions: category.questions };
          }

          if (category.subCategories && category.subCategories.length > 0) {
            const sub = category.subCategories.map((subCategory) => {
              let s: ISurveySequence = {
                data: subCategory,
              };
              return {
                ...s,
                questions: subCategory.questions,
              };
            });
            o = { ...o, subCategories: sub };
          }

          if (category.subCategories && category.subCategories.length > 0) {
            category.subCategories.map((subCategory) => {
              let s: ISurveySequence = { data: subCategory };

              if (subCategory.questions) {
                s = {
                  ...s,
                  questions: subCategory.questions,
                };
              }

              return { ...o, subCategories: s };
            });
          }

          return o;
        }),
        questionsList: total,
        skipLogic: action.payload.data.skipLogic ?? [],
      };

    case GetSurveyStructure.FAILED:
      return { ...state, loading: false };

    case StartSurvey.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case StartSurvey.SUCCESS:
      return {
        ...state,
        respondent: action.payload.data.respondent,
      };

    case StartSurvey.FAILED:
      return state;

    case GetSurveyResponses.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetSurveyResponses.SUCCESS:
      return {
        ...state,
        responses: action.payload.data,
      };

    case GetSurveyResponses.FAILED:
      return state;

    case ClearSurveyResponses.CLEAR:
      return {
        ...state,
        responses: undefined,
      };

    case GetSurveyIdentificationInfo.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetSurveyIdentificationInfo.SUCCESS:
      return {
        ...state,
        loading: false,
        currentSurveyIdentification: action.payload.data,
      };

    case GetSurveyIdentificationInfo.FAILED:
      return state;

    case GetSurveyStatistics.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetSurveyStatistics.SUCCESS:
      return {
        ...state,
        loading: false,
        surveyStatistics: action.payload.data,
      };

    case GetSurveyStatistics.FAILED:
      return state;

    case GetSurveyPrint.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GetSurveyPrint.SUCCESS:
      return {
        ...state,
        loading: false,
        surveyPrint: action.payload.data,
      };

    case GetSurveyPrint.FAILED:
      return state;

    case GetAdminSurveyPrint.SUCCESS:
      return {
        ...state,
        loading: false,
        surveyPrint: action.payload.data,
      };

    case GetAdminSurveyPrint.FAILED:
      return state;

    case SurveyRespond.REQUEST:
      return {
        ...state,
        loading: true,
      };

    case SurveyRespond.SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case SurveyRespond.FAILED:
      return state;

    case SurveyCategoryComment.SUCCESS:
      return {
        ...state,
        responses: {
          answers: state.responses ? [...state.responses?.answers] : [],
          categoryComments: state.responses ? [...state.responses.categoryComments.filter(({categoryId}) => categoryId !== action.payload.data.categoryId), action.payload.data] : []
        }
      };

    default:
      return endReducer(state, action);
  }
};

import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useProcessListStyles } from '../../../components/processes/ProcessList.styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TProcessState } from '../../../types/Process';
import { IAppState } from '../../../store/Store';
import { filterProcessList, getCategories, getProcessList } from '../../../actions/ProcessActions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PageContent from '../../../components/PageContent';
import { ContentPage } from '../../../types/Content';
import CategoryDropdown from '../../../components/processes/CategoryDropdown';
import { debounce } from 'lodash';
import TextField from '@material-ui/core/TextField';
import ProcessList from '../../../components/processes/ProcessList';


const ProcessListPage: React.FC<RouteComponentProps<any>> = (props): JSX.Element => {
  const classes = useProcessListStyles();
  const {t} = useTranslation();
  const [category, setCategory] = useState('');
  const [searchValue, setSearchValue] = useState<string>("");

  const dispatch = useDispatch();
  const processState: TProcessState = useSelector<
    IAppState,
    TProcessState
  >((state) => state.processState);

  useEffect(() => {
    dispatch(getCategories());
    dispatch(getProcessList());
  }, [dispatch]);

  const handleCategoryChange = (
    event: React.ChangeEvent<{ value: unknown}>
  ) => {
    setCategory(event.target.value as string);
    dispatch(getProcessList(event.target.value as number));
  };

  const filterList = useMemo(
    () => debounce((search: string) => {
      dispatch(filterProcessList(search))
    }, 100),
    [dispatch]
  );

  return (
    <>
      <PageContent pageType={ContentPage.PROCESSES} showAsParagraph={true} />
      {processState.categories && (
        <CategoryDropdown
          category={category}
          handleCategoryChange={handleCategoryChange}
          categories={processState.categories}
        />
      )}
      <TextField
        value={searchValue}
        label={t("process.search.label")}
        className={classes.searchField}
        onChange={e => {
          filterList(e.target.value);
          setSearchValue(() => e.target.value as string);
        }}
        />
      <div className={classes.root}>
        <ProcessList processes={processState.filteredList} />
      </div>
    </>
  );


}

export default withRouter(ProcessListPage);
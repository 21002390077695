import * as React from "react";
import { useState } from "react";
import { Prompt, RouteComponentProps, withRouter } from "react-router";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import { TDispatch } from "../../../types/Thunk";
import { sendSurveyAnswers } from "../../../actions/SurveyActions";
import { reverse } from "named-urls";
import { routes } from "../../../Routes";
import { Beforeunload } from "react-beforeunload";
import { TSurveysState } from "../../../types/Survey";
import SurveyTranslation from "../../../components/survey/SurveyTranslation";
import {
  loadStoredRespondentState,
  useCurrentActiveSurvey,
} from "../../../hooks/useCurrentActiveSurvey";
import { IAppState } from "../../../store/Store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      marginTop: theme.spacing(6),
      alignItems: "center",
      flexDirection: "column",
    },
    icon: {
      fontSize: "60px",
      marginBottom: theme.spacing(3),
    },
    submit: {
      marginTop: theme.spacing(3),
    },
  })
);

const SurveyEndPage: React.FC<RouteComponentProps<any>> = (
  props
): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch<TDispatch>();
  const respondentIdentifier = loadStoredRespondentState();
  const [submitted, setSubmitted] = useState(false);
  const surveysState: TSurveysState = useSelector<IAppState, TSurveysState>(
    (state) => state.surveysState
  );
  const { isInspection, removeFromRespondentsStorage, addCompletedSurvey } =
    useCurrentActiveSurvey();

  const handleAnswerSubmit = () => {
    if (!respondentIdentifier) {
      throw new Error(`No stored respondent`);
    }

    dispatch(sendSurveyAnswers(respondentIdentifier.id)).then(() => {
      setSubmitted(true);
      removeFromRespondentsStorage(props.match.params.id);
      addCompletedSurvey(props.match.params.id, respondentIdentifier.id);
      props.history.push(
        reverse(routes.surveyEndPage, {
          id: props.match.params.id,
        })
      );
    });
  };
  return (
    <Beforeunload
      onBeforeunload={() => (
        <SurveyTranslation
          translate={"survey.send.answers.leave.before.save.message"}
        />
      )}
    >
      <div className={classes.root}>
        <Prompt
          when={!submitted}
          message={t("survey.send.answers.leave.before.save.message")}
        />
        <i className={`${classes.icon} fas fa-flag-checkered`} />
        <h2>
          {typeof surveysState.currentSurvey?.survey.surveySubmitHeader ===
          "string" ? (
            <>{surveysState.currentSurvey?.survey.surveySubmitHeader}</>
          ) : (
            <SurveyTranslation translate={"survey.send.answers.page.intro"} />
          )}
        </h2>
        <p style={{ textAlign: "center", marginBottom: "30px" }}>
          {typeof surveysState.currentSurvey?.survey.surveySubmitBody ===
          "string" ? (
            <>{surveysState.currentSurvey?.survey.surveySubmitBody}</>
          ) : (
            <SurveyTranslation
              translate={"survey.send.answers.page.description"}
            />
          )}
        </p>
        <Grid container justifyContent="space-around">
          <Grid item xs={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              onClick={handleAnswerSubmit}
            >
              {typeof surveysState.currentSurvey?.survey.surveySubmitButton ===
              "string" ? (
                <>{surveysState.currentSurvey?.survey.surveySubmitButton}</>
              ) : (
                <SurveyTranslation
                  translate={
                    isInspection
                      ? "inspection.send.answers.button"
                      : "survey.send.answers.button"
                  }
                />
              )}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Beforeunload>
  );
};

export default withRouter(SurveyEndPage);

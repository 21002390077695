import * as React from "react";
import { FormEvent, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import IdentificationForm from "../../../components/forms/survey/IdentificationForm";
import { useApp } from "../../../hooks/useApp";
import { StartSurvey, TSurveysState } from "../../../types/Survey";
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../../store/Store";
import { TApiUserDepartment, TApiUserLocation } from "../../../types/User";
import { withSurveyData } from "../../../components/hoc/requiresSurveyData";
import { TDispatch } from "../../../types/Thunk";
import { startSurvey } from "../../../actions/SurveyActions";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "redux";
import { reverse } from "named-urls";
import { routes } from "../../../Routes";
import { useCurrentActiveSurvey } from "../../../hooks/useCurrentActiveSurvey";
import { isEmpty, head } from "lodash";
import SurveyTranslation from "../../../components/survey/SurveyTranslation";
import { StaticContext } from "react-router";

type LocationState = {
  language: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      fontSize: theme.typography.pxToRem(24),
      textAlign: "center"
    }
  })
);

const SurveyIdentificationPage: React.FC<RouteComponentProps<
  any,
  StaticContext,
  LocationState
>> = (props): JSX.Element => {
  const classes = useStyles();
  const { app } = useApp();
  const dispatch = useDispatch<TDispatch>();
  const surveysState: TSurveysState = useSelector<IAppState, TSurveysState>(
    state => state.surveysState
  );
  const [error, setError] = useState(false);
  const { setRespondent, addToRespondentsStorage } = useCurrentActiveSurvey();

  const [name, setName] = React.useState("");
  const [organization, setOrganization] = React.useState<TApiUserDepartment>({
    id: 0,
    title: "",
    children: []
  });
  const [location, setLocation] = React.useState<TApiUserLocation>({
    id: 0,
    title: "",
    children: []
  });

  const goToCategory = () => {
    props.history.push(
      reverse(routes.surveyCategory, {
        id: surveysState.currentSurvey?.survey.id as number
      })
    );
  };

  useEffect(() => {
    if (!isEmpty(surveysState.currentSurveyIdentification?.locations)) {
      setLocation(
        head(
          surveysState.currentSurveyIdentification?.locations
        ) as TApiUserLocation
      );
    }
    if (!isEmpty(surveysState.currentSurveyIdentification?.departments)) {
      setOrganization(
        head(
          surveysState.currentSurveyIdentification?.departments
        ) as TApiUserDepartment
      );
    }
  }, [surveysState.currentSurveyIdentification]);

  const submitIdentificationForm = (
    e: FormEvent,
    anonymous: boolean = false
  ) => {
    e.preventDefault();
    dispatch(
      startSurvey({
        surveyId: surveysState.currentSurvey!.survey.id,
        languageCode: props.location.state.language,
        respondentName: name,
        departmentId: organization?.id,
        locationId: location?.id
      })
    )
      .then((res: any) => {
        setRespondent(
          res.data.respondent,
          surveysState.currentSurvey!.survey.id,
          props.location.state.language
        );
        if (surveysState.currentSurvey?.survey.allowSavingProgress) {
          addToRespondentsStorage(
            surveysState.currentSurvey?.survey.id,
            res.data.respondent
          );
        }
        goToCategory();
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <>
      <Typography className={classes.heading}>
        <SurveyTranslation
          translate={"survey.identification.before.begin.title"}
        />
      </Typography>
      <p>
        <SurveyTranslation
          translate={"survey.identification.before.begin.description"}
        />
      </p>
      <IdentificationForm
        name={name}
        setName={setName}
        location={location}
        setLocation={setLocation}
        organization={organization}
        setOrganization={setOrganization}
        submitForm={submitIdentificationForm}
        survey={surveysState}
        errors={error && app.getError(StartSurvey)}
        segmentationContinueButton={
          typeof surveysState.currentSurvey?.survey
            .segmentationContinueButton === "string"
            ? surveysState.currentSurvey?.survey.segmentationContinueButton
            : null
        }
        segmentationId={
          typeof surveysState.currentSurvey?.survey.segmentationId === "string"
            ? surveysState.currentSurvey?.survey.segmentationId
            : null
        }
        selectSegmentationLocation={
          typeof surveysState.currentSurvey?.survey
            .selectSegmentationLocation === "string"
            ? surveysState.currentSurvey?.survey.selectSegmentationLocation
            : null
        }
        selectSegmentationOrganisation={
          typeof surveysState.currentSurvey?.survey
            .selectSegmentationOrganisation === "string"
            ? surveysState.currentSurvey?.survey.selectSegmentationOrganisation
            : null
        }
      />
    </>
  );
};
export default compose(
  withSurveyData,
  withRouter
)(SurveyIdentificationPage) as React.FC;
